import { Box, BoxProps, Skeleton } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/system";
import { useAtomValue } from "jotai";
import { FC, ReactNode, useEffect, useState } from "react";
import { preloadImage } from "../../utils/image";
import { imageRefineAtomsAtom } from "../ai-refine/store";
import { imagePreviewDimensions } from "../generate/store";
import { LoadableTemplateImage } from "../generic/atoms/types/template";
import { TemplateImageAttribution } from "./template-image-attribution";
export const TemplateImage: FC<
  BoxProps & {
    img?: LoadableTemplateImage;
    designHuddle?: boolean;
    loadingComponent?: ReactNode;
  }
> = ({ img, designHuddle = false, sx = [], loadingComponent, ...props }) => {

  const [imageUrl, setImageUrl] = useState("none");
  const [highQualityReady, setHighQualityReady] = useState(false);

  const refineAtoms = useAtomValue(imageRefineAtomsAtom);

  const dimensions = useTemplateImageDimensions();

  const canRefine = refineAtoms && img?.state === "hasData" && img.data;

  useEffect(() => {
    if (!img || img.state === "loading") setHighQualityReady(false);

    const lowQualityUrl =
      img?.state === "hasData" && img.data?.url.state === "hasData" ? img.data.url.data : null;
    const highQualityUrl =
      img?.state === "hasData" && img.data?.urlHighQuality?.state === "hasData"
        ? img.data.urlHighQuality.data
        : null;

    if (highQualityUrl) {
      preloadImage(highQualityUrl)
        .then(() => setHighQualityReady(true))
        .catch(() => {
          console.log("High quality preview failed");
        });
    }

    highQualityReady
      ? setImageUrl(`url(${highQualityUrl})`)
      : lowQualityUrl
        ? setImageUrl(`url(${lowQualityUrl})`)
        : () => {};
  }, [highQualityReady, img]);

  return (
    <Box
      data-testid="template-image"
      sx={[
        {
          position: "relative",
          borderRadius: "5px",
          border: "5px solid #eee",
          padding: "5px",
          ...((canRefine || designHuddle) && {
            ":hover": {
              border: "1px dashed #00000066",
              padding: "9px",
              ".MuiButton-root": {
                display: "inline-flex",
              },
            },
          }),
          overflow: "hidden",
          // add 20: 2 * (border + padding)
          width: dimensions.width + 20,
          height: dimensions.height + 20,
        },
        ...(sx instanceof Array ? sx : [sx]),
      ]}
      {...props}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          boxSizing: "content-box",
          position: "relative",
          backgroundImage: imageUrl,
          backgroundSize: "cover",
        }}
        data-testid="template-image-bg"
      >
        {(img?.state !== "hasData" || img.data?.url.state !== "hasData") &&
          (loadingComponent || (
            <Skeleton sx={{ width: "100%", height: "100%" }} variant="rectangular" />
          ))}
        {img?.state === "hasData" && img?.data?.unsplashExtra && (
          <TemplateImageAttribution attributionData={img.data.unsplashExtra.attributionData} />
        )}
      </Box>
    </Box>
  );
};

export const useTemplateImageDimensions = (): { width: number; height: number } => {
  const { image_type, height, width } = useAtomValue(imagePreviewDimensions);

  const theme = useTheme();

  const isGreaterThanXl = useMediaQuery(theme.breakpoints.up("xl"));

  const isLessThanLg = useMediaQuery(theme.breakpoints.down("lg"));

  if (image_type === "banner") return { width, height };

  if (isGreaterThanXl) return { width: 400, height: 400 };

  if (isLessThanLg) return { width: 200, height: 200 };

  return { width: 250, height: 250 };
};
