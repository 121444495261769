/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  SupabaseService,
  SupabaseServiceView,
} from "../../../server/supabase/base-supabase-service";
import { Database } from "../../../server/supabase/types/database-definitions";
import { INTERVAL, ISO8601_DATE } from "../../../utils/iso8601";
import { UUID } from "../../../utils/uuid";

export type Event = Database["public"]["Tables"]["event"]["Row"];
export type EventKey = {
  key: string;
  title: string;
  calendar: boolean;
  date: ISO8601_DATE;
  library: boolean;
  repeat: INTERVAL | null | undefined;
  allow_initial: boolean;
  allow_repeats: boolean;
  has_schedule_event: boolean;
  number_of_repeats: number | undefined;
};

export class SupabaseEventService extends SupabaseService<"event"> {
  table = "event" as const;

  async getKeys(options?: { mustIncludeKey?: string; includeLibrary?: boolean }) {
    let query = this.client.from("event_key").select("*");
    if (!options?.includeLibrary) query = query.eq("library", false);

    return this.getAllFromSelection(
      null,
      options?.mustIncludeKey
        ? query.or(`deleted.is.false,key.eq.${options.mustIncludeKey}`)
        : query.or("deleted.is.false"),
      { order: [{ column: "key" }] },
    );
  }
}

export class SupabaseProgramEventInfoService extends SupabaseServiceView<"program_event_info"> {
  table = "program_event_info" as const;
  async getEventsByProgram(account_id: UUID, options: { programId: UUID }) {
    return this.getAllFromSelection(
      account_id,
      this.client
        .from(this.table)
        .select("*")
        .eq("program_id", options.programId)
        .not("id", "is", null)
        .not("title", "is", null)
        .order("title", { ascending: true }),
      { order: [{ column: "id" }] },
    );
  }
}
export class SupabaseEventsWithMomentsCalendarService extends SupabaseServiceView<"events_with_related_moments_calendar"> {
  table = "events_with_related_moments_calendar" as const;
}

export class SupabaseEventsWithMomentsPersonService extends SupabaseServiceView<"events_with_related_moments_person"> {
  table = "events_with_related_moments_person" as const;
}

export class SupabaseScheduleEventService extends SupabaseService<"schedule_event"> {
  table = "schedule_event" as const;

  async update(
    event_key: Database["public"]["Tables"]["schedule_event"]["Insert"]["event_key"],
    updateProps: Database["public"]["Tables"]["schedule_event"]["Update"],
  ) {
    return this.log_errors(
      await this.client.from(this.table).update(updateProps).match({ event_key }).select().single(),
    );
  }
}

export class SupabaseProgramEvents extends SupabaseServiceView<"program_event_info"> {
  table = "program_event_info" as const;
}
