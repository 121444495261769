import { Typography } from "@mui/material";
import { FC } from "react";
import { CeTextField } from "../generic/components/form-fields/ce-text-field";

export const EditableField: FC<{
  title: string;
  editable: boolean;
  setEditable: (state: boolean) => void;
  setFilename: (f: string) => void;
  onChange?: (value: string) => void;
  error?: string;
}> = ({ title, editable, error, setEditable, setFilename, onChange }) => {
  return (
    <>
      <Typography
        flexGrow={1}
        variant="h5"
        color={editable ? "transparent" : "text.secondary"}
        onClick={() => {
          setEditable(true);
        }}
        sx={{ position: "relative" }}
      >
        {title}
        {(editable || error) && (
          <CeTextField
            data-testid="edit-content-edit-name-input"
            sx={{
              position: "absolute",
              left: 0,
              top: -4,
              color: "text.secondary",
              minWidth: "200px",
            }}
            fullWidth
            variant="editabletext"
            defaultValue={title}
            error={Boolean(error)}
            helperText={error}
            onBlur={(e) => {
              if (e.target.value.trim() === "") {
                setEditable(false);
                return;
              }
              setFilename(e.target.value);
              void onChange?.(e.target.value);
              setEditable(false);
            }}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                setEditable(false);
              }
            }}
          />
        )}
      </Typography>
    </>
  );
};
