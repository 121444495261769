import { $generateHtmlFromNodes } from "@lexical/html";
import { $insertNodes } from "lexical";
import { channelToFormat } from "../../../../../utils/channel-to-format";
import { ChannelName } from "../../../../channels/types";
import { SerialisedEditorDesign } from "../../../../message/editor/types/serialised-editor-design";
import { generateNodesFromHtml } from "../../../../message/editor/utils/generate-nodes-from-html";
import { createConfiguredEditor } from "../../../../message/editor/utils/headless-editor";

export function convertLexicalDesign(
  from: NonNullable<ChannelName>,
  to: NonNullable<ChannelName>,
  design: SerialisedEditorDesign | null,
): SerialisedEditorDesign {
  const oldEditor = createConfiguredEditor({ format: channelToFormat(from) });

  const state = oldEditor.parseEditorState(JSON.stringify(design));
  oldEditor.setEditorState(state);

  let htmlString = "";
  oldEditor.update(
    () => {
      htmlString = $generateHtmlFromNodes(oldEditor, null);
    },
    { discrete: true },
  );

  const newEditor = createConfiguredEditor({ format: channelToFormat(to) });

  newEditor.update(
    () => {
      const nodes = generateNodesFromHtml(newEditor, htmlString, to);
      $insertNodes(nodes);
      return true;
    },
    { discrete: true },
  );

  return newEditor.getEditorState().toJSON();
}
