import { DOMConversionOutput, ElementFormatType } from "lexical";
import { uuidv4 } from "../../../../utils/uuid";
import { EmailButtonType } from "../../../email-design/types/email-button-type";
import { ButtonListNodeMui } from "./button-list-mui";
import { ButtonListNode } from "./button-list-node";
import { DesignHuddleNodeMui } from "./design-huddle-mui";
import { DesignHuddleCustomisations, DesignHuddleNode } from "./design-huddle-node";
import { DividerNodeMui } from "./divider-mui";
import { DividerNode } from "./divider-node";
import { ImageNode } from "./image-node";
import { ImageNodeMui } from "./image-node-mui";
import { StylableButtonNodeMui } from "./stylable-button-mui";
import { StylableButtonNode } from "./stylable-button-node";

export function convertButtonListElement(
  domNode: Node,
  type: typeof ButtonListNodeMui | typeof ButtonListNode,
): DOMConversionOutput {
  if (domNode instanceof HTMLDivElement) {
    const buttons = Array.from(domNode.querySelectorAll("a")).map((anchor) => ({
      id: uuidv4(),
      text: anchor.textContent || "",
      url: anchor.href || "",
    }));

    return {
      node: new type(buttons),
    };
  }

  return { node: null };
}

export function isButtonListElement(node: HTMLElement): boolean {
  return node.tagName === "DIV" && node.getAttribute("data-node-type") === "button-list-node";
}

export function isDesignHuddleElement(node: HTMLElement): node is HTMLImageElement {
  return node.tagName === "IMG" && node.getAttribute("data-node-type") === "design-huddle-node";
}

export function convertDesignHuddleImageElement(
  domNode: Node,
  type: typeof DesignHuddleNodeMui | typeof DesignHuddleNode,
): DOMConversionOutput {
  if (domNode instanceof HTMLImageElement) {
    const widthPercentage = domNode.style.width ? parseFloat(domNode.style.width) / 100 : 1;
    const account = domNode.getAttribute("data-dh-account-id") ?? undefined;
    const project = domNode.getAttribute("data-dh-project-id") ?? undefined;
    const attr = domNode.getAttribute("data-dh-customisations");
    const customisations = attr ? (JSON.parse(attr) as DesignHuddleCustomisations) : undefined;

    return {
      node: account
        ? new type(
            account,
            project,
            undefined,
            widthPercentage,
            1,
            customisations,
            undefined,
            undefined,
            undefined,
            true,
          )
        : null,
    };
  }

  return { node: null };
}

export function convertDividerElement(
  domNode: Node,
  type: typeof DividerNodeMui | typeof DividerNode,
): DOMConversionOutput {
  if (domNode instanceof HTMLHRElement) {
    const attr = domNode.getAttribute("data-divider-type");
    return {
      node: new type({ type: (attr as "primary" | "secondary") ?? "primary" }),
    };
  }

  return { node: null };
}

export function isDividerElement(node: HTMLElement): node is HTMLHRElement {
  return node.tagName === "HR";
}

export function isImageElement(node: HTMLElement): node is HTMLImageElement {
  return node.tagName === "IMG" && node.getAttribute("data-node-type") !== "design-huddle-node";
}

export function convertImageElement(
  domNode: Node,
  type: typeof ImageNodeMui | typeof ImageNode,
): DOMConversionOutput {
  if (domNode instanceof HTMLImageElement) {
    const url = domNode.src;

    // Extract width percentage
    const widthPercentage = domNode.style.width ? parseFloat(domNode.style.width) / 100 : 1;
    const aspectRatio = domNode.style.aspectRatio ? parseFloat(domNode.style.aspectRatio) : 1;

    return { node: new type(url, widthPercentage, aspectRatio) };
  }
  return { node: null };
}

export function convertButtonElement(
  domNode: Node,
  type: typeof StylableButtonNodeMui | typeof StylableButtonNode,
): DOMConversionOutput {
  if (domNode instanceof HTMLDivElement) {
    if (domNode instanceof HTMLDivElement) {
      const span = domNode.querySelector("span");
      const anchor = span?.querySelector("a");

      if (anchor) {
        return {
          node: new type(
            anchor.textContent || "",
            anchor.href || "",
            anchor.className as EmailButtonType,
            domNode.style.width === "100%",
            anchor.getAttribute("data-button-format") as ElementFormatType,
          ),
        };
      }
    }
  }

  return { node: null };
}

export function isButtonStylableButtonElement(node: HTMLElement): boolean {
  return node.tagName === "DIV" && node.getAttribute("data-node-type") === "stylable-button-node";
}
