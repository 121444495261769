import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { FC, ReactElement } from "react";
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";
import { ElementTracking, useElementTracking } from "../../../analytics/analytics";
import { validationError } from "../../../moment/validation-error";

type TextFieldProps = Omit<MuiTextFieldProps, "variant"> &
  ElementTracking & {
    variant?: MuiTextFieldProps["variant"] | "editabletext";
  };

export const CeTextField: FC<TextFieldProps> = function CeTextField(
  { variant, analyticsCategory, sx = [], ...props },
): ReactElement {
  const analyticsProps = useElementTracking(analyticsCategory, props.id, "text-input");
  return (
    <MuiTextField
      {...props}
      variant={variant === "editabletext" ? "standard" : variant}
      sx={[
        ...(variant === "editabletext"
          ? [
              {
                "& .MuiInputBase-root": {
                  fontSize: "inherit",
                  fontWeight: "inherit",
                },
                "& .MuiInputBase-root::before": {
                  borderBottom: "none",
                },
              },
            ]
          : []),
        ...(sx instanceof Array ? sx : [sx]),
      ]}
      {...analyticsProps}
    />
  );
};

export const CeTextInput = <TFieldValues extends FieldValues>({
  label,
  name,
  showValidationErrors = true,
  ...props
}: {
  label?: string;
  name: Path<TFieldValues>;
  showValidationErrors?: boolean;
} & TextFieldProps): React.JSX.Element => {
  const { control } = useFormContext<TFieldValues>();

  return (
    <Controller<TFieldValues>
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <CeTextField
          {...field}
          fullWidth
          id={name}
          label={label}
          helperText={validationError(fieldState, showValidationErrors)?.message}
          error={Boolean(validationError(fieldState, showValidationErrors))}
          value={field.value || ""}
          disabled={props.disabled}
          {...props}
        />
      )}
    />
  );
};
