import { Typography } from "@mui/material";
import { FC } from "react";
import { Asset } from "./server/digital-asset-service";

export const AssetCount: FC<{ assets: Asset[]; searchText?: string }> = ({
  assets,
  searchText,
}) => {
  return (
    <Typography variant="h6">
      {assets.length} assets {searchText ? `found for "${searchText}"` : ""}
    </Typography>
  );
};
