import { uniqBy } from "lodash-es";
import { ChannelName, isEmailChannel } from "../../../../modules/channels/types";
import {
  filterStatType,
  GroupedMomentAnalyticsData,
  isMomentAnalyticsStatType,
} from "../../../../modules/stats/server/analytics-service";
import { formatIntervalFromParts } from "../../../../utils/interval";
import { fromISO8601 } from "../../../../utils/iso8601";
import { UUID } from "../../../../utils/uuid";
import { calculatePercentage } from "../utils";

export type MomentAnalyticsCellDataDisplay =
  | {
      value: number;
      type: "percentage" | "number";
    }
  | {
      type: null;
    };

export type TransformedGroupedMomentAnalytics = {
  id: number;
  account_id: UUID;
  min_timestamp: Date;
  recipients: {
    display: string;
    type: "number" | "person_name" | "channel_name" | null;
    cc?: string[];
    bcc?: string[];
    connection_type?: string | null;
  };
  subject: { when: string; subject?: string } | null;
  delivered: MomentAnalyticsCellDataDisplay;
  opened: MomentAnalyticsCellDataDisplay;
  interacted: MomentAnalyticsCellDataDisplay;
  rawData: GroupedMomentAnalyticsData;
  channel_name: ChannelName;
};

export function transformGroupedMomentAnalytics(
  item: GroupedMomentAnalyticsData,
  index: number,
): TransformedGroupedMomentAnalytics {
  const stats = item.stats.filter(
    (stat) =>
      isMomentAnalyticsStatType("delivery")(stat) || isMomentAnalyticsStatType("error")(stat),
  );

  const deliveries = filterStatType("delivery", item.stats);
  const deliveredPercentage = calculatePercentage(deliveries.length, stats.length);

  const opens = filterStatType("grouped_open", item.stats);

  const openPercentage = calculatePercentage(
    item.stats.filter(isMomentAnalyticsStatType("grouped_open")).length,
    deliveries.length,
  );

  const clicks = filterStatType("click", item.stats);

  const uniqueClicks = uniqBy(clicks, (stat) => stat.data.recipient_data?.recipient_id);

  const interactionPercentage = calculatePercentage(
    uniqueClicks.length,
    isEmailChannel(item.channel_name as ChannelName) ? opens.length : deliveries.length,
  );

  return {
    id: index,
    account_id: item.account_id,
    min_timestamp: fromISO8601(item.min_timestamp),
    recipients: makeRecipients(item, stats),
    subject: makeSubject(item),
    delivered: {
      value: deliveredPercentage,
      type: "percentage",
    },
    opened: {
      value: openPercentage,
      type: "percentage",
    },
    interacted: {
      value: interactionPercentage,
      type: "percentage",
    },
    rawData: item,
    channel_name: item.channel_name as ChannelName,
  };
}

export function makeSubject(
  item: GroupedMomentAnalyticsData,
): TransformedGroupedMomentAnalytics["subject"] {
  if (
    item.grouping_info.type === "immediate" ||
    item.grouping_info.type === "unknown" ||
    item.grouping_info.type === "one_off"
  ) {
    return null;
  }

  const interval = formatIntervalFromParts(item.grouping_info.interval);

  const data = { when: `${interval} ${item.grouping_info.event_key}` };

  if (item.grouping_info.type === "person" && item.grouping_info.subject) {
    return {
      ...data,
      subject: `${item.grouping_subject?.first_name} ${item.grouping_subject?.last_name}`,
    };
  }

  return data;
}

export function makeRecipients(
  item: GroupedMomentAnalyticsData,
  stats: Extract<
    GroupedMomentAnalyticsData["stats"][number],
    { stat_type: "delivery" } | { stat_type: "error" }
  >[],
): TransformedGroupedMomentAnalytics["recipients"] {
  const { cc, bcc, connection_type } =
    item.grouping_info.type === "unknown"
      ? { cc: [], bcc: [], connection_type: null }
      : item.grouping_info;

  // TODO currently cc and bcc contain the personIds not names
  const baseRecipient = { cc, bcc, connection_type };

  if (stats.length !== 1) {
    return { ...baseRecipient, display: `${stats.length}`, type: "number" };
  }

  const recipient_data = stats[0].data.recipient_data;
  if (!recipient_data) {
    return { ...baseRecipient, display: "-", type: null };
  }

  const [display, type] = recipient_data.is_multiperson
    ? [`#${recipient_data.display}`, "channel_name" as const]
    : [`${recipient_data.first_name} ${recipient_data.last_name}`, "person_name" as const];

  return { ...baseRecipient, display, type };
}
