export function incrementFileName(fileName: string): string {
  // Handle files with multiple extensions (e.g., "test.tar.gz")
  const firstDotIndex = fileName.indexOf(".");
  const lastDotIndex = fileName.lastIndexOf(".");

  // No dots, or only one dot
  if (firstDotIndex === lastDotIndex) {
    const nameWithoutExtension = firstDotIndex !== -1 ? fileName.slice(0, firstDotIndex) : fileName;
    const extension = firstDotIndex !== -1 ? fileName.slice(firstDotIndex) : "";

    const match = nameWithoutExtension.match(/(.*?)\s*\((\d+)\)$/);
    if (match) {
      const baseName = match[1];
      const num = parseInt(match[2], 10);
      return `${baseName} (${num + 1})${extension}`;
    } else {
      return `${nameWithoutExtension} (1)${extension}`;
    }
  }
  // Multiple extensions (e.g., .tar.gz)
  else {
    const nameWithoutExtensions = fileName.slice(0, firstDotIndex);
    const allExtensions = fileName.slice(firstDotIndex);

    const match = nameWithoutExtensions.match(/(.*?)\s*\((\d+)\)$/);
    if (match) {
      const baseName = match[1];
      const num = parseInt(match[2], 10);
      return `${baseName} (${num + 1})${allExtensions}`;
    } else {
      return `${nameWithoutExtensions} (1)${allExtensions}`;
    }
  }
}
