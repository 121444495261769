import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { FC, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useApiServiceCallback } from "../../../../../server/api/client";
import { Database } from "../../../../../server/supabase/types/database-definitions";
import { Refresh } from "../../../../../styles/icons/refresh";
import { CustomEmailDomainsService } from "../../client/custom-email-domains-service";
import { CustomEmailDomain } from "../../server/supabase-custom-email-domain-service";

export const RefreshButton: FC<{
  customEmailDomain: CustomEmailDomain;
  onVerified: () => void;
}> = ({ customEmailDomain, onVerified }) => {
  const { t } = useTranslation();
  const [refreshing, setRefreshing] = useState(false);

  const handleCheckStatus = useApiServiceCallback(
    CustomEmailDomainsService,
    async (
      { apiService },
      emailDomain: Database["public"]["Tables"]["custom_email_domain"]["Row"],
    ): Promise<void> => {
      setRefreshing(true);

      const { data } = await apiService.refreshVerifiedStatus(emailDomain.id);

      if (data) {
        if (data.verified) {
          toast.success(t("{{domain}} has been verified.", { domain: emailDomain.domain }));
          onVerified();
        } else {
          toast(t("{{domain}} has not yet been verified.", { domain: emailDomain.domain }), {
            icon: "⚠️",
          });
          setRefreshing(false);
        }
      } else {
        toast.error(
          t("Something went wrong when refreshing status for {{domain}}", {
            domain: emailDomain.domain,
          }),
        );
        setRefreshing(false);
      }
    },
    [onVerified, t],
  );

  return (
    <Tooltip
      title={t("Refresh Status for {{domain}}", {
        domain: customEmailDomain.domain,
      })}
      placement="right"
    >
      {refreshing ? (
        <IconButton data-analytics-id="email-domain-table-refreshing">
          <CircularProgress size="small" />
        </IconButton>
      ) : (
        <IconButton
          data-analytics-id="email-domain-table-refresh"
          onClick={() => {
            void handleCheckStatus(customEmailDomain).catch(console.error);
          }}
        >
          <Refresh fontSize="small" />
        </IconButton>
      )}
    </Tooltip>
  );
};
