import { AppState, GetTokenSilentlyOptions, RedirectLoginOptions } from "@auth0/auth0-react";
import { PostgrestClient } from "@supabase/postgrest-js";
import { RefObject } from "react";
import { UUID } from "../../../utils/uuid";
import { Database } from "../types/database-definitions";
import { OrgUser, SupabaseHandlerArg, SupabaseSchemaName } from "./types";

export const getSupabase = <Database, SchemaName extends SupabaseSchemaName<Database>>(
  url: string,
  key: string,
  access_token: string,
  schema = "public" as SchemaName,
  abortControllerRef: RefObject<AbortController | null>
): PostgrestClient<Database, SchemaName> => {

  return new PostgrestClient(`${url}/rest/v1`, {
    headers: {
      apikey: key,
      Authorization: `Bearer ${access_token}`,
    },
    schema,
    fetch: (furl, init) => fetch(furl, {...init, signal: abortControllerRef.current?.signal}),
  });
}

export async function withSupabaseToken<SchemaName extends SupabaseSchemaName<Database> = "public">(
  user: OrgUser | undefined,
  isLoading: boolean,
  getToken: (options?: GetTokenSilentlyOptions) => Promise<string>,
  loginWithRedirect: (options?: RedirectLoginOptions<AppState>) => Promise<void>,
  schema: SchemaName,
  abortControllerRef: RefObject<AbortController | null>
): Promise<SupabaseHandlerArg<SchemaName> | undefined> {
  if (isLoading) return;
  if (!user) {
    await loginWithRedirect({
      appState: {
        returnTo: `${window.location.pathname}${window.location.search}`,
      }});
    return;
  }
  const token  = await getToken()
  // We use the AccessToken here because it has the same lifetime as the Supabase token.
  const auth0Token = JSON.parse(window.atob((token).split(".")[1])) as {
    "http://changeengine.com/supabase_url": string,
    "http://changeengine.com/supabase_anon_key": string,
    "http://changeengine.com/account_id": UUID,
    "org_id": string,
  };

  return {
    supabase: getSupabase<Database, SchemaName>(
      auth0Token["http://changeengine.com/supabase_url"],
      auth0Token["http://changeengine.com/supabase_anon_key"],
      token,
      schema,
      abortControllerRef
    ),
    account_id: auth0Token["http://changeengine.com/account_id"],
    org_id: auth0Token.org_id,
    token,
    abortController: abortControllerRef.current
  };
}
