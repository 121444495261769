import { useCallback } from "react";
import { TemplateCustomizationObject } from "./brand-info-customizations/types";

type createDesignHuddleContentProject = (props: {
  templateCode: string;
  customizations: TemplateCustomizationObject;
  pageNumber?: number;
}) => Promise<string>;

export const useCreateDesignHuddleProject = (): createDesignHuddleContentProject => {
  return useCallback(async ({ templateCode, customizations, pageNumber }) => {
    return new Promise((resolve, reject) => {
      DSHDLib.createProject(
        { template_code: templateCode, customizations, page_number: pageNumber },
        (err, response) => {
          if (err) {
            console.error(err);
            reject(new Error("Failed to create project"));
          }
          resolve(response.project_id);
        },
      );
    });
  }, []);
};
