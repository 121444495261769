import { useCallback } from "react";
import { useToken } from "../../../server/auth/use-token";
import { incrementFileName } from "../../../utils/increment-file-name";
import { NameSpaceURL, uuidv4, uuidv5 } from "../../../utils/uuid";
import { copyImage } from "../../design-huddle/utils";
import { Asset } from "../server/digital-asset-service";
import { useCreateAssetCallback } from "./use-upsert-asset";

export const useDuplicateAssetCallback = (): ((asset: Asset) => Promise<{success: boolean}>) => {
  const getToken = useToken();
  const createAsset = useCreateAssetCallback();

  return useCallback(
    async (asset: Asset) => {
      if (!asset.public_url) {
        throw new Error(`No public URL available for asset ${asset.title}.`);
      }

      const copyFileResponse = await copyImage(
        getToken,
        asset.public_url,
        uuidv5(NameSpaceURL, uuidv4()),
      );

      if (!copyFileResponse.success) return { success: false };

      try {
        await createAsset({
          title: incrementFileName(asset.title),
          thumbnailUrl: asset.thumbnail_url ? copyFileResponse.url : undefined,
          publicUrl: copyFileResponse.url,
          contentType: asset.content_type,
          projectId: asset.project_id,
          pageId: asset.page_id,
          mimeType: asset.mime_type,
          sourceDigitalAssetId: asset.id,
        });
        return { success: true };
      } catch (e) {
        console.error(e);
        return { success: false };
      }
    },
    [createAsset, getToken],
  );
};
