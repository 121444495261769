import { Card, DialogContentText, Grid, LinearProgress, Stack } from "@mui/material";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useEnabledChannels } from "../../../../channels/hooks/use-enabled-channels";
import { CeTextInput } from "../../../../generic/components/form-fields/ce-text-field";
import { MomentFormMoment } from "../../../../moment/types/moment-form-moment";
import { useSegmentFields } from "../../../hooks/use-segment-fields";
import { BuildSegmentFilters } from "../../build-segment-filters";
import { SegmentOperation } from "../../segment-operation/operation";
import { SegmentManagerRecipientCount } from "../segment-manager-recipient-count";

export const SegmentModalForm: FC<{
  defaultSegmentManager?: boolean;
  disabled?: boolean;
  isRecipientType: boolean;
}> = ({ defaultSegmentManager = false, disabled = false, isRecipientType }) => {
  const { t } = useTranslation();

  const segmentFields = useSegmentFields();
  const channels = useEnabledChannels();

  if (channels.loading || segmentFields.loading) return <LinearProgress />;

  if (channels.error || segmentFields.error || !segmentFields.data || !channels.data) {
    return null;
  }

  return (
    <>
      {defaultSegmentManager && (
        <DialogContentText>
          <Trans>
            When creating a new Segment, you may want some filters pre-populated by default. You can
            set these up below. Once saved, whenever a user creates a &quot;New Segment&quot;
            anywhere in ChangeEngine, their Segment will have these filters preloaded.
          </Trans>
        </DialogContentText>
      )}
      <Stack spacing={2}>
        {!defaultSegmentManager && (
          <>
            <CeTextInput<MomentFormMoment>
              name="segment.name"
              variant="standard"
              label={t("Name")}
              required={true}
            />
            <CeTextInput<MomentFormMoment>
              name="segment.description"
              variant="standard"
              label={t("Description")}
            />
          </>
        )}
        <Stack sx={{ pt: 2, pb: 1 }}>
          <SegmentOperation
            disabled={disabled}
            fields={segmentFields.data}
            disableAudienceClearable={!defaultSegmentManager && !isRecipientType}
          />
        </Stack>
        <Card variant="outlined" sx={{ p: 2 }}>
          <Stack spacing={2}>
            <BuildSegmentFilters
              disabled={disabled}
              showValidationErrors={true}
              segmentFields={segmentFields.data}
            />
            <Grid container justifyContent="flex-start">
              <SegmentManagerRecipientCount channels={channels.data} />
            </Grid>
          </Stack>
        </Card>
      </Stack>
    </>
  );
};
