import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import { OrgUser } from "../../../server/supabase/hooks/types";
import { mapOrgUserToRole } from "../../analytics/analytics";
import { useAnalyticsData } from "../../analytics/hooks/use-analytics-data";
import { usePendo } from "../../analytics/hooks/use-pendo-watch";
import { useAccount } from "../../generic/hooks/use-account";
import { AccountLimitKey } from "../../generic/server/supabase-account-service";
import { Columns } from "../types";

export const useTriggerPendoUpdate = (): {
  accountUpdated: (row: Columns) => Promise<void>;
} => {
  const pendo = usePendo();
  const { user } = useAuth0<OrgUser>();
  const { account } = useAccount();

  const { data: currentSessionData } = useAnalyticsData(
    user?.sub,
    account,
    user ? mapOrgUserToRole(user) : undefined,
    user?.email,
  );

  const accountUpdated = useCallback(
    async ({ id, name, business_name, usage, churned, ...limits }: Columns) => {
      if (pendo && user && currentSessionData) {
        await pendo.flushNow();
        pendo.teardown();

        const { contributors = 0, super_admins = 0 } = limits as {
          [key in AccountLimitKey]: number;
        };

        pendo.initialize({
          account: {
            id: business_name,
            accountName: name,
            usage,
            churned,
            limit_contributors: contributors,
            limit_super_admins: super_admins,
          },
          visitor: {
            id: user.sub,
          },
        });
        pendo.track("ce_admin_account_updated", {
          account_id: id,
          account_name: name,
          business_name: business_name,
          usage: usage,
          churned: churned,
          limit_contributors: contributors,
          limit_super_admins: super_admins,
        });
        // Now close and reinitiliase pendo with the logged in user data
        await pendo.flushNow();
        pendo.teardown();
        pendo.initialize(currentSessionData);
      }
    },
    [currentSessionData, pendo, user],
  );

  return {
    accountUpdated,
  };
};
