import { Alert, Box, LinearProgress } from "@mui/material";
import { DataGridPro, GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid-pro";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PersonInUnsavedSegmentWithChannels } from "../../../../server/supabase-segment-service";
import { Segment } from "../../../../types";
import { useRecipientColumns } from "../../hooks/use-recipient-columns";
import { useSegmentRecipients } from "../../hooks/use-segment-recipients";

export const SegmentRecipientsDataGrid: FC = () => {
  const { t } = useTranslation();
  const columns = useRecipientColumns();
  const { getValues } = useFormContext<{ segment: Pick<Segment, "operation" | "conditions"> }>();

  const [operation, conditions] = getValues(["segment.operation", "segment.conditions"]);

  const { data, loading, error } = useSegmentRecipients({ operation, conditions });

  if (loading) return <LinearProgress />;
  if (error) return <Alert severity="error">{t("Unable to load related Moments")}</Alert>;

  return (
    <DataGridPro<PersonInUnsavedSegmentWithChannels>
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
      initialState={{
        pagination: {
          paginationModel: { pageSize: 50, page: 0 },
          rowCount: 0,
        },
      }}
      disableRowSelectionOnClick
      getRowHeight={() => "auto"}
      rows={data}
      columns={columns}
      slots={{ toolbar: Toolbar }}
    />
  );
};

const Toolbar: FC = () => {
  return (
    <GridToolbarContainer sx={{ pb: 2 }}>
      <GridToolbarFilterButton />
      <Box sx={{ flexGrow: 1 }} />
    </GridToolbarContainer>
  );
};
