import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SwipeOutlined from "@mui/icons-material/SwipeOutlined";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  filterStatType,
  GroupedMomentAnalyticsData,
} from "../../../../../modules/stats/server/analytics-service";
import { EmptyState } from "../../generic/empty-state";
import { NestedLinkTable } from "./nested-link-table";
import { InteractionsByUrl } from "./types";

export const AnalyticsSendsTableExpandedInteractionsByLink: FC<{
  stats: GroupedMomentAnalyticsData["stats"];
}> = ({ stats }) => {
  const { t } = useTranslation();

  const { data } = useMemo(() => {
    const interactions = filterStatType("click", stats);

    const mapped = interactions.reduce(
      (acc, { data: innerData }) => {
        if (!acc[innerData.url]) acc[innerData.url] = { url: innerData.url, people: {} };

        const id = innerData.recipient_data?.person_id ?? "unknown";

        if (!acc[innerData.url].people[id])
          acc[innerData.url].people[id] = {
            first_name: innerData.recipient_data?.first_name ?? t("-"),
            last_name: innerData.recipient_data?.last_name ?? t("-"),
            first_interaction: innerData.min_timestamp,
            last_interaction: innerData.max_timestamp,
            clicks: innerData.count,
          };

        return acc;
      },
      {} as Record<string, InteractionsByUrl>,
    );

    return { data: Object.values(mapped) };
  }, [stats, t]);

  const rows = useMemo(() => {
    return data.map((item, id) => {
      return {
        id,
        url: item.url,
        clicks: Object.values(item.people).reduce((acc, { clicks }) => acc + clicks, 0),
        people: item.people,
      };
    });
  }, [data]);

  const columns = useMemo(() => {
    const columnData: GridColDef<(typeof rows)[0]>[] = [
      {
        field: "url",
        headerName: t("Link"),
        flex: 5,
      },
      {
        field: "clicks",
        headerName: t("Clicks"),
        flex: 0.5,
      },
    ];
    return columnData;
  }, [t]);

  if (rows.length === 0)
    return (
      <EmptyState
        symbol={<SwipeOutlined fontSize="large" />}
        title={t("No interactions yet")}
        subtitle={t(
          "It could be that no-one has interacted with a link yet, or there are no links in this Moment. Check back later if you're expecting to see some data here.",
        )}
      />
    );

  return (
    <DataGridPro
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
      initialState={{ pagination: { paginationModel: { pageSize: 25 } } }}
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      getDetailPanelContent={({ row }) => <NestedLinkTable row={row} />}
      getDetailPanelHeight={() => "auto"}
      slots={{
        detailPanelCollapseIcon: ExpandLess,
        detailPanelExpandIcon: ExpandMore,
      }}
    />
  );
};
