import { Stack, Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TableStack } from "../../../../../pages/moments/analytics/send-table/table-stack";
import { ChannelIcon } from "../../../../channels/components/channel-icon";
import { PersonInUnsavedSegmentWithChannels } from "../../../server/supabase-segment-service";

export function useRecipientColumns(): GridColDef<PersonInUnsavedSegmentWithChannels>[] {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        field: "first_name",
        headerName: t("First Name"),
        sortable: true,
        filterable: true,
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<PersonInUnsavedSegmentWithChannels, string>) => (
          <TableStack>{params.value ?? "-"}</TableStack>
        ),
      },
      {
        field: "last_name",
        headerName: t("Last Name"),
        sortable: true,
        filterable: true,
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<PersonInUnsavedSegmentWithChannels, string>) => (
          <TableStack>{params.value ?? "-"}</TableStack>
        ),
      },
      {
        field: "email",
        headerName: t("Email"),
        sortable: true,
        filterable: true,
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<PersonInUnsavedSegmentWithChannels, string>) => (
          <TableStack>{params.value ?? "-"}</TableStack>
        ),
      },
      {
        field: "job_title",
        headerName: t("Job Title"),
        sortable: true,
        filterable: true,
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<PersonInUnsavedSegmentWithChannels, string>) => (
          <TableStack>{params.value ?? "-"}</TableStack>
        ),
      },
      {
        field: "channels",
        headerName: t("Channels"),
        sortable: false,
        filterable: false,
        flex: 0.5,
        renderCell: (
          params: GridRenderCellParams<
            PersonInUnsavedSegmentWithChannels,
            PersonInUnsavedSegmentWithChannels["channels"]
          >,
        ) =>
          params.value ? (
            <Stack alignItems="center" height="100%" direction="row">
              {channels.map((channel) =>
                params.value?.[channel] ? (
                  <Tooltip key={channel} title={params.value[channel]}>
                    <span>
                      <ChannelIcon channel={channel} fontSize="small" />
                    </span>
                  </Tooltip>
                ) : null,
              )}
            </Stack>
          ) : null,
      },
    ],
    [t],
  );
}

const channels = ["slack", "teams", "work_email", "personal_email", "twilio"] as const;
