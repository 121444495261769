import { Alert, Box, FormControlLabel, LinearProgress, Switch } from "@mui/material";
import {
  DataGridPro,
  GridFilterModel,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UUID } from "../../../../../../utils/uuid";
import { MomentStatusDetail } from "../../../../../moment/server/supabase-moment-service";
import { useSegmentRelatedMoments } from "../../../../server/use-segment-related-moments";
import { useRelatedMomentsColumns } from "../../hooks/use-related-moments-columns";

export const SegmentRelatedMomentsDataGrid: FC<{ segmentId: UUID }> = ({ segmentId }) => {
  const { t } = useTranslation();

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "last_status_update", sort: "desc" },
  ]);

  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });

  const { data, loading, error, reload } = useSegmentRelatedMoments(segmentId);
  const columns = useRelatedMomentsColumns({ moments: data ?? undefined, reload });

  if (loading) return <LinearProgress />;
  if (error) return <Alert severity="error">{t("Unable to load related Moments")}</Alert>;

  return (
    <DataGridPro<MomentStatusDetail>
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
      initialState={{
        pagination: {
          paginationModel: { pageSize: 50, page: 0 },
          rowCount: 0,
        },
      }}
      disableRowSelectionOnClick
      getRowHeight={() => "auto"}
      rows={data}
      columns={columns}
      slots={{ toolbar: Toolbar }}
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
      filterModel={filterModel}
      onFilterModelChange={(model) => setFilterModel(model)}
    />
  );
};

const Toolbar: FC = () => {
  const [active, setActive] = useState(false);

  const { upsertFilterItem, deleteFilterItem } = useGridApiContext().current;

  useEffect(() => {
    if (active) {
      upsertFilterItem({
        field: "status",
        operator: "equals",
        value: "active",
      });
    } else {
      deleteFilterItem({ field: "status", operator: "equals" });
    }
  }, [active, upsertFilterItem, deleteFilterItem]);

  return (
    <GridToolbarContainer sx={{ pb: 2 }}>
      <GridToolbarFilterButton />
      <Box sx={{ flexGrow: 1 }} />
      <FormControlLabel
        control={
          <Switch
            checked={active}
            onChange={() => setActive(!active)}
            data-analytics-id={"segment_related_moments_show_active_moments_toggle"}
          />
        }
        label="Show active Moments only"
        labelPlacement="start"
      />
    </GridToolbarContainer>
  );
};
