import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Stack, Tab } from "@mui/material";
import { FC, SyntheticEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { GroupedMomentAnalyticsData } from "../../../../../modules/stats/server/analytics-service";
import { AnalyticsSendsTableExpandedReactionsByEmoji } from "./expanded-reactions-by-emoji";
import { AnalyticsSendsTableExpandedReactionsByPerson } from "./expanded-reactions-by-person";

export const AnalyticsSendsTableExpandedReactions: FC<{
  stats: GroupedMomentAnalyticsData["stats"];
}> = ({ stats }) => {
  const [tab, setTab] = useState<"person" | "reaction">("person");
  const { t } = useTranslation();

  const handleChange = useCallback((_: SyntheticEvent, newValue: "person" | "reaction") => {
    setTab(newValue);
  }, []);

  return (
    <Stack sx={{ px: 2, height: "100%", boxSizing: "border-box" }} direction="column">
      <TabContext value={tab}>
        <Stack direction="column" spacing={2} sx={{ my: 2 }}>
          <TabList onChange={handleChange}>
            <Tab label={t("By person")} value="person" />
            <Tab label={t("By reaction")} value="reaction" />
          </TabList>
          <TabPanel value="person">
            <AnalyticsSendsTableExpandedReactionsByPerson stats={stats} />
          </TabPanel>
          <TabPanel value="reaction">
            <AnalyticsSendsTableExpandedReactionsByEmoji stats={stats} />
          </TabPanel>
        </Stack>
      </TabContext>
    </Stack>
  );
};
