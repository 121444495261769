import { Stack, SxProps, Theme, Typography } from "@mui/material";
import { FC } from "react";

export const EmptyState: FC<
  {
    symbol: string | React.JSX.Element;
    title: string | React.JSX.Element;
    subtitle?: string | React.JSX.Element;
  } & {
    sx?: SxProps<Theme>;
  }
> = ({ symbol, title, subtitle, sx }) => {
  return (
    <Stack spacing={2} direction="column" alignItems="center" justifyContent="center" sx={sx}>
      {typeof symbol === "string" ? <Typography variant="h2">{symbol}</Typography> : symbol}
      {typeof title === "string" ? (
        <Typography variant="body1" fontWeight={700}>
          {title}
        </Typography>
      ) : (
        title
      )}
      {subtitle && (
        <>
          {typeof subtitle === "string" ? (
            <Typography variant="body2" color="text.secondary">
              {subtitle}
            </Typography>
          ) : (
            subtitle
          )}
        </>
      )}
    </Stack>
  );
};
