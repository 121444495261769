import { Box } from "@mui/material";
import { FC } from "react";
import { UUID } from "../../../utils/uuid";
import { SEQUENCE_BAR_WIDTH } from "../../sequence/sequence-bar";
import { DiscoverSequenceBar } from "../../sequence/sequence-bar-discover-v2";

export const SequencePageWrapper: FC<{
  excludeSidebar?: boolean;
  sidebar?: { type: "discover" } | { type: "moment"; sequenceId: UUID };
  children: React.ReactNode;
}> = ({ children, sidebar }) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "grid",
        gridTemplateColumns: `${SEQUENCE_BAR_WIDTH}px calc(100% - ${SEQUENCE_BAR_WIDTH}px)`,
        overflow: "hidden",
        px: 4,
      }}
    >
      {sidebar?.type === "discover" && <DiscoverSequenceBar />}
      {children}
    </Box>
  );
};
