import UpcomingOutlined from "@mui/icons-material/UpcomingOutlined";
import { Alert, Button, Stack, Typography } from "@mui/material";
import { DataGridPro, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  filterStatType,
  GroupedMomentAnalyticsData,
} from "../../../../modules/stats/server/analytics-service";
import { ISO8601 } from "../../../../utils/iso8601";
import { AnalyticsDatetime } from "../generic/analytics-datetime";
import { EmptyState } from "../generic/empty-state";
import { calculatePercentage } from "../utils";

export const AnalyticsSendsTableExpandedDeliveries: FC<{
  stats: GroupedMomentAnalyticsData["stats"];
  switchToIssuesTab: () => void;
}> = ({ stats, switchToIssuesTab }) => {
  const { t } = useTranslation();

  const { data, overall, errorCount } = useMemo(() => {
    const deliveries = filterStatType("delivery", stats);
    const errors = filterStatType("error", stats);

    return {
      data: deliveries.map((item) => item.data),
      errorCount: errors.length,
      overall: { total: deliveries.length + errors.length, count: deliveries.length },
    };
  }, [stats]);

  const rows = useMemo(() => {
    return data.map((item, id) => {
      return {
        id,
        first_name: item.recipient_data?.first_name ?? t("-"),
        last_name: item.recipient_data?.last_name ?? t("-"),
        work_email: item.recipient_data?.email ?? t("-"),
        delivery_address: item.recipient_data?.display ?? t("-"),
        delivery_time: item.timestamp,
      };
    });
  }, [data, t]);

  const columns = useMemo(() => {
    const columnData: GridColDef<(typeof rows)[0]>[] = [
      {
        field: "first_name",
        headerName: t("First Name"),
        flex: 1,
      },
      {
        field: "last_name",
        headerName: t("Last Name"),
        flex: 1,
      },
      {
        field: "work_email",
        headerName: t("Work Email"),
        flex: 1,
      },
      {
        field: "delivery_address",
        headerName: t("Delivery Address"),
        flex: 1,
      },
      {
        field: "delivery_time",
        headerName: t("Delivered"),
        flex: 1.5,
        renderCell: (params: GridRenderCellParams<(typeof rows)[0], ISO8601>) =>
          params.value ? (
            <Stack direction="row" spacing={1} alignItems="center" height="100%">
              <AnalyticsDatetime datetime={params.value} />
            </Stack>
          ) : null,
      },
    ];
    return columnData;
  }, [t]);

  const percent = calculatePercentage(overall.count, overall.total);

  const isSendToChannel = useMemo(
    () => data.length === 1 && data[0].recipient_data?.is_multiperson,
    [data],
  );

  if (!rows.length)
    return (
      <EmptyState
        symbol={<UpcomingOutlined fontSize="large" />}
        title={t("No deliveries yet")}
        subtitle={t("Check back soon")}
      />
    );

  return (
    <Stack spacing={2} direction="column">
      <Stack direction="row" spacing={2} alignItems="flex-end">
        <Typography variant="h4">{t(`${percent}%`)}</Typography>
        {!isSendToChannel && (
          <Typography variant="body1" color="text.secondary">
            <Trans i18nKey="countOverallRecipients" values={{ count: overall.count }} />
          </Typography>
        )}
      </Stack>
      {!isSendToChannel && (
        <DataGridPro
          pagination
          pageSizeOptions={[10, 25, 50, 100]}
          initialState={{ pagination: { paginationModel: { pageSize: 25 } } }}
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick
        />
      )}
      {errorCount > 0 && (
        <Alert
          severity="error"
          action={
            <Button
              data-analytics-id="switch-to-delivery-tab"
              onClick={switchToIssuesTab}
              variant="text"
              color="inherit"
            >
              {t("View delivery issues")}
            </Button>
          }
        >
          <Trans
            i18nKey="countHadDeliveryIssues"
            values={{
              count: errorCount,
              percentage: calculatePercentage(errorCount, overall.total),
            }}
          />
        </Alert>
      )}
    </Stack>
  );
};
