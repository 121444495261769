import { Typography } from "@mui/material";
import { FC } from "react";
import { formatDatetime, fromISO8601, ISO8601 } from "../../../../utils/iso8601";

export const AnalyticsDatetime: FC<{ datetime: ISO8601 | Date }> = ({ datetime }) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // If we have a Date object, keep it, otherwise convert the ISO8601 string to a Date object
  const date = datetime instanceof Date ? datetime : fromISO8601(datetime);

  return (
    <Typography variant="body2">
      {formatDatetime(date, userTimeZone, {
        year: "numeric",
        month: "short",
        day: "2-digit",
        weekday: undefined,
      })}
    </Typography>
  );
};
