import {
    Avatar,
    Card,
    CardContent,
    CardProps,
    Grid,
    SxProps,
    Theme,
    Typography,
} from "@mui/material";
import { FC } from "react";

export type IconCardIconProps = {
  icon: React.JSX.Element;
  onClick?: () => void;
  sx?: SxProps<Theme>;
};

interface Props extends CardProps {
  title: string;
  icon: IconCardIconProps;
  outlined?: boolean;
}

export const IconCard: FC<Props> = ({ title, icon, children, outlined, ...props }) => {
  return (
    <Card {...(outlined === false ? {} : { variant: "outlined" })} {...props}>
      <CardContent>
        <Grid container>
          <Grid
            sx={{
              textAlign: "center",
              align: "center",
              display: "flex",
              mb: 3,
            }}
            justifyContent="center"
            size={{xs:12}}
          >
            <Avatar onClick={icon.onClick} sx={{ height: "100px", width: "100px", ...icon.sx }}>
              {icon.icon}
            </Avatar>
          </Grid>
          <Grid size={{xs:12}} sx={{ mb: 3 }}>
            <Typography align="center" variant="h6">
              {title}
            </Typography>
          </Grid>
          {children}
        </Grid>
      </CardContent>
    </Card>
  );
};
