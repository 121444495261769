import { styled } from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";
import { FC } from "react";

const BaseLogo: FC<CommonProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 646.72 94.31"
    {...props}
  >
    <defs>
      <style>
        {
          ".cls-1{fill:#fff;}.cls-2{fill:#00b6ef;}.cls-3{fill:#0d1035;opacity:0.35;}.cls-4{fill:url(#linear-gradient);}.cls-5{fill:url(#linear-gradient-2);}"
        }
      </style>
      <linearGradient
        id="linear-gradient"
        x1="26.43"
        y1="10.75"
        x2="86.45"
        y2="70.77"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#eb0d8c" />
        <stop offset="0.17" stopColor="#d72790" />
        <stop offset="0.36" stopColor="#c24699" />
        <stop offset="0.38" stopColor="#be469a" />
        <stop offset="0.53" stopColor="#a3499b" />
        <stop offset="0.68" stopColor="#90499c" />
        <stop offset="0.83" stopColor="#854b9d" />
        <stop offset="1" stopColor="#814b9d" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="33.66"
        y1="7.75"
        x2="34.01"
        y2="8.1"
        xlinkHref="#linear-gradient"
      />
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M247.12,70.46c0-.07,0-.13,0-.2s0-.09,0-.13l0-.18,0-.15s0-.1,0-.15,0-.12-.05-.18l0-.12a1.54,1.54,0,0,0-.08-.19s0,0,0-.06l-17.42-36,0-.09-.09-.17-.08-.11-.1-.15-.09-.12-.11-.13a1.14,1.14,0,0,1-.11-.11l-.12-.11-.12-.11-.13-.1-.14-.09-.12-.08-.16-.09-.13-.07-.16-.06-.14-.06-.13,0-.19-.06h-.07l-.24-.05h0l-.28,0h-.31l-.17,0H226l-.18,0-.14,0-.15,0-.16.05-.12.05-.18.07-.11.06-.18.1-.11.07-.15.11-.12.09-.13.11a1.14,1.14,0,0,0-.11.11l-.11.11-.11.13-.1.12-.1.15-.07.11a1.62,1.62,0,0,0-.1.18.35.35,0,0,1,0,.08l-17.41,36A3.27,3.27,0,0,0,212,72l14.39-29.74v0l.08-.16,12.2,25.23h0l0,.09-.05-.09H223.08a3.27,3.27,0,1,0,0,6.53h20.78a3.36,3.36,0,0,0,.63-.06h0l.27-.07.06,0a1.06,1.06,0,0,0,.25-.09l.06,0,.11,0,.05,0a1,1,0,0,0,.17-.1l.12-.07.14-.09.13-.1L246,73l.14-.13.08-.08.13-.15.08-.1.11-.14.08-.13.08-.13a.8.8,0,0,0,.08-.16l.06-.12a1.22,1.22,0,0,0,.07-.17s0-.08,0-.12a1.21,1.21,0,0,0,.05-.18.65.65,0,0,0,0-.13l0-.16,0-.17s0-.09,0-.14,0-.13,0-.2v-.12Z"
        />
        <polygon
          className="cls-1"
          points="404.54 40.18 437.01 40.18 437.01 35.63 404.54 35.63 404.54 9.12 439.96 9.12 439.96 4.57 399.81 4.57 399.81 73.44 440.45 73.44 440.45 68.89 404.54 68.89 404.54 40.18"
        />
        <path
          className="cls-1"
          d="M480.16,34.9a13.75,13.75,0,0,0-5-4.42,16.37,16.37,0,0,0-7.71-1.66,18.07,18.07,0,0,0-5.54.86,17.82,17.82,0,0,0-5,2.54,20.21,20.21,0,0,0-4.08,4l-.36.49v-.26c0-1-.06-2-.09-2.87s-.08-1.68-.14-2.3l-.12-1.25h-4.74l.15,1.53c0,.57.11,1.29.17,2.16s.1,1.77.13,2.73l.09,2.87c0,.92,0,1.7,0,2.33V73.43h4.73V51.74a23.9,23.9,0,0,1,1.2-7.88,18.15,18.15,0,0,1,3.19-5.78,13.38,13.38,0,0,1,10.36-4.8A10.32,10.32,0,0,1,473,34.62a11,11,0,0,1,3.49,3.4,13.11,13.11,0,0,1,1.79,4.63,28,28,0,0,1,.51,5V73.43h4.72V47.51a29.77,29.77,0,0,0-.74-6.58A17.81,17.81,0,0,0,480.16,34.9Z"
        />
        <path
          className="cls-1"
          d="M529.66,36.4A19.88,19.88,0,0,0,524,30.94a21.21,21.21,0,0,0-11.47-3.3,21.73,21.73,0,0,0-8.72,1.75,20.34,20.34,0,0,0-6.9,4.87,22.39,22.39,0,0,0-4.48,7.34,25.63,25.63,0,0,0-1.59,9,25,25,0,0,0,1.6,9,21.85,21.85,0,0,0,4.47,7.25,20.86,20.86,0,0,0,6.9,4.83,21.73,21.73,0,0,0,8.72,1.74,22.25,22.25,0,0,0,6.08-.84,20.52,20.52,0,0,0,5.53-2.51,21.6,21.6,0,0,0,4.62-4.06c.32-.36.62-.74.91-1.14v3.45a41.05,41.05,0,0,1-.81,8.24,19.81,19.81,0,0,1-2.68,6.81,13.83,13.83,0,0,1-5,4.65A16.94,16.94,0,0,1,513,89.84a22.38,22.38,0,0,1-8.43-1.62,18.93,18.93,0,0,1-7.11-5.14l-1-1.11-3.3,3.29.86,1a23.42,23.42,0,0,0,8.17,6A26.5,26.5,0,0,0,513,94.31a22,22,0,0,0,10.13-2.14,18.3,18.3,0,0,0,6.68-5.78,23.84,23.84,0,0,0,3.57-8.25,43.34,43.34,0,0,0,1-9.58V28.87h-4.73Zm-17.14-4.3a16.06,16.06,0,0,1,6.87,1.46,17.17,17.17,0,0,1,5.43,4,18.62,18.62,0,0,1,3.61,5.87,20.13,20.13,0,0,1,0,14.34,19.09,19.09,0,0,1-3.62,5.93,16.81,16.81,0,0,1-5.42,4,16.06,16.06,0,0,1-6.87,1.46,16.48,16.48,0,0,1-7-1.46,16,16,0,0,1-5.32-4,18.1,18.1,0,0,1-3.4-5.91,21.75,21.75,0,0,1,0-14.25,18.16,18.16,0,0,1,3.4-5.9,16.53,16.53,0,0,1,5.33-4A16,16,0,0,1,512.52,32.1Z"
        />
        <path
          className="cls-1"
          d="M546.09,5.77a3.55,3.55,0,0,0-1,2.57,3.65,3.65,0,0,0,1,2.66,3.26,3.26,0,0,0,4.72,0,3.61,3.61,0,0,0,1.11-2.7,3.55,3.55,0,0,0-1-2.57A3.36,3.36,0,0,0,546.09,5.77Z"
        />
        <rect className="cls-1" x="546.13" y="30.11" width="4.73" height="43.38" />
        <path
          className="cls-1"
          d="M594.9,34.9a13.75,13.75,0,0,0-5-4.42,16.4,16.4,0,0,0-7.71-1.66,18.07,18.07,0,0,0-5.54.86,17.69,17.69,0,0,0-5,2.54,20,20,0,0,0-4.08,4l-.36.49a2.35,2.35,0,0,0,0-.26c0-1-.05-2-.08-2.87s-.08-1.68-.14-2.3l-.13-1.25h-4.74l.15,1.53q.09.85.18,2.16c0,.86.1,1.77.13,2.73l.09,2.87c0,.92,0,1.7,0,2.33V73.43h4.73V51.74a23.9,23.9,0,0,1,1.2-7.88,18.15,18.15,0,0,1,3.19-5.78,13.38,13.38,0,0,1,10.36-4.8,10.32,10.32,0,0,1,5.53,1.34,11,11,0,0,1,3.49,3.4A13.11,13.11,0,0,1,593,42.65a28,28,0,0,1,.5,5V73.43h4.73V47.51a29.77,29.77,0,0,0-.74-6.58A17.81,17.81,0,0,0,594.9,34.9Z"
        />
        <path
          className="cls-1"
          d="M646.72,48.4a24.09,24.09,0,0,0-1.22-7.54,19.9,19.9,0,0,0-3.76-6.69,19,19,0,0,0-6.34-4.76,20.75,20.75,0,0,0-8.91-1.81,20.36,20.36,0,0,0-8.17,1.66,19.68,19.68,0,0,0-6.61,4.7,22.32,22.32,0,0,0-4.39,7.15,24.69,24.69,0,0,0-1.59,9,27.43,27.43,0,0,0,1.53,9.29,21.63,21.63,0,0,0,4.44,7.45,20.58,20.58,0,0,0,6.86,4.87,21.5,21.5,0,0,0,8.77,1.75,21.76,21.76,0,0,0,9.93-2.18,22.06,22.06,0,0,0,7.49-6.3l.8-1L642,60.84l-.9,1.19a17.43,17.43,0,0,1-6,5.12,17,17,0,0,1-7.83,1.74,16.29,16.29,0,0,1-7.49-1.61A15.74,15.74,0,0,1,614.61,63a18.58,18.58,0,0,1-3.11-6,23.48,23.48,0,0,1-1-5.53h36.22ZM614.28,38.07a16.22,16.22,0,0,1,4.92-4.3,14.12,14.12,0,0,1,7.15-1.71,16.71,16.71,0,0,1,6.35,1.17,13.92,13.92,0,0,1,4.82,3.29,15.86,15.86,0,0,1,3.2,5.16,19.06,19.06,0,0,1,1.2,5.33H610.63a19.52,19.52,0,0,1,.82-3.52A18.42,18.42,0,0,1,614.28,38.07Z"
        />
        <path
          className="cls-1"
          d="M146.83,57.61a18.41,18.41,0,0,1-7.57,6.81h0a22.4,22.4,0,0,1-9.61,2.14,23.51,23.51,0,0,1-10.16-2.14,23,23,0,0,1-7.67-5.76A25.93,25.93,0,0,1,107,50.22a30,30,0,0,1-1.71-10.14,30.78,30.78,0,0,1,1.67-10.15,25.32,25.32,0,0,1,4.82-8.44,23.1,23.1,0,0,1,7.67-5.76,23.71,23.71,0,0,1,10.24-2.13,19.14,19.14,0,0,1,5.24.68,21.75,21.75,0,0,1,4.51,1.79A16.48,16.48,0,0,1,143,18.61a14.83,14.83,0,0,1,2.41,2.86l.41.65L152,17.38l-.37-.55a18.7,18.7,0,0,0-4-4.27,26.39,26.39,0,0,0-5.26-3.21,31.17,31.17,0,0,0-6.16-2.06,30.53,30.53,0,0,0-6.68-.74,32.43,32.43,0,0,0-12.79,2.53,31.12,31.12,0,0,0-10.21,7A32.3,32.3,0,0,0,99.8,26.67a36,36,0,0,0-2.44,13.41,36.61,36.61,0,0,0,2.4,13.4,32.08,32.08,0,0,0,6.68,10.59,30.51,30.51,0,0,0,10.22,7,32.71,32.71,0,0,0,12.91,2.53,29.61,29.61,0,0,0,7.74-1A28.35,28.35,0,0,0,143.79,70,25.33,25.33,0,0,0,149,66.17a26.92,26.92,0,0,0,3.81-4.63l.37-.58L147.21,57Z"
        />
        <path
          className="cls-1"
          d="M180.76,30a16.82,16.82,0,0,0-8.37,2.21,13.42,13.42,0,0,0-4.51,4V5.66h-7.2v68.1h7.2V51.89a21.89,21.89,0,0,1,1-7,14.32,14.32,0,0,1,2.6-4.8,10.3,10.3,0,0,1,3.64-2.77,10.19,10.19,0,0,1,4.2-.91,11,11,0,0,1,4.94,1,8,8,0,0,1,3.06,2.71,12.22,12.22,0,0,1,1.65,4.3,30.81,30.81,0,0,1,.5,5.78V73.76h7.2v-26c0-5.26-1.33-9.56-4-12.79S186,30,180.76,30Z"
        />
        <path
          className="cls-1"
          d="M276.27,30a16.65,16.65,0,0,0-8.47,2.34,14.73,14.73,0,0,0-4.71,4.26c0-.37,0-.75,0-1.17-.05-1.36-.14-2.62-.25-3.75l-.07-.62h-6.93l.06.75c.11,1.39.2,3,.25,4.73s.09,3.22.09,4.3V73.71h7.2V51.83a21.94,21.94,0,0,1,1-7A14.25,14.25,0,0,1,267,40.07a10.49,10.49,0,0,1,3.65-2.78,10.28,10.28,0,0,1,4.2-.91,10.84,10.84,0,0,1,4.93,1,7.66,7.66,0,0,1,3.06,2.71,11.84,11.84,0,0,1,1.65,4.3,30,30,0,0,1,.5,5.78V73.71h7.2v-26c0-5.26-1.33-9.56-4-12.8S281.53,30,276.27,30Z"
        />
        <path
          className="cls-1"
          d="M336.06,36.29a16.7,16.7,0,0,0-5.74-4.71,19.41,19.41,0,0,0-8.85-2.2,21.7,21.7,0,0,0-8.78,1.74A20.14,20.14,0,0,0,306,35.87a21.88,21.88,0,0,0-4.32,7,23.7,23.7,0,0,0-1.54,8.54,24,24,0,0,0,1.54,8.64A20.23,20.23,0,0,0,306,67a20,20,0,0,0,6.73,4.53,22.57,22.57,0,0,0,8.76,1.64,19.78,19.78,0,0,0,8.89-2.19,16.4,16.4,0,0,0,5.7-4.62v5.76a22.07,22.07,0,0,1-.83,6.12,13.52,13.52,0,0,1-2.55,4.91,12.15,12.15,0,0,1-4.57,3.29,19,19,0,0,1-15.41-.9,18.76,18.76,0,0,1-6.3-5.19l-.5-.67-5.19,5.68.44.47A26.19,26.19,0,0,0,310,92a26.85,26.85,0,0,0,10.87,2.27A24.74,24.74,0,0,0,332,92.11a19.69,19.69,0,0,0,6.86-5.49,19.1,19.1,0,0,0,3.45-7.23,31.86,31.86,0,0,0,.92-7.31V30.44h-7.2Zm-14,30.33A14.7,14.7,0,0,1,316,65.4a12.94,12.94,0,0,1-4.5-3.31,14.79,14.79,0,0,1-2.81-4.89,18.35,18.35,0,0,1,0-11.89,14.89,14.89,0,0,1,2.81-4.89A13.63,13.63,0,0,1,316,37.06a14.34,14.34,0,0,1,6.11-1.25,13.71,13.71,0,0,1,5.85,1.25,15,15,0,0,1,4.65,3.38,15.57,15.57,0,0,1,3.07,4.9,16,16,0,0,1,1.1,5.92,17.43,17.43,0,0,1-1.1,6.32,14.43,14.43,0,0,1-3.06,4.84,13.28,13.28,0,0,1-4.63,3.1A15.43,15.43,0,0,1,322.07,66.62Z"
        />
        <path
          className="cls-1"
          d="M390.53,41.86h0a20.1,20.1,0,0,0-3.76-6.72,18,18,0,0,0-6.22-4.59A20.67,20.67,0,0,0,372,28.86a20.2,20.2,0,0,0-8.23,1.69,20.44,20.44,0,0,0-6.62,4.7,21.9,21.9,0,0,0-4.42,7.11,24.6,24.6,0,0,0-1.58,8.9,24,24,0,0,0,1.63,8.9,22.49,22.49,0,0,0,4.45,7.11A20.09,20.09,0,0,0,364,72a21.44,21.44,0,0,0,8.51,1.69,22.26,22.26,0,0,0,9.56-2.09,22.58,22.58,0,0,0,8.16-6.92l.41-.53-5.5-4.46-.42.68a15.17,15.17,0,0,1-4.78,4.76,13.24,13.24,0,0,1-7.43,2.05A13.1,13.1,0,0,1,367.06,66a13.95,13.95,0,0,1-7.33-7.61,15.86,15.86,0,0,1-1.11-4.87h33l.06-.62a14.74,14.74,0,0,0,.09-1.57V50A25.92,25.92,0,0,0,390.53,41.86Zm-31.79,5.53a13.64,13.64,0,0,1,1.15-4.09,14.14,14.14,0,0,1,2.85-4.12,13.57,13.57,0,0,1,4.14-2.86,12.47,12.47,0,0,1,5.1-1c3.92,0,7,1.21,9.08,3.6a14.28,14.28,0,0,1,3.41,8.51Z"
        />
        <path
          className="cls-2"
          d="M68.39,79.38,63.12,69H33.21a6.27,6.27,0,0,0,0,12.54H73.07C73.09,80.72,69.58,80.74,68.39,79.38Z"
        />
        <polygon
          className="cls-3"
          points="68.39 79.38 63.12 68.98 58.08 68.98 68.4 79.39 68.39 79.38"
        />
        <path
          className="cls-2"
          d="M39.76,20.64,33.69,7.74a6.25,6.25,0,0,1,.54-4.38l-.09.17L.63,72.51a6.28,6.28,0,0,0,2.9,8.38,6.19,6.19,0,0,0,2.73.63A6.26,6.26,0,0,0,11.9,78L37.75,24.77l2-4.13Z"
        />
        <polygon
          className="cls-3"
          points="39.76 20.64 33.68 7.73 33.71 7.84 37.75 24.77 39.76 20.64 39.76 20.64"
        />
        <path
          className="cls-4"
          d="M73.11,81.52A6.26,6.26,0,0,1,67.46,78L34.13,9A6.27,6.27,0,0,1,45.42,3.54l33.33,69a6.26,6.26,0,0,1-2.92,8.36A6.18,6.18,0,0,1,73.11,81.52Z"
        />
        <path className="cls-5" d="M33.71,7.84s0-.07,0-.1h0Z" />
      </g>
    </g>
  </svg>
);

export const Logo = styled(BaseLogo)``;
