import { useCallback } from "react";
import { useToken } from "../../server/auth/use-token";
import { huddle } from "../huddle/huddle";

export function useDesignHuddleProjectClone(): (
  projectId: string,
  pageId?: string | null,
) => Promise<string> {
  const getToken = useToken();

  return useCallback(
    async (projectId: string, pageId?: string | null) => {
      const token = await getToken();

      const response = await huddle.POST("/project/{projectId}/clone", {
        params: { path: { projectId } },
        headers: { Authorization: `Bearer ${token}` },
        body: {
          ...(pageId ? { pageId } : {}),
        },
      });

      if (!response.data?.projectId) {
        throw new Error("Failed to clone project");
      }

      return response.data.projectId;
    },
    [getToken],
  );
}
