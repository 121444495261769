import { Button, Dialog } from "@mui/material";
import { FC, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSupabaseCallback } from "../../../../server/supabase/hooks";
import { useDialog } from "../../../../utils/hooks/use-dialog";
import { useSegmentService } from "../../hooks/use-segment-service";
import { UniqueSegmentNameError } from "../../server/segment-state-service";
import { SupabaseSegmentService } from "../../server/supabase-segment-service";
import { Segment } from "../../types";
import { SegmentModalForm } from "./segment-modal/segment-modal-form";
import { SegmentModalFormWrapper } from "./segment-modal/segment-modal-form-wrapper";

export const ManageDefaultSegmentButton: FC = () => {
  const { t } = useTranslation();
  const dialog = useDialog<Segment | null>();
  const [loading, setLoading] = useState(false);

  const loadSegment = useSupabaseCallback(
    async ({ supabase }) => {
      setLoading(true);

      const { data } = await new SupabaseSegmentService(supabase).getDefaultSegment();

      setLoading(false);

      return dialog.handleOpen(data);
    },
    [dialog],
  );

  return (
    <>
      <Button
        data-analytics-id="segment-set-default-filters"
        loading={loading}
        onClick={() => void loadSegment().catch(console.error)}
        variant="contained"
      >
        {t("Set default filters")}
      </Button>
      <Dialog open={dialog.open} onClose={dialog.handleClose} fullWidth>
        <EditDefaultSegmentDialog segment={dialog.data ?? null} handleClose={dialog.handleClose} />
      </Dialog>
    </>
  );
};

const EditDefaultSegmentDialog: FC<{ segment: Segment | null; handleClose: () => void }> = ({
  segment,
  handleClose,
}) => {
  const { t } = useTranslation();
  const saveSegment = useSegmentService();

  const onSave = useSupabaseCallback(
    async ({ supabase }, data: Segment | null) => {
      if (!data) return;

      try {
        await saveSegment({
          ...segment,
          ...data,
        });
      } catch (e) {
        if (e instanceof UniqueSegmentNameError) {
          return {
            "segment.name": t("Segment name must be unique"),
          };
        }

        console.error("Error editing default Segment", e);
        toast.error(t("Error editing default Segment"));
        return;
      }

      await new SupabaseSegmentService(supabase).update(data.id, { is_default: true });

      handleClose();
      toast.success(t("Default segment edited successfully"));
      return;
    },
    [saveSegment, t, segment, handleClose],
  );

  return (
    <SegmentModalFormWrapper
      onSave={onSave}
      onCancel={handleClose}
      defaultSegmentManager={true}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-expect-error
      initialValues={segment ? { segment } : undefined}
      isRecipientType={false}
    >
      <SegmentModalForm defaultSegmentManager={true} isRecipientType={false} />
    </SegmentModalFormWrapper>
  );
};
