import { useSupabaseLoader } from "../../../../../server/supabase/hooks";
import { UseSupabaseLoaderState } from "../../../../../server/supabase/hooks/types";
import {
  PersonInUnsavedSegmentWithChannels,
  SupabaseSegmentService,
} from "../../../server/supabase-segment-service";
import { Segment } from "../../../types";

export function useSegmentRecipients(
  segment: Pick<Segment, "operation" | "conditions">,
): UseSupabaseLoaderState<PersonInUnsavedSegmentWithChannels[]> {
  return useSupabaseLoader(
    async ({ supabase, account_id }) => {
      const { data, error } = await new SupabaseSegmentService(supabase).getAllUnsavedRecipients(
        account_id,
        segment,
      );
      if (error) throw new Error(error.message);
      return data.map((x) => ({
        ...x,
        channels: x.channels as PersonInUnsavedSegmentWithChannels["channels"],
      }));
    },
    [segment],
  );
}
