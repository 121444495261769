import MailOutline from "@mui/icons-material/MailOutline";
import { Stack, Typography } from "@mui/material";
import { DataGridPro, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  filterStatType,
  GroupedMomentAnalyticsData,
} from "../../../../modules/stats/server/analytics-service";
import { ISO8601 } from "../../../../utils/iso8601";
import { AnalyticsDatetime } from "../generic/analytics-datetime";
import { EmptyState } from "../generic/empty-state";
import { calculatePercentage } from "../utils";
import { AnalyticsSendsTableMissingOpens } from "./missing-opens";

export const AnalyticsSendsTableExpandedOpens: FC<{
  stats: GroupedMomentAnalyticsData["stats"];
}> = ({ stats }) => {
  const { t } = useTranslation();

  const { data, overall, missing } = useMemo(() => {
    const opens = filterStatType("grouped_open", stats).map((x) => x.data);
    const deliveries = filterStatType("delivery", stats);

    return {
      data: opens,
      missing: deliveries.filter((delivery) => {
        return !opens.some((open) => open.recipient_data?.id === delivery.data.recipient_data?.id);
      }),
      overall: {
        total: deliveries.length,
        count: opens.length,
      },
    };
  }, [stats]);

  const rows = useMemo(() => {
    return data.map((item, id) => {
      return {
        id,
        first_name: item.recipient_data?.first_name ?? t("-"),
        last_name: item.recipient_data?.last_name ?? t("-"),
        first_open: item.min_timestamp,
        most_recent_open: item.max_timestamp,
      };
    });
  }, [data, t]);

  const columns = useMemo(() => {
    const columnData: GridColDef<(typeof rows)[0]>[] = [
      {
        field: "first_name",
        headerName: t("First Name"),
        flex: 1,
      },
      {
        field: "last_name",
        headerName: t("Last Name"),
        flex: 1,
      },
      {
        field: "first_open",
        headerName: t("First Open"),
        flex: 1.5,
        renderCell: (params: GridRenderCellParams<(typeof rows)[0], ISO8601>) =>
          params.value ? (
            <Stack direction="row" spacing={1} alignItems="center" height="100%">
              <AnalyticsDatetime datetime={params.value} />
            </Stack>
          ) : null,
      },
      {
        field: "most_recent_open",
        headerName: t("Most Recent Open"),
        flex: 1.5,
        renderCell: (params: GridRenderCellParams<(typeof rows)[0], ISO8601>) =>
          params.value ? (
            <Stack direction="row" spacing={1} alignItems="center" height="100%">
              <AnalyticsDatetime datetime={params.value} />
            </Stack>
          ) : null,
      },
    ];
    return columnData;
  }, [t]);

  const percent = calculatePercentage(overall.count, overall.total);

  if (!rows.length)
    return (
      <EmptyState
        symbol={<MailOutline fontSize="large" />}
        title={t("No opens yet")}
        subtitle={t("Check back soon")}
      />
    );

  return (
    <Stack spacing={2} direction="column">
      <Stack direction="row" spacing={2} alignItems="flex-end">
        <Typography variant="h4">{t(`${percent}%`)}</Typography>
        <Typography variant="body1" color="text.secondary">
          <Trans i18nKey="countOverallRecipients" values={{ count: overall.count }} />
        </Typography>
      </Stack>
      <Typography variant="body2" color="text.secondary">
        {t("These numbers only count people who received the email.")}
      </Typography>
      <DataGridPro
        pagination
        pageSizeOptions={[10, 25, 50, 100]}
        initialState={{ pagination: { paginationModel: { pageSize: 25 } } }}
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
      />
      <AnalyticsSendsTableMissingOpens deliveries={missing.map((x) => x.data)} />
    </Stack>
  );
};
