import ContentCopy from "@mui/icons-material/ContentCopy";
import Edit from "@mui/icons-material/Edit";
import RestoreFromTrash from "@mui/icons-material/RestoreFromTrash";
import { Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { FC, JSX, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "../../../generic/components/crud-data-grid/types";
import { SegmentCrudDialogState } from "./hooks/use-segment-crud-datagrid-dialog";
import { SegmentRow } from "./types";

export function useSegmentCrudDataGridActions(
  openDialog: (data?: SegmentCrudDialogState) => void,
): (actions: JSX.Element[], row?: Row<Row<SegmentRow>>) => JSX.Element[] {
  return useCallback(
    (defaultActions, row) => {
      let actions = [];
      if (!row?.deleted_at) actions = defaultActions;
      else {
        actions = [
          <GridActionsCellItem
            key={row.id}
            icon={<RestoreFromTrashTooltip />}
            label="Unarchive"
            onClick={() =>
              openDialog({
                type: "unarchive",
                data: { segment_id: row.id, segment_name: row.name },
              })
            }
            color="inherit"
            disabled={false}
          />,
        ];
      }

      if (row?.id) {
        actions = [
          ...actions,
          <GridActionsCellItem
            key="edit"
            icon={<EditTooltip />}
            label="Edit"
            onClick={() => openDialog({ type: "edit", data: { segment_id: row?.id } })}
            color="inherit"
            disabled={false}
          />,
          <GridActionsCellItem
            key="clone"
            icon={<CloneTooltip />}
            label="Clone"
            onClick={() => openDialog({ type: "clone", data: { segment_id: row?.id } })}
            color="inherit"
            disabled={false}
          />,
        ];
      }

      return actions;
    },
    [openDialog],
  );
}

const CloneTooltip: FC = () => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t("Clone")}>
      <ContentCopy data-testid="ContentCopyIcon" />
    </Tooltip>
  );
};

const EditTooltip: FC = () => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t("Edit")}>
      <Edit />
    </Tooltip>
  );
};

const RestoreFromTrashTooltip: FC = () => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t("Unarchive")}>
      <RestoreFromTrash data-testid="RestoreFromTrashIcon"/>
    </Tooltip>
  );
};
