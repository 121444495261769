import { atom, useAtom } from "jotai";
import { omit } from "lodash-es";
import { useGetDesignHuddleAccessToken } from "../../modules/design-huddle/use-design-huddle-access-token";
import { SupabaseDesignHuddleCustomBrandService } from "../../modules/generic/server/supabase-account-service";
import { AugmentedTemplate } from "../../modules/multiformat/store/custom-templates-atom";
import { useSupabaseLoader } from "../../server/supabase/hooks";

const customTemplateCache = atom<CustomTemplates | null>(null);

export type CustomTemplates = {
  [key: string]: AugmentedTemplate[];
};
export const useCustomTemplates = (): ReturnType<
  typeof useSupabaseLoader<CustomTemplates | null>
> => {
  const getAccessToken = useGetDesignHuddleAccessToken();
  const [customTemplates, setCustomTemplates] = useAtom(customTemplateCache);

  return useSupabaseLoader(
    async ({ supabase }) => {
      if (customTemplates) return customTemplates;

      const customBrandService = new SupabaseDesignHuddleCustomBrandService(supabase);
      const { data: customBrandData, error: customBrandError } =
        await customBrandService.maybeGet();

      if (customBrandError) throw new Error(customBrandError.message);

      if (!customBrandData || !customBrandData.brand_id) return null;

      DSHDLib.configure({
        access_token: await getAccessToken([]),
        visitor: false,
      });

      const templates = await getCustomBrandTemplates(customBrandData.brand_id);
      setCustomTemplates(templates);
      return templates;
    },
    [customTemplates, getAccessToken, setCustomTemplates],
  );
};

export async function getCustomBrandTemplates(brand_id: number): Promise<CustomTemplates> {
  return new Promise((resolve, reject) => {
    DSHDLib.getTemplates({ brand_id, search: "p0" }, (err, data) => {
      if (err) return reject(err);

      const templates = data.items
        .map((template) => {
          const [env, shortFormat, name] = template.template_title.split("_", 3);

          const abbreviations: { [key: string]: string } = {
            MO: "Manager One-Pager",
            FA: "FAQ",
            PS: "Poster",
            ZB: "Zoom Background",
            CT: "Certificate",
            TV: "TV Display",
            LS: "Square Logo",
            LL: "Landscape Logo",
            HG: "Horizontal Banner",
            SC: "Square Comms",
            LC: "Landscape Comms",
          };

          const format = abbreviations[shortFormat] ? abbreviations[shortFormat] : shortFormat;

          return {
            env,
            type: ["LC", "SC"].includes(shortFormat) ? "moment" : "multiformat",
            format,
            name,
            template,
            page_count: template.page_count,
            id: `custom-brand-template-${template.template_id}`,
          };
        })
        .filter(({ env }) => env === "p0")
        .sort((a, b) => a.name.localeCompare(b.name));

      resolve(
        templates.reduce<{ [key: string]: AugmentedTemplate[] }>((acc, { format, ...rest }) => {
          if (!acc[format]) acc[format] = [];
          acc[format].push(omit(rest, "env"));
          return acc;
        }, {}),
      );
    });
  });
}
