import { ISO8601_DATE } from "../../../utils/iso8601";
import { TemplateType } from "../../generic/atoms/types/template";

type SequenceTemplateQuery = {
  template_slug?: string;
  sequence_slug: string;
};

type DiscoverTemplateQuery = {
  template_slug: string;
  program_slug: string;
};

export type LibraryQuery = DiscoverTemplateQuery | SequenceTemplateQuery;

export const isSequenceTemplateQuery = (query: LibraryQuery): query is SequenceTemplateQuery => {
  return "sequence_slug" in query && "template_slug" in query;
};

export const isDiscoverTemplateQuery = (query: LibraryQuery): query is DiscoverTemplateQuery => {
  return "template_slug" in query && "program_slug" in query;
};

export type Library = {
  programs: LibraryProgram[];
  sequences: LibrarySequence[];
};

export type LibraryProgram = {
  slug: string;
  name: string;
  photo?: string;
  color?: string;
  templates: LibraryTemplate[];
};

export type LibrarySequence = {
  type: TemplateType.sequence;
  id: string;
  slug: string;
  title: string;
  description: string;
  img: LibrarySequenceImage;
  templates: LibraryTemplate[];
};

export type LibraryTemplate = {
  type: TemplateType.moment;
  id: string;
  slug: string;
  title: string;
  what: string;
  context: string;
  img: LibraryTemplateImage;
  content: {
    email_subject: string;
    professional: string;
    casual: string;
    direct: string;
    custom?: string;
  };
  recommendations: {
    channel: string;
    send_time: string;
  };
  date: ISO8601_DATE | null;
  end_date: ISO8601_DATE | null;
  trigger: string | null;
  segment: LibrarySegment;
  trending: string | null;
  hero: boolean | null;
  top: number | null;
  geo: string | null;
  program: Omit<LibraryProgram, "templates">;
};

export type LibrarySegment =
  | "#general"
  | "All employees"
  | "All managers"
  | "Managers of All Employees"
  | "";

export type LibraryTemplateImage = {
  template_code: string;
  header?: string;
  subheader?: string;
  search?: string;
};

export type LibrarySequenceImage = {
  thumbnail: string;
};
