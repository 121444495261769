import { Dialog, LinearProgress } from "@mui/material";
import { FC, useState } from "react";
import { useFlags } from "../../../../../server/optimizely";
import { useSupabaseLoader } from "../../../../../server/supabase/hooks";
import { SupabaseSegmentService } from "../../../server/supabase-segment-service";
import { SegmentModalForm } from "./segment-modal-form";
import { SegmentModalFormWrapper } from "./segment-modal-form-wrapper";
import {
  EditModalTab,
  TabbedEditSegmentModal,
} from "./tabbed-edit-segment-modal/tabbed-edit-segment-modal";
import { EditSegmentModalProps } from "./types";

export const EditSegmentModal: FC<EditSegmentModalProps> = ({
  open,
  segmentId,
  onSave,
  onCancel,
  disabled,
  defaultTab,
}) => {
  const {
    flags: { editable_segments },
    flagsLoaded,
  } = useFlags("editable_segments");

  const [tab, setTab] = useState<EditModalTab>(defaultTab ?? "DETAILS");

  const { data, loading } = useSupabaseLoader(
    async ({ supabase }) => {
      if (!segmentId) return;
      const resp = await new SupabaseSegmentService(supabase).getSegmentData(segmentId);

      if (resp.error) throw resp.error;

      return resp.data;
    },
    [segmentId],
  );

  return (
    <Dialog open={open} fullWidth maxWidth="lg" onClose={onCancel}>
      {(!flagsLoaded || loading) && <LinearProgress />}
      {flagsLoaded && data && (
        <SegmentModalFormWrapper
          onSave={onSave}
          onCancel={onCancel}
          initialValues={{ segment: data }}
          isRecipientType={data.is_recipient_type ?? false}
          showButtons={!editable_segments || tab === "DETAILS"}
        >
          {editable_segments ? (
            <TabbedEditSegmentModal segment={data} disabled={disabled} tab={tab} setTab={setTab} />
          ) : (
            <SegmentModalForm
              isRecipientType={data.is_recipient_type ?? false}
              disabled={disabled}
            />
          )}
        </SegmentModalFormWrapper>
      )}
    </Dialog>
  );
};
