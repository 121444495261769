import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CsvExportButton } from "../../../../../modules/generic/components/csv-export-button";
import { GroupedMomentAnalyticsData } from "../../../../../modules/stats/server/analytics-service";

export type DeliveryStat = Extract<
  GroupedMomentAnalyticsData["stats"][number],
  { stat_type: "delivery" }
>["data"];

export const MissingByPerson: FC<{ missing: DeliveryStat[] }> = ({ missing }) => {
  const { t } = useTranslation();

  const rows = useMemo(() => {
    return missing.map((item, id) => {
      return {
        id,
        first_name: item.recipient_data?.first_name ?? t("-"),
        last_name: item.recipient_data?.last_name ?? t("-"),
        work_email: item.recipient_data?.email ?? t("-"),
      };
    });
  }, [missing, t]);

  const columns = useMemo(() => {
    const columnData: GridColDef<(typeof rows)[0]>[] = [
      {
        field: "first_name",
        headerName: t("First Name"),
        flex: 1,
      },
      {
        field: "last_name",
        headerName: t("Last Name"),
        flex: 1,
      },
      {
        field: "work_email",
        headerName: t("Email"),
        flex: 0.5,
      },
    ];
    return columnData;
  }, [t]);

  return (
    <DataGridPro
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
      initialState={{ pagination: { paginationModel: { pageSize: 25 } } }}
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      slots={{ toolbar: CsvExportButton }}
    />
  );
};
