import { atom } from "jotai";
import { multiformatAtom } from "./multiformat-atom";
import { ContentTypeOption } from "./types";

export const contentTypeOptionsAtom = atom<ContentTypeOption[] | undefined>((get) => {
  const multiformat = get(multiformatAtom);

  const sorting = [
    "Moment",
    "Landscape Logo",
    "Square Logo",
    "TV Display",
    "Certificate",
    "Zoom Background",
    "Poster",
    "FAQ",
    "Manager One-Pager",
    "Horizontal Banner",
  ];

  return multiformat?.["content-types"]
    .filter((c) => sorting.includes(c.content_type))
    .sort((a, b) => sorting.indexOf(a.content_type) - sorting.indexOf(b.content_type))
    .map((c) => ({
      label: c.content_type,
      value: c.content_type,
    }));
});
