import { Alert, Dialog, LinearProgress } from "@mui/material";
import { FC } from "react";
import { useSupabaseLoader } from "../../../../../server/supabase/hooks";
import { UUID, uuidv4 } from "../../../../../utils/uuid";
import { useSegmentFields } from "../../../hooks/use-segment-fields";
import { SupabaseSegmentService } from "../../../server/supabase-segment-service";
import { Segment } from "../../../types";
import { SegmentModalForm } from "./segment-modal-form";
import { SegmentFormSaveErrors, SegmentModalFormWrapper } from "./segment-modal-form-wrapper";

export const CloneSegmentModal: FC<{
  open: boolean;
  segmentId: UUID | undefined;
  onSave: (segment: Segment | null) => Promise<SegmentFormSaveErrors | undefined>;
  onCancel: () => void;
}> = ({ segmentId, open, onCancel, onSave }) => {
  const segmentFields = useSegmentFields();

  const { data, loading, error } = useSupabaseLoader(
    async ({ supabase }) => {
      if (!segmentId) return;
      const service = new SupabaseSegmentService(supabase);
      const resp = await service.getSegmentData(segmentId);

      if (resp.error || !resp.data) throw resp.error;

      return service.cloneSegment(resp.data, uuidv4());
    },

    [segmentId],
  );

  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      {loading && <LinearProgress />}
      {error && <Alert severity="error">Unable to load Segment data</Alert>}
      {data && segmentFields.data && (
        <SegmentModalFormWrapper
          onSave={onSave}
          onCancel={onCancel}
          initialValues={{ segment: data }}
          isRecipientType={data.is_recipient_type ?? false}
        >
          <SegmentModalForm isRecipientType={data.is_recipient_type ?? false} />
        </SegmentModalFormWrapper>
      )}
    </Dialog>
  );
};
