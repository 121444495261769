import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export const ErrorModal: FC<{
  open: boolean;
  handleClose: () => void;
}> = ({ open, handleClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ width: "100%", maxWidth: 600 }}>
          <img
            src="https://images.changeengine.com/uh-oh.svg"
            alt="something went wrong"
            style={{ width: "100%", height: "auto", display: "block" }}
          ></img>
          <Box sx={{ p: 2 }}>
            {t(
              "Something went wrong when trying to edit this image. Make sure your internet connection is active, and try again.",
            )}
            <br />
            {t("If this error persists, please contact support@changeengine.com")}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button onClick={handleClose} data-analytics-id="error-modal-go-back" variant="contained">
          {t("Go back")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
