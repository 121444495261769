import { Alert, Dialog, LinearProgress } from "@mui/material";
import { FC } from "react";
import { useSupabaseLoader } from "../../../../../server/supabase/hooks";
import { uuidv4 } from "../../../../../utils/uuid";
import { SupabaseSegmentService } from "../../../server/supabase-segment-service";
import { Segment, makeDefaultSegment } from "../../../types";
import { SegmentModalForm } from "./segment-modal-form";
import { SegmentFormSaveErrors, SegmentModalFormWrapper } from "./segment-modal-form-wrapper";

export const NewSegmentModal: FC<{
  open: boolean;
  onSave: (segment: Segment | null) => Promise<SegmentFormSaveErrors | undefined>;
  onCancel: () => void;
  isRecipientType: boolean;
}> = ({ open, onSave, onCancel, isRecipientType }) => {
  const { data, loading, error } = useSupabaseLoader(
    async ({ supabase }) => {
      if (isRecipientType)
        return { ...makeDefaultSegment(uuidv4()), is_recipient_type: isRecipientType };

      const service = new SupabaseSegmentService(supabase);
      const resp = await service.getDefaultSegment();

      if (resp.error) throw error;

      if (!resp.data)
        return { ...makeDefaultSegment(uuidv4()), is_recipient_type: isRecipientType };

      return { ...service.cloneSegment(resp.data, uuidv4()), description: "" };
    },
    [isRecipientType],
  );

  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      {loading && <LinearProgress />}
      {error && <Alert severity="error">Unable to load Segment data</Alert>}
      {data && (
        <SegmentModalFormWrapper
          onSave={onSave}
          onCancel={onCancel}
          initialValues={{ segment: data }}
          isRecipientType={isRecipientType}
        >
          <SegmentModalForm isRecipientType={isRecipientType} />
        </SegmentModalFormWrapper>
      )}
    </Dialog>
  );
};
