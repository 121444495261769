import { CircularProgress, Grid } from "@mui/material";
import { useAtomValue, useSetAtom } from "jotai";
import { get, omit } from "lodash-es";
import { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { redirect, useNavigate, useParams } from "react-router";
import { useBrandKit } from "../../modules/brand-kit/hooks/use-brand-kit";
import { useBrandTones } from "../../modules/brand-kit/hooks/use-brand-tones";
import { useResetBrandingImageType } from "../../modules/brand-kit/hooks/use-reset-branding-image-type";
import { BrandingBar } from "../../modules/branding-bar/branding-bar";
import { DiscoverCard } from "../../modules/discover/carousel/discover-card";
import { programSubheadingOverrides } from "../../modules/discover/carousel/discover-carousels";
import { useDiscoverLibraryLoader } from "../../modules/discover/hooks/use-libary-loader";
import { discoverProgramAtom, discoverProgramSlugAtom } from "../../modules/discover/store";
import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { Breadcrumbs } from "../../modules/tabbed-programs/breadcrumbs";
import { toSlug } from "../../utils/create-slug";

export const DiscoverProgramPage: FC = () => {
  useDiscoverLibraryLoader();
  const { t } = useTranslation();
  const { branding, loading } = useBrandKit();
  const { data: brandTones, loading: loadingBrandTone } = useBrandTones();
  const brandTone = useMemo(() => brandTones?.find((b) => b.is_default), [brandTones]);
  const navigate = useNavigate();
  const { program_slug } = useParams<{
    program_slug: string;
  }>();

  useResetBrandingImageType();
  const setDiscoverProgramAtom = useSetAtom(discoverProgramSlugAtom);
  const programInfo = useAtomValue(discoverProgramAtom);

  useEffect(
    () => setDiscoverProgramAtom(program_slug ?? null),
    [program_slug, setDiscoverProgramAtom],
  );

  if (programInfo.state === "loading") return <CircularProgress />;
  if (programInfo.state === "hasError") return;

  const program = programInfo.data;

  if (program === "NOT_FOUND") {
    redirect("/404");
    return null;
  }

  return (
    <Page title={`${t("Discover")}: ${program.name} | ChangeEngine`}>
      <PageHeader>
        <Breadcrumbs
          crumbs={[{ to: "/discover", title: t("Discover") }, { title: program.name }]}
        />
        <PageHeading heading={program.name} data-testid={`program-title-${toSlug(program.name)}`} />
      </PageHeader>
      <BrandingBar visualStyleSelector />
      <PageContent>
        {!loading && !loadingBrandTone && (
          <Grid
            container
            rowSpacing={5}
            columnSpacing={3}
            columns={{ xs: 2, sm: 3, md: 3, lg: 4, xl: 5 }}
          >
            {program.templates?.map((template) => (
              <Grid key={template.slug} size={{ xs: 1 }} minWidth={250}>
                <DiscoverCard
                  template={{
                    ...template,
                    program: omit(program, ["templates"]),
                  }}
                  onClick={() => {
                    void navigate({
                      pathname: `/discover/${program.slug}/${template.slug}`,
                      ...(brandTone
                        ? {
                            search: `?${new URLSearchParams({
                              brand_tone: brandTone?.id,
                            }).toString()}`,
                          }
                        : {}),
                    })
                  }
                  }
                  subheadingGetter={get(programSubheadingOverrides, program.slug)}
                  brandKitSelections={branding}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </PageContent>
    </Page>
  );
};

export default DiscoverProgramPage;
