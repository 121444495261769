import Close from "@mui/icons-material/Close";
import ImageOutlined from "@mui/icons-material/ImageOutlined";
import PictureAsPdfOutlined from "@mui/icons-material/PictureAsPdfOutlined";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { FC, useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useToken } from "../../server/auth/use-token";
import { downloadFile } from "../../utils/download-file";
import { useDialog } from "../../utils/hooks/use-dialog";
import { PopoverController } from "../../utils/hooks/use-popover";
import { Progress, Success } from "../design-huddle/design-huddle-export-modal";
import { useGetDesignHuddleAccessToken } from "../design-huddle/use-design-huddle-access-token";
import { getDesignHuddleProjectRenderFromPageId } from "../design-huddle/utils";
import { useAccountId } from "../generic/hooks/use-account-id";
import { Asset } from "./server/digital-asset-service";

export const ExportMenu: FC<{
  menuController: PopoverController<HTMLElement>;
  asset: Asset;
}> = ({ menuController, asset }) => {
  const dialogController = useDialog();
  const getToken = useToken();
  const getAccessToken = useGetDesignHuddleAccessToken();

  const accountId = useAccountId();

  const [renderState, setRenderState] = useState<
    | {
        state: "loading";
      }
    | {
        state: "hasError";
        error: string;
      }
    | {
        state: "hasData";
        url: string;
        filename: string;
      }
    | {
        state: "uninitialized";
      }
  >({ state: "uninitialized" });

  const [cancelled, setCancelled] = useState(false);

  const handleExport = useCallback(
    (format: string) => {
      void (async () => {
        if (!accountId || !asset.project_id) return;
        if (renderState.state === "loading") return;
        setRenderState({ state: "loading" });
        dialogController.handleOpen();

        const filename = `${asset.title}.${format}`;

        try {
          const downloadUrl = await getDesignHuddleProjectRenderFromPageId({
            getToken,
            accessToken: await getAccessToken([asset.project_id]),
            projectId: asset.project_id,
            format,
            filename,
            ...(asset.page_id ? { pageId: asset.page_id } : {}),
          });
          setRenderState({ state: "hasData", url: downloadUrl, filename });
        } catch (e) {
          if (e instanceof TypeError) {
            setRenderState({ state: "hasError", error: e.message });
          }
        } finally {
          menuController.handleClose();
        }
      })();
    },
    [
      accountId,
      renderState.state,
      dialogController,
      asset.title,
      asset.project_id,
      asset.page_id,
      getToken,
      getAccessToken,
      menuController,
    ],
  );

  useEffect(() => {
    if (cancelled) {
      setRenderState({ state: "uninitialized" });
      setCancelled(false);
    }
    if (renderState.state === "hasData") {
      void downloadFile({ url: renderState.url, filename: renderState.filename });
    }
  }, [cancelled, renderState]);

  const handleCancel = useCallback(() => {
    setCancelled(true);
    dialogController.handleClose();
    menuController.handleClose();
  }, [dialogController, menuController]);

  if (renderState.state === "hasError") {
    toast.error(renderState.error);
  }

  return (
    <>
      <Menu
        open={menuController.open}
        onClose={menuController.handleClose}
        anchorEl={menuController.anchorRef.current}
      >
        <MenuItem
          onClick={() => handleExport("pdf")}
          data-analytics-id="asset-export-pdf"
          sx={{ display: "flex", gap: 1, minWidth: "8em" }}
        >
          <PictureAsPdfOutlined />
          PDF
        </MenuItem>
        <MenuItem
          onClick={() => handleExport("png")}
          data-analytics-id="asset-export-png"
          sx={{ display: "flex", gap: 1 }}
        >
          <ImageOutlined />
          PNG
        </MenuItem>
      </Menu>
      <Dialog
        open={dialogController.open}
        onClose={renderState.state === "loading" ? undefined : dialogController.handleClose}
        fullWidth
      >
        <DialogTitle display="flex" component={"div"}>
          <Typography flexGrow={1} variant="h5">
            {renderState.state === "hasData"
              ? t("Export Successful")
              : renderState.state === "loading"
                ? t("Rendering Design for Export")
                : t("Export Error")}
          </Typography>
          {renderState.state !== "loading" && (
            <IconButton
              data-analytics-id="design-huddle-export-dialog-close"
              onClick={dialogController.handleClose}
            >
              <Close data-testid="CloseIcon"/>
            </IconButton>
          )}
        </DialogTitle>

        {renderState.state === "hasData" ? (
          <Success
            filename={renderState.filename}
            url={renderState.url}
            onClose={dialogController.handleClose}
          />
        ) : renderState.state === "loading" ? (
          <Progress onClose={handleCancel} />
        ) : (
          <DialogContent>
            <Typography variant="body1">
              {t(
                "An error occurred while exporting the design. Please refresh this page and try again.",
              )}
            </Typography>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};
