import { Stack } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { useFlags } from "../../../../server/optimizely";
import { useDialog } from "../../../../utils/hooks/use-dialog";
import { UUID } from "../../../../utils/uuid";
import { CrudDataGrid } from "../../../generic/components/crud-data-grid/crud-data-grid";
import {
  RelatedMoments,
  RelatedMomentsDialog,
} from "../../../generic/components/crud-data-grid/related-moments-dialog";
import { Row } from "../../../generic/components/crud-data-grid/types";
import { SegmentWithMoments } from "../../server/supabase-segment-service";
import { useProcessedSegments } from "./hooks/use-processed-segments";
import { useSegmentCrudColumns } from "./hooks/use-segment-crud-columns";
import { useSegmentCrudDatagridDialog } from "./hooks/use-segment-crud-datagrid-dialog";
import { SegmentCrudDataGridDialog } from "./segment-crud-data-grid-dialog";
import { SegmentCrudDataGridHeader } from "./segment-crud-data-grid-header";
import { SegmentRow } from "./types";
import { useSegmentCrudDataGridActions } from "./use-segment-crud-data-grid-actions";

export const SegmentCrudDataGrid: FC<{
  segments: SegmentWithMoments[];
  is_recipient_type: boolean;
  gridConfig?: {
    disableVirtualization: boolean;
  };
  reload: () => void;
}> = ({ segments, is_recipient_type, gridConfig, reload }) => {
  const {
    flags: { editable_segments },
  } = useFlags("editable_segments");

  const [showArchived, setShowArchived] = useState(false);
  const processedSegments = useProcessedSegments(segments, showArchived);
  // TODO: remove this dialog when we have the related moment tab in the edit segment modal
  const relatedMomentsDialog = useDialog<RelatedMoments>();

  const dialog = useSegmentCrudDatagridDialog();

  const makeActions = useSegmentCrudDataGridActions(dialog.handleOpen);

  const onRelatedMomentsButtonClick = useCallback(
    (data: { id: UUID; title: string; moments: SegmentRow["moments"] }) => {
      const { id, ...rest } = data;

      if (editable_segments)
        return dialog.handleOpen({
          type: "edit",
          data: { segment_id: id, defaultTab: "RELATED_MOMENTS" },
        });
      return relatedMomentsDialog.handleOpen(rest);
    },
    [dialog, relatedMomentsDialog, editable_segments],
  );

  const onSegmentNameClick = useCallback(
    (id: UUID) => {
      if (editable_segments)
        return dialog.handleOpen({
          type: "edit",
          data: { segment_id: id, defaultTab: "DETAILS" },
        });
      return dialog.handleOpen({ type: "edit", data: { segment_id: id } });
    },
    [dialog, editable_segments],
  );

  const columns = useSegmentCrudColumns({
    is_recipient_type,
    onRelatedMomentsButtonClick,
    onSegmentNameClick,
  });

  return (
    <Stack direction="column" spacing={1}>
      <SegmentCrudDataGridHeader
        openAddDialog={() => dialog.handleOpen({ type: "add", data: undefined })}
        showArchived={showArchived}
        setShowArchived={setShowArchived}
        is_recipient_type={is_recipient_type}
      />
      <CrudDataGrid<Row<SegmentRow>>
        onDelete={async (row) => {
          return new Promise<boolean>((resolve) => {
            // Save the arguments to resolve or reject the promise later
            dialog.handleOpen({
              type: "delete",
              data: { segment_id: row.id, resolve },
            });
          });
        }}
        initialRows={processedSegments}
        columns={columns}
        gridOverrides={
          gridConfig ? { disableVirtualization: gridConfig.disableVirtualization } : {}
        }
        initialState={{
          sorting: { sortModel: [{ field: "name", sort: "asc" }] },
        }}
        modifyActions={makeActions}
      />
      <RelatedMomentsDialog subjectNamedInTitle="Segment" dialog={relatedMomentsDialog} />
      <SegmentCrudDataGridDialog
        dialog={dialog}
        is_recipient_type={is_recipient_type}
        reload={reload}
        processedSegments={processedSegments}
      />
    </Stack>
  );
};
