import { omit } from "lodash-es";
import { Workers, serviceURL } from "../../utils/service-url";
import { ApiResponse, ApiService } from "./client";

export abstract class WorkerApiService extends ApiService {
  abstract module: Workers;

  async request<T>(method: string, path: string, body?: unknown): Promise<ApiResponse<T>> {
    const response = await fetch(`${serviceURL(this.module)}${path}`, {
      method,
      body: body ? JSON.stringify(body) : null,
      headers: this.headers(),
    });
    const data = [204, 401, 403].includes(response.status)
      ? undefined
      : ((await response.json()) as T & { error: string });

    if (!response.ok) {
      return {
        error: data?.error || "Unknown error",
        data: undefined,
        status: response.status,
      };
    }

    return {
      error: undefined,
      data: omit(data, "error") as T,
      status: response.status,
    };
  }
}
