import { Box, Stack } from "@mui/material";
import { DataGridPro, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ISO8601 } from "../../../../../utils/iso8601";
import { AnalyticsDatetime } from "../../generic/analytics-datetime";
import { InteractionsByUrl } from "./types";

export const NestedLinkTable: FC<{ row: InteractionsByUrl }> = ({ row }) => {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    return [
      {
        field: "first_name",
        headerName: t("First Name"),
        flex: 1,
      },
      {
        field: "last_name",
        headerName: t("Last Name"),
        flex: 1,
      },
      {
        field: "clicks",
        headerName: t("Clicks"),
        flex: 0.5,
      },
      {
        field: "first_interaction",
        headerName: t("First Interaction"),
        flex: 2,
        renderCell: (params: GridRenderCellParams<{ first_interaction: ISO8601 }, ISO8601>) =>
          params.value ? (
            <Stack direction="row" spacing={1} alignItems="center" height="100%">
              <AnalyticsDatetime datetime={params.value} />
            </Stack>
          ) : null,
      },
      {
        field: "last_interaction",
        headerName: t("Last Interaction"),
        flex: 2,
        renderCell: (params: GridRenderCellParams<{ last_interaction: ISO8601 }, ISO8601>) =>
          params.value ? (
            <Stack direction="row" spacing={1} alignItems="center" height="100%">
              <AnalyticsDatetime datetime={params.value} />
            </Stack>
          ) : null,
      },
    ];
  }, [t]);

  return (
    <Box sx={{ ml: "50px" }}>
      <DataGridPro
        hideFooterRowCount
        rows={Object.values(row.people).map(
          ({ first_name, last_name, clicks, first_interaction, last_interaction }, index) => ({
            id: index,
            first_name,
            last_name,
            clicks,
            first_interaction,
            last_interaction,
          }),
        )}
        columns={columns}
        disableRowSelectionOnClick
      />
    </Box>
  );
};
