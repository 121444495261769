import { GridColDef } from "@mui/x-data-grid-pro";
import { FC } from "react";
import { UsedMomentInfo } from "../../../../modules/moment/server/supabase-moment-service";
import { UsedProgramInfo } from "../../../../modules/programs/server/supabase-program-service";
import { UsedSegmentInfo } from "../../../../modules/segment/server/supabase-segment-service";
import { MomentAnalyticsProvider } from "../../analytics/context/moment-stats-context";
import { AnalyticsSendsTable } from "../../analytics/send-table/send-table";
import { useSentMomentDataDataSource } from "../hooks/use-sent-moment-data";
import { useSentMomentTableColumns } from "../hooks/use-sent-moment-table-columns";
import { SentMomentsTableToolbar } from "./sent-moments-table-toolbar";

export const AnalyticsSentMomentsTable: FC<{
  segments: Pick<UsedSegmentInfo, "id" | "name">[];
  programs: Pick<UsedProgramInfo, "id" | "name">[];
  moments: Pick<UsedMomentInfo, "id" | "name">[];
}> = ({ segments, programs, moments }) => {
  const statsDataSource = useSentMomentDataDataSource();

  const columns = useSentMomentTableColumns({ segments, programs, moments });

  return (
    <MomentAnalyticsProvider statsDataSource={statsDataSource}>
      <AnalyticsSendsTable
        columns={columns}
        slots={{ toolbar: SentMomentsTableToolbar }}
        slotProps={{
          columnsManagement: {
            getTogglableColumns: (cols: GridColDef[]) =>
              cols
                .filter((column) => column.field !== "__detail_panel_toggle__")
                .map((column) => column.field),
          },
        }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 50, page: 0 },
            rowCount: 0,
          },
          sorting: { sortModel: [{ field: "min_timestamp", sort: "desc" }] },
          columns: {
            columnVisibilityModel: {
              segment_name: false,
              program_name: false,
              publisher_name: false,
            },
          },
        }}
      />
    </MomentAnalyticsProvider>
  );
};
