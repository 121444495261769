/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SupabaseService } from "../../../../server/supabase/base-supabase-service";
import { Database } from "../../../../server/supabase/types/database-definitions";
import { UUID } from "../../../../utils/uuid";
import { CustomEmail } from "../types";

export type SupabaseCustomEmail = Database["public"]["Tables"]["custom_email_address"]["Row"];
export type SupabaseCustomEmailDefault =
  Database["public"]["Tables"]["custom_email_address_default"]["Row"];

export class SupabaseCustomEmailService extends SupabaseService<"custom_email_address"> {
  table = "custom_email_address" as const;

  async getAllVerified() {
    return this.log_errors(await this.client.from(this.table).select("*").is("verified", true));
  }

  async upsertSimple(customEmail: CustomEmail) {
    return this.log_errors(
      await this.client.rpc("upsert_custom_email_address", {
        address_: customEmail.address,
        from_name_: customEmail.from_name!,
        reply_to_: customEmail.reply_to ?? undefined,
      }),
    );
  }

  async getDefault() {
    return this.log_errors(await this.client.from("default_email").select().maybeSingle());
  }

  async setDefault(account_id: UUID, custom_email_address_id: UUID) {
    return this.log_errors(
      await this.client
        .from("custom_email_address_default")
        .upsert({ account_id, custom_email_address_id }),
    );
  }

  async removeDefault(account_id: UUID) {
    return this.log_errors(
      await this.client.from("custom_email_address_default").delete().eq("account_id", account_id),
    );
  }
}
