import Close from "@mui/icons-material/Close";
import ContentCopy from "@mui/icons-material/ContentCopy";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FileDownload from "@mui/icons-material/FileDownload";
import {
  Alert,
  Box,
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useDialog } from "../../utils/hooks/use-dialog";
import { usePopover } from "../../utils/hooks/use-popover";
import { useDesignHuddleProjectClone } from "../design-huddle/use-design-huddle-project-clone";
import { ExportMenu } from "./asset-export-menu";
import { DeleteConfirmation } from "./asset-preview-modal";
import { useDeleteAssetCallback } from "./hooks/use-delete-asset-callback";
import { useCreateAssetCallback } from "./hooks/use-upsert-asset";
import { LinkToAssetButton } from "./link-to-asset-button";
import { Asset } from "./server/digital-asset-service";

export const DesignHuddleAssetPreviewModal: FC<{
  open: boolean;
  onClose: () => void;
  asset: Asset;
  onChange?: () => void;
  onDeleted?: () => void;
}> = ({ asset, open, onClose, onChange, onDeleted }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [copying, setCopying] = useState(false);
  const createAsset = useCreateAssetCallback();

  const cloneProject = useDesignHuddleProjectClone();

  const handleCopy = useCallback(async () => {
    if (copying) return;

    setCopying(true);

    const loadingToast = toast.custom(
      <Alert severity="info" variant="outlined" sx={{ bgcolor: "white" }}>
        {t("Creating copy...")}
      </Alert>,
    );

    if (!asset.project_id) return;
    try {
      const projectId = await cloneProject(asset.project_id, asset.page_id);
      await createAsset({
        projectId: projectId,
        contentType: asset.content_type,
        thumbnailUrl: asset.thumbnail_url,
        pageId: asset.page_id,
        title: `Copy of ${asset.title}`,
      });

      toast.dismiss(loadingToast);
      toast.custom(<Alert severity="success">{t(`Copy of "${asset.title}" created`)}</Alert>, {
        duration: 2000,
      });
      onChange?.();
    } catch (e) {
      console.error(e);
      toast.dismiss(loadingToast);
      toast.custom(
        <Alert severity="error">{t("Failed to create copy. Please try again later")}</Alert>,
        { duration: 3000 },
      );
    } finally {
      setCopying(false);
    }
  }, [asset, cloneProject, copying, createAsset, onChange, t]);

  const deleteConfirmation = useDialog();
  const deleteAsset = useDeleteAssetCallback();

  const handleConfirmDelete = useCallback(async () => {
    deleteConfirmation.handleClose();

    try {
      await deleteAsset(asset.id);
      toast.custom(<Alert severity="success">{t(`"${asset.title}" deleted`)}</Alert>, {
        duration: 2000,
      });
      onDeleted?.();
    } catch (e) {
      console.error(e);
      toast.custom(
        <Alert severity="error">{t("Failed to delete asset. Please try again later")}</Alert>,
        {
          duration: 3000,
        },
      );
    }
  }, [asset, deleteAsset, deleteConfirmation, onDeleted, t]);

  const exportMenuController = usePopover<HTMLButtonElement>();

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle display="flex" justifyContent="space-between" component="div">
          <Stack spacing={0} width="100%">
            <Box sx={{ p: 0, m: 0 }} display="flex" alignItems="center" width="100%">
              <Typography variant="h6" flexGrow={1}>
                {asset?.title}
              </Typography>
              <IconButton
                onClick={onClose}
                data-analytics-id="content-library-preview-close"
                title={t("Close")}
                size="small"
              >
                <Close data-testid="CloseIcon" />
              </IconButton>
            </Box>
            <LinkToAssetButton asset={asset} />
          </Stack>
        </DialogTitle>
        <DialogContent>
          {asset && asset.thumbnail_url && <CardMedia src={asset?.thumbnail_url} component="img" />}
        </DialogContent>
        <DialogActions sx={{ justifyItems: "right", px: 2, mb: 2 }}>
          <Button
            ref={exportMenuController.anchorRef}
            onClick={exportMenuController.handleOpen}
            startIcon={<FileDownload />}
            data-analytics-id="content-library-preview-export"
          >
            Export
          </Button>
          <Button
            startIcon={<ContentCopy data-testid="ContentCopyIcon" />}
            data-analytics-id="content-library-preview-duplicate"
            onClick={() => void handleCopy()}
            loading={copying}
          >
            {t("Duplicate")}
          </Button>
          <Button
            startIcon={<Edit />}
            data-analytics-id="content-library-preview-edit"
            onClick={() => void navigate(`/content-library/${asset.id}`)}
          >
            {t("Edit")}
          </Button>
          <Button
            startIcon={<DeleteOutline data-testid="DeleteOutlineIcon" />}
            data-analytics-id="content-library-preview-delete"
            onClick={deleteConfirmation.handleOpen}
            color="error"
          >
            {t("Delete")}
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteConfirmation
        asset={asset}
        open={deleteConfirmation.open}
        onClose={deleteConfirmation.handleClose}
        onConfirm={() => void handleConfirmDelete()}
      />
      <ExportMenu menuController={exportMenuController} asset={asset} />
    </>
  );
};
