import { Auth0Provider } from "@auth0/auth0-react";
import { LinearProgress } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LicenseInfo } from "@mui/x-license";
import { OptimizelyProvider } from "@optimizely/react-sdk";
import { ErrorBoundary } from "@sentry/react";
import "draft-js/dist/Draft.css";
import { FC, Suspense } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router";
import { config } from "./config";
import "./i18n";
import { AnalyticsMetadata } from "./modules/analytics/analytics";
import { DesignHuddleLogin } from "./modules/design-huddle/design-huddle-login";
import { AppError } from "./modules/generic/components/errors/app-error";
import { Token } from "./server/auth/token";
import { optimizely, optimizelyUser } from "./server/optimizely";
import { MomentCardDragAndDropProvider } from "./utils/contexts/moment-card-drag-and-drop-context";
import { SettingsProvider } from "./utils/contexts/settings-context";
import { getOrganization } from "./utils/organization";

export const App: FC = () => {
  const [searchparams] = useSearchParams();
  const navigate = useNavigate();
  LicenseInfo.setLicenseKey(config.mui_x_pro.licence);

  return (
    <ErrorBoundary fallback={({ resetError }) => <AppError onRetry={resetError} />}>
      <Auth0Provider
        domain={config.auth0.issuer_base_domain}
        clientId={config.auth0.client_id}
        authorizationParams={{
          redirect_uri: location.origin,
          audience: config.auth0.audience,
          scope: "openid profile email supabase_token",
          invitation: searchparams.get("invitation") || undefined,
          organization: getOrganization(searchparams),
        }}
        useRefreshTokens={true}
        cacheLocation="localstorage"
        skipRedirectCallback={window.location.pathname === "/channels/slack"}
        onRedirectCallback = {(appState) => {
            void navigate(appState?.returnTo || window.location.pathname)
        }}
      >
        <AnalyticsMetadata />
        <DesignHuddleLogin />
        <OptimizelyProvider optimizely={optimizely} user={optimizelyUser}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MomentCardDragAndDropProvider>
              <SettingsProvider>
                {window.location.pathname !== "/" && (
                  <ErrorBoundary>
                    <Token />
                  </ErrorBoundary>
                )}
                <Suspense fallback={<LinearProgress />}>
                  <Outlet />
                </Suspense>
              </SettingsProvider>
            </MomentCardDragAndDropProvider>
          </LocalizationProvider>
        </OptimizelyProvider>
      </Auth0Provider>
    </ErrorBoundary>
  );
};
