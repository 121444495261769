import { IconButton } from "@mui/material";
import {
  gridDetailPanelExpandedRowsContentCacheSelector,
  GridExpandMoreIcon,
  GridRenderCellParams,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import { isValidElement } from "react";

import { TransformedGroupedMomentAnalytics } from "../hooks/utils";
import { TableStack } from "./table-stack";

export const CustomDetailPanelToggle = (
  props: Pick<
    GridRenderCellParams<TransformedGroupedMomentAnalytics, { isExpanded: boolean }>,
    "id" | "value"
  >,
): React.ReactElement => {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = isValidElement(contentCache[id]);

  return (
    <TableStack>
      <IconButton
        size="small"
        tabIndex={-1}
        disabled={!hasDetail}
        aria-label={isExpanded ? "Close" : "Open"}
        data-analytics-id="moment-analytics-detail-panel-toggle"
      >
        <GridExpandMoreIcon
          sx={(theme) => ({
            transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
            transition: theme.transitions.create("transform", {
              duration: theme.transitions.duration.shortest,
            }),
          })}
          fontSize="inherit"
        />
      </IconButton>
    </TableStack>
  );
};
