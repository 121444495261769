import { ApiResponse, ApiService } from "../../../../server/api/client";
import { UUID } from "../../../../utils/uuid";

export class SlackService extends ApiService {
  module = "integration/slack";

  disconnect = async (slackConfigId: UUID): Promise<ApiResponse<void>> => {
    return await this.post("disconnect", { slackConfigId });
  };

  setSlackInfo = async (
    code: string
  ): Promise<ApiResponse<void>> => {
    return await this.post("set_slack_info", { code });
  };

  build_app = async (
    state: UUID,
  ): Promise<ApiResponse<{ clientId: string }>> => {
    return await this.post("build_app", { state });
  };

  async get_upload_image_info(): Promise<ApiResponse<{ url: string; name: string }>> {
    return await this.get("get_slack_image_upload_url");
  }
}
