import { Autocomplete, Box, TextField } from "@mui/material";
import {
  GridFilterInputValueProps,
  GridFilterItem,
  GridFilterOperator,
} from "@mui/x-data-grid-pro";
import React, { FC, useCallback, useState } from "react";

// Define props interface for the Autocomplete component
interface AutocompleteFilterInputProps extends GridFilterInputValueProps {
  options: { label: string; value: string }[];
}

const AutocompleteFilterInput: FC<AutocompleteFilterInputProps> = (props) => {
  const { item, applyValue, options } = props;
  const [value, setValue] = useState<{ label: string; value: string } | null>(
    () => options.find((o) => o.value === item.value) || null,
  );

  const handleFilterChange = useCallback(
    (_event: React.SyntheticEvent, newValue: string | null) => {
      setValue(options.find((o) => o.value === newValue) || null);
      applyValue({ ...item, value: newValue });
    },
    [options, applyValue, item],
  );

  return (
    <Box
      sx={{
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "center",
        height: 48,
        pl: "20px",
      }}
    >
      <Autocomplete
        value={value}
        renderInput={(params) => (
          <TextField {...params} variant="standard" placeholder="Select value" size="small" />
        )}
        onChange={(event, newValue) => handleFilterChange(event, newValue?.value ?? null)}
        options={options}
        getOptionLabel={(option) => option.label}
        style={{ width: 150 }}
        size="small"
      />
    </Box>
  );
};

export const autocompleteOperator = (
  options: { label: string; value: string }[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): GridFilterOperator<any, string> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const operator: GridFilterOperator<any, string> = {
    label: "Equals",
    value: "equals",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || filterItem.value === undefined || !filterItem.operator) {
        return null;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (value: any): boolean => {
        if (value === undefined || value === null || filterItem.value === null) {
          return false;
        }

        return String(value) === String(filterItem.value);
      };
    },
    InputComponent: AutocompleteFilterInput,
    InputComponentProps: { options },
  };

  return operator;
};
