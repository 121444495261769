import { Box } from "@mui/material";
import { FC, ReactElement, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Page } from "../../../modules/layout/components/page";
import { PageContent } from "../../../modules/layout/components/page-content";
import { PageHeader } from "../../../modules/layout/components/page-header";
import { PageHeading } from "../../../modules/layout/components/page-heading";
import { MomentCardDropArea } from "../../../modules/moment-card/components/drag-and-drop/moment-card-drop-area";
import { MomentDashboardSection } from "../../../modules/moment-dashboard-section/moment-dashboard-section";
import {
  MomentCardMomentActivity,
  SupabaseMomentsRecentlyEditedService,
  SupabaseMomentsSentService,
  SupabaseMomentsUpcomingService,
} from "../../../modules/moment/server/supabase-moment-service";
import { SearchField } from "../../../modules/search/search-field";
import { useSupabase } from "../../../server/supabase/hooks";
import { makeDefaultFilters } from "../../../utils/default-filters";

const MomentsDashboardPage = (): ReactElement => {
  const [recentlyEdited, setRecentlyEdited] = useState<MomentCardMomentActivity[]>();
  const [sent, setSent] = useState<MomentCardMomentActivity[]>();
  const [upcoming, setUpcoming] = useState<MomentCardMomentActivity[]>();

  const limit = 24;

  useSupabase(({ supabase, account_id }) => {
    return new SupabaseMomentsRecentlyEditedService(supabase)
      .getAll(account_id, { limit, order: [{ column: "attime", dir: "desc" }] })
      .then(({ data, error }) => {
        if (!error) {
          setRecentlyEdited(data);
        }
      });
  }, []);

  useSupabase(({ supabase, account_id }) => {
    return new SupabaseMomentsSentService(supabase)
      .getAll(account_id, { limit, order: [{ column: "attime", dir: "desc" }] })
      .then(({ data, error }) => {
        if (!error) {
          setSent(data);
        }
      });
  }, []);

  useSupabase(({ supabase, account_id }) => {
    return new SupabaseMomentsUpcomingService(supabase)
      .getAll(account_id, { limit, order: [{ column: "attime", dir: "asc" }] })
      .then(({ data, error }) => {
        if (!error) {
          setUpcoming(data);
        }
      });
  }, []);

  return <MomentsDashboardNewUI recentlyEdited={recentlyEdited} sent={sent} upcoming={upcoming} />;
};

const MomentsDashboardNewUI: FC<{
  recentlyEdited?: MomentCardMomentActivity[];
  sent?: MomentCardMomentActivity[];
  upcoming?: MomentCardMomentActivity[];
}> = ({ recentlyEdited, sent, upcoming }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [value, setValue] = useState("");

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
  }, []);

  const handleSubmit = useCallback((): void => {
    const searchParams = makeDefaultFilters();
    searchParams.set("q", value);

    void navigate({
      pathname: "/tabbed-programs",
      search: searchParams.toString(),
    });
  }, [navigate, value]);

  return (
    <Page title={t("Your Moments | ChangeEngine")}>
      <PageHeader>
        <PageHeading heading={t("Your Moments")} />
      </PageHeader>
      <PageContent>
        <MomentCardDropArea>
          <Box sx={{ minWidth: 200, maxWidth: 510, mt: 4 }}>
            <SearchField
              onSubmit={handleSubmit}
              onChange={handleChange}
              defaultValue={value}
              label={t("Search Moments & Programs")}
            />
          </Box>
          <MomentDashboardSection moments={recentlyEdited} title={t("Recently Edited")} mt={5} />
          <MomentDashboardSection moments={sent} title={t("Sent")} mt={5} />
          <MomentDashboardSection
            moments={upcoming}
            title={t("Upcoming")}
            help={t("Not including trigger-based Moments")}
            mt={5}
          />
        </MomentCardDropArea>
      </PageContent>
    </Page>
  );
};
export default MomentsDashboardPage;