/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SupabaseService } from "../../../server/supabase/base-supabase-service";
import { Database } from "../../../server/supabase/types/database-definitions";
import { UUID } from "../../../utils/uuid";

export type DigitalAssetLogRecord = Database["public"]["Tables"]["digital_asset_log"]["Row"];

export class SupabaseDigitalAssetLogService extends SupabaseService<"digital_asset_log"> {
  table = "digital_asset_log" as const;

  async getAssetCreatorByDigitalAssetID(id: UUID) {
    return this.log_errors(
      await this.client
        .from(this.table)
        .select("user_name, created_at, action")
        .eq("digital_asset_id", id)
        .order("created_at", { ascending: false })
        .limit(1)
        .single(),
    );
  }
}
