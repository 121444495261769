import { init } from "@sentry/react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router";
import { config } from "./config";
import { Routes } from "./routes";

init(config.sentry);

const router = createBrowserRouter(createRoutesFromElements(Routes));

const root = createRoot(document.getElementById("root")!);
root.render(<RouterProvider router={router} />);
