import { useSupabaseLoader } from "../../../server/supabase/hooks";
import { UseSupabaseLoaderState } from "../../../server/supabase/hooks/types";
import { notEmpty } from "../../../utils/not-empty";
import { SupabasePersonService } from "../../employees/server/supabase-person-service";
import { UsableAccountPersonField } from "../server/supabase-segment-field-service";

export function useSegmentFieldValueOptions({
  audience,
  disabled,
  segmentField,
}: {
  segmentField?: UsableAccountPersonField;
  disabled?: boolean;
  audience?: string;
}): UseSupabaseLoaderState<string[]> {
  // No key equals an empty string so we can just not make the request
  const segmentFieldKey = segmentField?.key || null;

  return useSupabaseLoader<string[]>(
    async ({ supabase, account_id }) => {
      if (disabled || !segmentFieldKey) return [];

      const service = new SupabasePersonService(supabase);

      const { data: uniqueFieldsData } = await (audience
        ? service.getUniqueValuesByAudience(account_id, segmentFieldKey, audience)
        : service.getUniqueValues(account_id, segmentFieldKey));

      return (uniqueFieldsData || [])
        .map((field) => field.value)
        .filter(notEmpty)
        .sort();
    },
    [disabled, audience, segmentFieldKey],
  );
}
