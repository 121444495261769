import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { DesignHuddleAssetPreviewModal } from "./design-huddle-asset-preview-modal";
import { Asset } from "./server/digital-asset-service";
import { UploadedFileAssetPreviewModal } from "./uploaded-file-asset-preview-modal";

export const AssetPreviewModal: FC<{
  open: boolean;
  onClose: () => void;
  asset: Asset | null;
  onChange: () => void;
  onDeleted?: () => void;
  onViewFileClick: (asset: Asset) => void;
}> = ({ asset, open, onClose, onChange, onDeleted, onViewFileClick }) => {
  if (!asset) return null;

  return asset.content_type === "Uploaded File" ? (
    <UploadedFileAssetPreviewModal
      asset={asset}
      open={open}
      onClose={onClose}
      onChange={onChange}
      onDeleted={onDeleted}
      onViewFileClick={onViewFileClick}
    />
  ) : (
    <DesignHuddleAssetPreviewModal
      asset={asset}
      open={open}
      onClose={onClose}
      onChange={onChange}
      onDeleted={onDeleted}
    />
  );
};

export const DeleteConfirmation: FC<{
  asset: Asset;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}> = ({ asset, open, onClose, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t(`Delete "${asset.title}"?`)}</DialogTitle>
      <DialogContent>
        {t(
          "This will delete the file from your Content Library and you won’t be able to view or edit it again.",
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} data-analytics-id="asset-delete-cancel" variant="text">
          {t("Cancel")}
        </Button>
        <Button
          onClick={onConfirm}
          color="error"
          data-analytics-id="asset-delete-confirm"
          variant="contained"
        >
          {t("Delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
