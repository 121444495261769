import { useAuth0 } from "@auth0/auth0-react";
import { UUID } from "../../utils/uuid";
import { OrgUser } from "../supabase/hooks/types";

export type Permissions = {
  editable_program: readonly UUID[];
  read_only_program: readonly UUID[];
  ce_admin: boolean;
  super_admin: boolean;
};

const defaultPermissions: Permissions = {
  editable_program: [],
  read_only_program: [],
  ce_admin: false,
  super_admin: false,
};

export function usePermissions(): Permissions & { isLoading: boolean } {
  const { user, isLoading } = useAuth0<OrgUser>();

  if (!user) return { ...defaultPermissions, isLoading };

  return {
    editable_program: user["http://changeengine.com/editable_program"] ?? [],
    read_only_program: user["http://changeengine.com/read_only_program"] ?? [],
    ce_admin: user["http://changeengine.com/ce_admin"],
    super_admin: user["http://changeengine.com/super_admin"],
    isLoading,
  };
}
