import { RawDraftContentState } from "draft-js";
import { draftToMarkdown, DraftToMarkdownOptions } from "markdown-draft-js";

export const toMarkdown = (content: RawDraftContentState): string =>
  draftToMarkdown(content, {
    preserveNewlines: false,
    escapeMarkdownCharacters: false,
    styleItems: supportedMrkdwnStyles,
  });

const unsupported = {
  open: function () {
    return "";
  },

  close: function () {
    return "";
  },
};

const supportedMrkdwnStyles: DraftToMarkdownOptions["styleItems"] = {
  // Block level styles
  "unordered-list-item": {
    open: function () {
      return "* ";
    },

    close: function () {
      return "";
    },
  },

  blockquote: unsupported,
  "header-one": unsupported,
  "header-two": unsupported,
  "header-three": unsupported,
  "header-four": unsupported,
  "header-five": unsupported,
  "header-six": unsupported,
  "code-block": unsupported,
  CODE: unsupported,
};
