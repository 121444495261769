import { Box, Stack } from "@mui/material";
import { DataGridPro, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AnalyticsDatetime } from "../../generic/analytics-datetime";
import { ReactionsByEmoji } from "./types";

type RowType = ReactionsByEmoji["people"][0] & { id: number };

export const NestedReactionTable: FC<{ row: ReactionsByEmoji }> = ({ row }) => {
  const { t } = useTranslation();

  const columns: GridColDef<RowType>[] = useMemo(
    () => [
      {
        field: "first_name",
        headerName: t("First Name"),
        flex: 1,
      },
      {
        field: "last_name",
        headerName: t("Last Name"),
        flex: 1,
      },
      {
        field: "timestamp",
        headerName: t("Reaction Time"),
        flex: 4,
        type: "dateTime",
        renderCell: (params: GridRenderCellParams<RowType, Date>) =>
          params.value ? (
            <Stack direction="row" spacing={1} alignItems="center" height="100%">
              <AnalyticsDatetime datetime={params.value} />
            </Stack>
          ) : null,
      },
    ],
    [t],
  );

  const people = useMemo(
    () => row.people.map((person, index) => ({ id: index, ...person })),
    [row],
  );

  return (
    <Box sx={{ ml: "50px" }}>
      <DataGridPro hideFooterRowCount rows={people} columns={columns} disableRowSelectionOnClick />
    </Box>
  );
};
