import { Stack, Tab, Tabs } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Segment } from "../../../../types";
import { SegmentModalForm } from "../segment-modal-form";
import { EditSegmentModalProps } from "../types";
import { SegmentRecipientsDataGrid } from "./segment-recipients-data-grid";
import { SegmentRelatedMomentsDataGrid } from "./segment-related-moments-data-grid";

export type EditModalTab = "DETAILS" | "RELATED_MOMENTS" | "RECIPIENTS";

export const TabbedEditSegmentModal: FC<
  Pick<EditSegmentModalProps, "disabled"> & {
    segment: Segment;
    tab: EditModalTab;
    setTab: (tab: EditModalTab) => void;
  }
> = ({ segment, disabled, tab, setTab }) => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" spacing={4}>
      <Tabs
        indicatorColor="primary"
        scrollButtons="auto"
        textColor="primary"
        value={tab}
        variant="scrollable"
        sx={{ mt: 3 }}
      >
        <Tab
          key="DETAILS"
          label="Details"
          value="DETAILS"
          onClick={() => setTab("DETAILS")}
          data-analytics-id="segment_details_tab"
        />
        <Tab
          key="RELATED_MOMENTS"
          label={t("Related Moments")}
          value="RELATED_MOMENTS"
          onClick={() => setTab("RELATED_MOMENTS")}
          data-analytics-id="segment_related_moments_tab"
        />
        <Tab
          key="RECIPIENTS"
          label={t("Recipients")}
          value="RECIPIENTS"
          onClick={() => setTab("RECIPIENTS")}
          data-analytics-id="segment_recipients_tab"
        />
      </Tabs>
      {tab === "DETAILS" && (
        <SegmentModalForm
          isRecipientType={segment.is_recipient_type ?? false}
          disabled={disabled}
        />
      )}
      {tab === "RELATED_MOMENTS" && <SegmentRelatedMomentsDataGrid segmentId={segment.id} />}
      {tab === "RECIPIENTS" && <SegmentRecipientsDataGrid />}
    </Stack>
  );
};
