import {
  SupabaseCEAdminServiceView,
  SupabaseServiceView,
} from "../../../server/supabase/base-supabase-service";
import { Database } from "../../../server/supabase/types/database-definitions";

export type MomentAnalyticsTotals = Database["public"]["Views"]["moment_analytics_totals"]["Row"];
export type GroupedMomentAnalyticsData =
  Database["public"]["Views"]["grouped_moment_analytics_data"]["Row"];
export type FloatingOutboxRecipient =
  Database["ce_admin"]["Views"]["floating_outbox_recipient"]["Row"];

export type MomentStat = Database["public"]["Views"]["all_moment_stats"]["Row"];
export type SentMomentData = Database["public"]["Views"]["sent_moment_data"]["Row"];

export class SupabaseMomentAnalyticsTotalsService extends SupabaseServiceView<"moment_analytics_totals"> {
  table = "moment_analytics_totals" as const;
}

export class SupabaseFloatingOutboxRecipientService extends SupabaseCEAdminServiceView<"floating_outbox_recipient"> {
  table = "floating_outbox_recipient" as const;
}

export class SupabaseGroupedMomentAnalyticsDataService extends SupabaseServiceView<"grouped_moment_analytics_data"> {
  table = "grouped_moment_analytics_data" as const;
}

export class SupabaseSentMomentDataService extends SupabaseServiceView<"sent_moment_data"> {
  table = "sent_moment_data" as const;
}

export class SupabaseAllMomentStatsService extends SupabaseServiceView<"all_moment_stats"> {
  table = "all_moment_stats" as const;
}

export type NarrowedGroupedAnalyticsData<
  T extends GroupedMomentAnalyticsData["stats"][number]["stat_type"],
> = Extract<GroupedMomentAnalyticsData["stats"][number], { stat_type: T }>["data"];

export type GroupedMomentAnalyticsDataError = NarrowedGroupedAnalyticsData<"error">;

export const isMomentAnalyticsStatType =
  <U extends GroupedMomentAnalyticsData["stats"][number]["stat_type"]>(type: U) =>
  (
    item: GroupedMomentAnalyticsData["stats"][number],
  ): item is Extract<GroupedMomentAnalyticsData["stats"][number], { stat_type: U }> => {
    return item.stat_type === type;
  };

export function filterStatType<U extends GroupedMomentAnalyticsData["stats"][number]["stat_type"]>(
  type: U,
  stats: GroupedMomentAnalyticsData["stats"],
): Extract<GroupedMomentAnalyticsData["stats"][number], { stat_type: U }>[] {
  return stats.filter(isMomentAnalyticsStatType(type));
}
