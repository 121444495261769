import { Alert, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { config } from "../../../../../config";

export const NoCustomDomainsMessage: FC = () => {
  const { t } = useTranslation();
  return (
    <Alert severity="warning" sx={{ mt: 2 }}>
      <Typography variant="body2">
        {t(
          "You do not have any custom domains configured. By default all emails will be sent from '{{default}}'",
          { default: config.defaultEmailFrom },
        )}
      </Typography>
    </Alert>
  );
};
