import { Box, Chip } from "@mui/material";
import { FC } from "react";

export const ContentTypeChips: FC<{
  contentTypes: string[];
  onDelete: (contentType: string) => void;
}> = ({ contentTypes, onDelete }) => {
  if (contentTypes.length === 0) return null;

  return (
    <Box>
      {contentTypes.map((contentType) => {
        return (
          <Chip
            onDelete={() => onDelete(contentType)}
            sx={{ mr: 1 }}
            label={contentType ?? "..."}
            variant="outlined"
            key={contentType}
          ></Chip>
        );
      })}
    </Box>
  );
};
