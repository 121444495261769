import yup from "../../../utils/yup";
import { emailHeaderSchema } from "../types/email-header-type";
import { emailFooterLayoutSettingsSchema, emailLayoutSettingsSchema } from "../types/email-layout-type";
import { emailTextSettingsSchema } from "../types/email-text-type";
import { emailButtonSettingsSchema } from "./email-button-settings";
import { dividerSettingsSchema } from "./email-divider-settings";

export const DEFAULT_EMAIL_DESIGN_NAME = "New Email Template";

export const emailDesignSchema = yup.object({
  id: yup.string(),
  name: yup.string().default("New Email Template").trim(),
  is_default: yup.boolean().required().default(false),
  header: emailHeaderSchema.nullable().default(null),
  footer: yup.object().nullable().default(null),
  footer_layout_settings: emailFooterLayoutSettingsSchema.nullable(),
  text_settings: emailTextSettingsSchema.nullable(),
  layout_settings: emailLayoutSettingsSchema.nullable(),
  button_settings: emailButtonSettingsSchema,
  divider_settings: dividerSettingsSchema,
});

export type EmailDesignSchema = yup.InferType<typeof emailDesignSchema>;
