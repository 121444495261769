import Warning from "@mui/icons-material/Warning";
import { Stack, Tooltip, Typography } from "@mui/material";
import {
  getGridDateOperators,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ChannelName, isEmailChannel } from "../../../../modules/channels/types";
import { MomentFormMoment } from "../../../../modules/moment/types/moment-form-moment";
import { ISO8601 } from "../../../../utils/iso8601";
import { AnalyticsDatetime } from "../generic/analytics-datetime";
import { CustomDetailPanelToggle } from "../send-table/custom-detial-panel-toggle";
import { TableStack } from "../send-table/table-stack";
import { ValueCellDisplay } from "../send-table/value-cell-display";
import { TransformedGroupedMomentAnalytics } from "./utils";

export function useSendTableColumns<T extends TransformedGroupedMomentAnalytics>(
  moment?: MomentFormMoment,
): GridColDef<T>[] {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        renderCell: (params) => <CustomDetailPanelToggle {...params} />,
        hideable: false,
      },
      {
        field: "min_timestamp",
        type: "dateTime",
        headerName: t("Sent"),
        filterOperators: getGridDateOperators(true).filter(
          (operator) => operator.value === "before" || operator.value === "after",
        ),
        flex: 1.5,
        renderCell: (params: GridRenderCellParams<T, ISO8601>) =>
          params.value ? (
            <TableStack tooltip={t("Date and time the message was sent")}>
              <AnalyticsDatetime datetime={params.value} />
            </TableStack>
          ) : null,
      },
      {
        field: "recipients",
        headerName: t("Recipients"),
        sortable: false,
        filterable: false,
        flex: 1,
        renderCell: (params: GridRenderCellParams<T, T["recipients"]>) => (
          <TableStack>
            <Tooltip
              title={(() => {
                // TODO this is the moment's current segment not the segment which was
                // this actual send.
                const blurb = moment?.segment?.name
                  ? t(
                      `Number of recipients ${moment?.target_connection_type ? "connected to subjects in" : "in"} segment`,
                    ) + ` "${moment?.segment.name}"`
                  : t("Number of recipients");

                switch (params.value?.type) {
                  case "channel_name":
                    return t("Channel the message was sent to");
                  case "person_name":
                    // This is only ever singular at the moment
                    return t("Person the message was sent to");
                  case "number":
                    return blurb;
                  default:
                    return undefined;
                }
              })()}
              arrow
              placement="top"
            >
              <Typography variant="body2">{params.value ? params.value.display : "-"}</Typography>
            </Tooltip>

            {params.value?.connection_type && (
              <Tooltip title={t("Recipients' connection to subject(s)")} placement="left" arrow>
                <Typography variant="body2" color="textSecondary" textTransform="capitalize">
                  {params.value.connection_type}
                </Typography>
              </Tooltip>
            )}
            <Stack direction="row" spacing={1} alignItems="center">
              {!!params.value?.cc?.length && params.value.cc.length > 0 && (
                <Tooltip title={t(params.value.cc.join(", "))} placement="bottom" arrow>
                  <Typography variant="body2" color="textSecondary">
                    {t("+CC")}
                  </Typography>
                </Tooltip>
              )}
              {!!params.value?.bcc?.length && params.value.bcc.length > 0 && (
                <Tooltip title={t(params.value.bcc.join(", "))} placement="bottom" arrow>
                  <Typography variant="body2" color="textSecondary">
                    {t("+BCC")}
                  </Typography>
                </Tooltip>
              )}
            </Stack>
          </TableStack>
        ),
      },
      {
        field: "subject",
        headerName: t("Subject/Trigger"),
        sortable: false,
        filterable: false,
        flex: 1,
        renderCell: (params: GridRenderCellParams<T, T["subject"]>) => (
          <TableStack
            tooltip={(() => {
              if (params.value?.subject && params.value?.when) {
                return t("Scheduling and subject of the message");
              }

              if (params.value?.subject) {
                return t("Subject of the message");
              }
              if (params.value?.when) {
                return t("Scheduling of the message");
              }
              return undefined;
            })()}
          >
            <Typography variant="body2">{params.value ? params.value.when : "-"}</Typography>
            {params.value?.subject && (
              <Typography variant="body2" color="textSecondary">
                {params.value.subject}
              </Typography>
            )}
          </TableStack>
        ),
      },
      {
        field: "delivered",
        headerName: t("Delivered"),
        flex: 0.5,
        sortable: false,
        filterable: false,
        align: "right",
        renderCell: ({ value }: GridRenderCellParams<GridValidRowModel, T["delivered"]>) => (
          <TableStack tooltip={t("Percentage of messages that were delivered successfully")}>
            <Stack direction="row" spacing={1} alignItems="flex-start" height="100%">
              <Typography variant="body2">
                <ValueCellDisplay cellData={value} />
              </Typography>
              {value?.type === "percentage" && value.value < 100 && (
                <Tooltip title={t("Some messages could not be delivered")} arrow placement="bottom">
                  <Warning color="error" fontSize="small" />
                </Tooltip>
              )}
            </Stack>
          </TableStack>
        ),
      },
      {
        field: "opened",
        headerName: t("Opened"),
        flex: 0.5,
        sortable: false,
        filterable: false,
        align: "right",
        renderCell: (params: GridRenderCellParams<GridValidRowModel, T["opened"]>) => (
          <TableStack
            tooltip={
              isEmailChannel(params.row.channel_name as ChannelName)
                ? t("Percentage of messages that were opened")
                : undefined
            }
          >
            <Stack direction="row" spacing={1} alignItems="flex-start" height="100%">
              <Typography variant="body2">
                {isEmailChannel(params.row.channel_name as ChannelName) ? (
                  <ValueCellDisplay cellData={params.value} />
                ) : (
                  "-"
                )}
              </Typography>
            </Stack>
          </TableStack>
        ),
      },
      {
        field: "interacted",
        headerName: t("Interacted"),
        flex: 0.5,
        sortable: false,
        filterable: false,
        align: "right",
        renderCell: (params: GridRenderCellParams<GridValidRowModel, T["interacted"]>) => (
          <TableStack
            tooltip={(() => {
              switch (params.value?.type) {
                case "percentage":
                  return t("Percentage of messages where recipients clicked on a link/button");
                case "number":
                  return t("Number of messages where recipients clicked on a link/button");
                default:
                  return undefined;
              }
            })()}
          >
            <Stack direction="row" spacing={1} alignItems="flex-start" height="100%">
              <Typography variant="body2">
                <ValueCellDisplay cellData={params.value} />
              </Typography>
            </Stack>
          </TableStack>
        ),
      },
    ];
  }, [t, moment]);
}
