import { FC } from "react";
import { useTranslation } from "react-i18next";
import { DialogController } from "../../../../utils/hooks/use-dialog";
import { SegmentCrudDialogState } from "./hooks/use-segment-crud-datagrid-dialog";
import { useSegmentCrudFunctions } from "./hooks/use-segment-crud-functions";
import { ArchiveDialog } from "./segment-modal/archive-dialog";

import { CloneSegmentModal } from "./segment-modal/clone-segment-modal";
import { EditSegmentModal } from "./segment-modal/edit-segment-modal";
import { NewSegmentModal } from "./segment-modal/new-segment-modal";
import { UnarchiveWithNewNameModal } from "./segment-modal/unarchive-segment-modal";
import { SegmentRowWithOptions } from "./types";

export const SegmentCrudDataGridDialog: FC<{
  dialog: DialogController<SegmentCrudDialogState>;
  is_recipient_type: boolean;
  reload: () => void;
  processedSegments: SegmentRowWithOptions[];
}> = ({ dialog, is_recipient_type, reload, processedSegments }) => {
  const { t } = useTranslation();

  const { handleDelete, handleUnarchive, handleUnarchiveWithNewName, handleEdit, handleCreate } =
    useSegmentCrudFunctions({
      is_recipient_type,
      reload,
      onUnarchiveDuplicate: (data) => dialog.handleOpen({ type: "unarchiveWithNewName", data }),
    });

  if (!dialog.data || !dialog.open) return null;

  const { type, data } = dialog.data;

  if (type === "add") {
    return (
      <NewSegmentModal
        isRecipientType={is_recipient_type}
        open={true}
        onCancel={() => dialog.handleClose()}
        onSave={async (segment) => {
          const result = await handleCreate(segment);
          if (result.success) dialog.handleClose();

          return !result.success && result.errors ? result.errors : undefined;
        }}
      />
    );
  }

  if (type === "edit") {
    return (
      <EditSegmentModal
        open={true}
        segmentId={data.segment_id}
        onCancel={() => dialog.handleClose()}
        onSave={async (segment, onlyNameEdited) => {
          const result = await handleEdit(segment, onlyNameEdited);
          if (result.success) dialog.handleClose();

          return !result.success && result.errors ? result.errors : undefined;
        }}
        disabled={
          (processedSegments
            .find((x) => x.id === data.segment_id)
            ?.moments.filter(({ finished }) => !finished).length ?? 0) > 1
        }
        defaultTab={data.defaultTab}
      />
    );
  }

  if (type === "delete") {
    return (
      <ArchiveDialog
        title={t("Archive Segment")}
        content={t("Are you sure you wish to archive this Segment?")}
        open={true}
        onCancel={() => {
          dialog.handleClose();
          data.resolve(false);
        }}
        onConfirm={async () => {
          dialog.handleClose();
          data.resolve((await handleDelete(data.segment_id)) ?? false);
        }}
      />
    );
  }

  if (type === "unarchive") {
    return (
      <ArchiveDialog
        title={t("Unarchive Segment")}
        content={t("Are you sure you wish to unarchive this Segment?")}
        open={true}
        onCancel={() => dialog.handleClose()}
        onConfirm={async () => {
          await handleUnarchive(data.segment_id, data.segment_name);
          dialog.handleClose();
        }}
      />
    );
  }

  if (type === "unarchiveWithNewName") {
    return (
      <UnarchiveWithNewNameModal
        open={true}
        onCancel={() => dialog.handleClose()}
        segmentId={data.segment_id}
        segmentName={data.segment_name}
        onSave={async (segment) => {
          const result = await handleUnarchiveWithNewName(segment);
          if (!result) return;

          if (result?.success) dialog.handleClose();

          return !result.success && result.errors ? result.errors : undefined;
        }}
      />
    );
  }

  if (type === "clone") {
    return (
      <CloneSegmentModal
        open={true}
        segmentId={data.segment_id}
        onCancel={() => dialog.handleClose()}
        onSave={async (segment) => {
          const result = await handleCreate(segment);
          if (result.success) dialog.handleClose();

          return !result.success && result.errors ? result.errors : undefined;
        }}
      />
    );
  }

  return null;
};
