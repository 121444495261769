import { Link, Typography } from "@mui/material";
import { FC } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Plus } from "../../../../styles/icons/plus";
import { IconCard } from "../../../generic/components/icon-card";

export const NotStarted: FC<{ onDrop: (file: File) => void }> = ({ onDrop }) => {
  const { t } = useTranslation();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileUpload,
  } = useDropzone({
    onDrop: (files: File[]) => onDrop(files[0]),
    accept: {
      "text/csv": [".csv"],
      "text/plain": [".csv"],
      "application/vnd.ms-excel": [".csv"],
      "application/csv": [".csv"],
    },
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <div {...getRootProps()}>
      <input data-testid="file-dropzone" {...getInputProps()} />
      <IconCard
        title="Upload using CSV"
        icon={{
          icon: <Plus data-testid="PlusIcon" color="success" />,
          onClick: openFileUpload,
          sx: {
            bgcolor: "#edf6ee",
            "&:hover": {
              backgroundColor: "action.hover",
              cursor: "pointer",
              opacity: 0.5,
            },
            ...(isDragActive && {
              backgroundColor: "action.active",
              opacity: 0.5,
            }),
          },
        }}
      >
        <Typography variant="subtitle2" align="center">
          {t("Upload new employees or update them using a CSV. Download an example ")}
          <Link href="/example.csv" underline="hover">
            {t("CSV template here.")}
          </Link>
        </Typography>
      </IconCard>
    </div>
  );
};
