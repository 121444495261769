import { Box, CircularProgress, Divider, Grid, GridProps, Tooltip } from "@mui/material";
import { FC, ReactElement, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { UUID } from "../../../utils/uuid";
import { SequenceIcon } from "../../generic/components/sequence-icon";
import { useMomentClonable } from "../../moment/component/copy/utils";
import { UseMomentProperties } from "../types";
import { CopyAction } from "./actions/copy";
import { EditAction } from "./actions/edit";
import { PublishToggleAction } from "./actions/publish-toggle";
import { ViewAction } from "./actions/view";
import { CardArchiveButton } from "./card-archive-button";

interface Props extends GridProps {
  moment: Pick<
    UseMomentProperties,
    | "id"
    | "title"
    | "status"
    | "valid_on_save"
    | "immediate"
    | "channel"
    | "only_additional_recipients"
    | "target_manager"
    | "target_connection_type"
    | "target_channel"
    | "notify_channel"
    | "use_lexical_editor"
    | "sequence"
    | "program"
  >;
  small?: boolean;
  switchable?: boolean;
  showSequenceIcon?: boolean;
  reload?: () => void;
}

export const MomentCardActions = ({
  moment,
  small,
  switchable = true,
  reload,
  showSequenceIcon,
  ...props
}: Props): ReactElement => {
  const { t } = useTranslation();
  const { clonable, loading } = useMomentClonable(moment);

  const { id, title, status } = moment;
  const [active, setActive] = useState(status === "active");

  const isArchived = status === "archived";
  const publishable = switchable && allowPublishToggle(moment, active);

  const handleReload = useCallback(
    (newActive: boolean) => {
      setActive(newActive);
      reload?.();
    },
    [reload],
  );

  if (loading) return <CircularProgress />;

  return (
    <Box display={"flex"} alignItems={"center"} {...props}>
      {showSequenceIcon && moment.sequence?.name && (
        <SequenceIconWrapper name={moment.sequence.name} />
      )}
      <Grid container justifyContent="flex-end" alignItems="center" flexGrow={1}>
        <Box>
          {id && (
            <CardArchiveButton
              id={id}
              isDeleted={isArchived}
              isArchived={isArchived}
              isActive={active}
              unsavedChanges={false}
              status={status}
              onClicked={reload}
            />
          )}
          {!active && (status === "draft" || status === "paused") ? (
            <EditAction id={id} title={title} />
          ) : (
            <ViewAction id={id} title={title} />
          )}
          {clonable && id && (
            <CopyAction
              id={id}
              title={title}
              clonable={clonable}
              disabled={
                !moment.program
                  ? { reason: t("This Moment must have a Program selected before copying") }
                  : undefined
              }
            />
          )}
          {small && publishable && (
            <PublishToggleAction id={id} status={status} reload={handleReload} />
          )}
        </Box>
        {!small && publishable && (
          <>
            <Divider orientation="vertical" flexItem={true} sx={{ ml: 0.5 }} />
            {<PublishToggleAction id={id} status={status} reload={handleReload} />}
          </>
        )}
      </Grid>
    </Box>
  );
};

export const SequenceIconWrapper: FC<{ name: string }> = ({ name }) => {
  return (
    <Tooltip title={`Part of the ${name} sequence`} arrow>
      <Box>
        <SequenceIcon />
      </Box>
    </Tooltip>
  );
};

export function allowPublishToggle(
  moment: {
    id: UUID | null;
    status: "draft" | "paused" | "finished" | "active" | "archived" | null;
    valid_on_save: boolean | null;
    immediate: boolean | null;
  },
  active: boolean,
): boolean {
  const { status, valid_on_save, immediate } = moment;

  if (immediate && active) return false;
  if (status === "archived" || status === "finished") return false;

  return valid_on_save ?? false;
}
