import { UseApiLoaderResponse } from "../../../server/api/hooks/use-api-loader";
import { notEmpty } from "../../../utils/not-empty";
import { ContentType } from "../store/types";
import { useMultiformat } from "./use-multi-format";

export const usePrompts = (): UseApiLoaderResponse<Record<string, string[]> | null> => {
  const { loading, error, data, reload } = useMultiformat();

  if (loading) return { loading, error, data: null, reload };
  if (error) return { loading, error, data: null, reload };

  const prompts = data?.["content-types"].reduce(
    (acc: Record<string, string[]>, c: ContentType) => {
      const promptsForContentType =
        c.example_prompts
          ?.split(",")
          .map((prompt) => prompt.trim())
          .filter(notEmpty) ?? [];

      if (promptsForContentType.length > 0 && c.content_type) {
        return { ...acc, [c.content_type]: promptsForContentType };
      }
      return acc;
    },
    {},
  );

  return { loading, error, data: prompts, reload };
};
