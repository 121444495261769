import { createContext, FC, ReactNode, useContext, useMemo } from "react";
import { EmojiCache, makeEmojiCache } from "../../../../modules/emoji/emoji-cache";
import { MomentAnalyticsTotals } from "../../../../modules/stats/server/analytics-service";
import { UseSupabaseLoaderState } from "../../../../server/supabase/hooks/types";
import { propertyNotEmpty } from "../../../../utils/not-empty";
import { UseAllMomentStatsState } from "../hooks/use-all-moment-stats";
import { TypedGridDataSource } from "../hooks/use-grouped-moment-analytics-data";
import { TransformedGroupedMomentAnalytics } from "../hooks/utils";

type MomentStatsTotalsContextType = {
  statsDataSource: TypedGridDataSource<TransformedGroupedMomentAnalytics>;
  emojiCache: EmojiCache;
  allMomentStats?: UseAllMomentStatsState;
  totals?: UseSupabaseLoaderState<MomentAnalyticsTotals>;
};

const MomentAnalyticsContext = createContext<MomentStatsTotalsContextType | null>(null);

type MomentAnalyticsProviderProps = Omit<MomentStatsTotalsContextType, "emojiCache"> & {
  children: ReactNode;
};

export const MomentAnalyticsProvider: FC<MomentAnalyticsProviderProps> = ({
  statsDataSource,
  allMomentStats,
  totals,
  children,
}) => {
  const emojiCache = useMemo(() => {
    if (!totals || totals.loading || totals.error) return {};

    const emojis = Object.entries(totals.data.reaction_summary).map(([emoji, { custom_url }]) => ({
      emoji,
      custom_url,
    }));

    return makeEmojiCache(
      emojis.map(({ emoji }) => emoji),
      Object.fromEntries(
        emojis
          .filter(propertyNotEmpty("custom_url"))
          .map(({ emoji, custom_url }) => [emoji, custom_url]),
      ),
    );
  }, [totals]);

  return (
    <MomentAnalyticsContext.Provider
      value={{ totals, statsDataSource, emojiCache, allMomentStats }}
    >
      {children}
    </MomentAnalyticsContext.Provider>
  );
};

export const useMomentAnalyticsContext = (): MomentStatsTotalsContextType => {
  const context = useContext(MomentAnalyticsContext);
  if (!context) {
    throw new Error("useMomentAnalyticsContext be used within a MomentAnalyticsProvider");
  }
  return context;
};
