import Warning from "@mui/icons-material/Warning";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Check } from "../../../../styles/icons/check";
import { Pencil } from "../../../../styles/icons/pencil";
import { Plus } from "../../../../styles/icons/plus";
import { X } from "../../../../styles/icons/x";
import { UnreachableCaseError } from "../../../../utils/errors/unreachable";
import { IconCard } from "../../../generic/components/icon-card";
import { CsvLineResult, ProcessingState, RemoteInfo } from "../../types";

export const Finished: FC<{
  state: "complete" | "error" | "warning" | "invalid-file";
  remoteInfo?: RemoteInfo;
  setProcessingState: (processingState: ProcessingState) => void;
}> = ({ state, remoteInfo, setProcessingState }) => {
  const { t } = useTranslation();

  let icon: React.JSX.Element;
  let iconColor: string;
  let title: string;
  switch (state) {
    case "complete":
      icon = <Check data-testid="CheckIcon" />;
      iconColor = "success.main";
      title = t("Upload complete.");
      break;
    case "error":
      icon = <X data-testid="XIcon" />;
      iconColor = "error.main";
      title = t("Something went wrong.");
      break;
    case "invalid-file":
      icon = <X data-testid="XIcon" />;
      iconColor = "error.main";
      title = t("Invalid file, please try again with a CSV.");
      break;
    case "warning":
      icon = <Warning />;
      iconColor = "warning.main";
      title = t("Confirmation needed to continue processing.");
      break;
    default:
      throw new UnreachableCaseError();
  }

  const warningAckFn =
    state === "warning" ? (s: ProcessingState) => setProcessingState(s) : undefined;

  return (
    <IconCard
      title={title}
      icon={{
        icon,
        sx: {
          bgcolor: iconColor,
          opacity: 0.5,
          "&:hover": {
            backgroundColor: "action.hover",
            cursor: "pointer",
            opacity: 0.5,
          },
        },

        onClick: state === "warning" ? undefined : () => setProcessingState("not started"),
      }}
    >
      {remoteInfo?.summary && (
        <List dense={true}>
          <ListItem>
            <ListItemIcon>
              <Plus data-testid="PlusIcon" />
            </ListItemIcon>
            <ListItemText
              primary={t("{{num}} new records.", {
                num: remoteInfo.summary.new,
              })}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Pencil />
            </ListItemIcon>
            <ListItemText
              primary={t("{{num}} updated records.", {
                num: remoteInfo.summary.updates,
              })}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <X data-testid="XIcon" />
            </ListItemIcon>
            <ListItemText
              primary={t("{{num}} removed records.", {
                num: remoteInfo.summary.removed,
              })}
            />
          </ListItem>
        </List>
      )}
      {remoteInfo?.exception && <Alert severity="error">{remoteInfo.exception}</Alert>}
      {remoteInfo?.errors && remoteInfo?.errors.length > 0 && (
        <InfoDialog data={remoteInfo.errors} title={t("CSV Upload Errors")} severity="error" />
      )}
      {remoteInfo?.warnings && remoteInfo?.warnings.length > 0 && (
        <InfoDialog
          data={remoteInfo.warnings}
          title={t("CSV Upload Warnings")}
          severity="warning"
          warningAckFn={warningAckFn}
        />
      )}
    </IconCard>
  );
};

const InfoDialog: FC<{
  data: CsvLineResult[];
  title: string;
  severity: "warning" | "error";
  warningAckFn?: (state: ProcessingState) => void;
}> = ({ data, title, severity, warningAckFn }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <Button
        data-analytics-id={`csv-upload-${severity}-info`}
        color={severity}
        onClick={() => setModalOpen(true)}
        fullWidth
      >
        {t("View {{severity}}s{{continue}}", {
          severity,

          continue: warningAckFn ? t(" to continue") : "",
        })}
      </Button>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        scroll="paper"
        aria-labelledby={`${severity}-dialog-title`}
        aria-describedby={`${severity}-dialog-description`}
      >
        <DialogTitle id={`${severity}-dialog-title`}>{title}</DialogTitle>
        <DialogContent id={`${severity}-dialog-description`}>
          <List dense={true}>
            {data.map((datum) => {
              return datum.messages.map((message, index) => {
                return (
                  <ListItem key={`${datum.line}-${index}`}>
                    <ListItemText
                      primary={
                        <>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color={`${severity}.main`}
                          >
                            ({message.columns.join(", ")})
                          </Typography>
                          {` - ${message.message}`}
                        </>
                      }
                      secondary={t("Row {{num}}", { num: datum.line })}
                    />
                  </ListItem>
                );
              });
            })}
          </List>
          <Typography variant="caption">The row number includes the row heading</Typography>
        </DialogContent>
        {warningAckFn && (
          <DialogActions>
            <Button
              data-analytics-id={`csv-upload-${severity}-dialog-cancel`}
              variant="outlined"
              color="error"
              onClick={() => {
                setModalOpen(false);
                warningAckFn("not started");
              }}
            >
              Cancel Processing
            </Button>
            <Button
              data-analytics-id={`csv-upload-${severity}-dialog-continue`}
              variant="outlined"
              color="success"
              onClick={() => {
                setModalOpen(false);
                warningAckFn("warning-acked");
              }}
            >
              Continue Processing
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
