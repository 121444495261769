import { FC } from "react";
import { useCreateAssetCallback } from "./hooks/use-upsert-asset";
import { Asset } from "./server/digital-asset-service";
import { UploadFileModal } from "./upload-file-modal";

export const UploadNewFileModal: FC<{
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  onCancel: () => void;
  onChange: () => void;
  onViewFileClick: (asset: Asset) => void;
}> = ({ ...props }) => {
  const createAsset = useCreateAssetCallback();

  return <UploadFileModal heading="Upload file" onSave={createAsset} {...props} />;
};
