import { get as getEmoji, has as hasEmoji } from "node-emoji";

export type EmojiCacheEntry = { type: "url" | "emoji"; data: string | undefined };
export type EmojiCache = { [key: string]: EmojiCacheEntry };

export function makeEmojiCache(
  emojis: string[],
  usedCustomEmojis?: { [emoji: string]: string }, // mapping from emoji to url
): EmojiCache {
  return emojis
    .filter((x) => (usedCustomEmojis !== undefined && x in usedCustomEmojis) || hasEmoji(x))
    .map((x) => ({
      [x]:
        usedCustomEmojis !== undefined && x in usedCustomEmojis
          ? { data: usedCustomEmojis[x], type: "url" as const }
          : { data: getEmoji(x), type: "emoji" as const },
    }))
    .reduce((prev, current) => ({ ...prev, ...current }), {});
}
