import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { isEqual } from "lodash-es";
import { contentTypeFamily } from "./content-type-family";

export const dhTemplateConfigFamily = atomFamily(
  (contentTypeName?: string | null) =>
    atom((get) => {
      if (!contentTypeName) return;
      const contentType = get(contentTypeFamily(contentTypeName));
      return {
        templateCode: contentType?.design_huddle_id ?? "AI Organic Test",
        dimensions: {
          width: contentType?.dh_dimensions?.width ?? undefined,
          height: contentType?.dh_dimensions?.height ?? undefined,
        },
      };
    }),
  isEqual,
);
