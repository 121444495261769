import {
  SerializedTableCellNode,
  SerializedTableNode,
  SerializedTableRowNode,
  TableCellHeaderStates,
  TableCellNode,
  TableNode,
  TableRowNode,
} from "@lexical/table";
import {
  ElementFormatType,
  SerializedElementNode,
  SerializedLexicalNode,
  SerializedParagraphNode,
  SerializedTextNode,
} from "lexical";
import { cloneDeep } from "lodash-es";
import { getImageDimensions } from "../../utils/image-dimensions";
import { SerializedImageNode } from "../message/editor/nodes/image-node";
import { SerialisedEditorDesign } from "../message/editor/types/serialised-editor-design";
import { EmailHeaderProperties } from "./types/email-header-type";

export function makeEmptySerializedState(
  body?: (SerializedElementNode | SerializedLexicalNode)[],
): SerialisedEditorDesign {
  return {
    root: {
      type: "root",
      format: "",
      indent: 0,
      version: 1,
      children: body ?? [],
      direction: "ltr",
    },
  };
}

function headerTable(children: {
  [k: string]: SerializedLexicalNode[];
}): SerializedTableNode | undefined {
  if (!children) {
    return undefined;
  }
  return {
    type: TableNode.getType(),
    children: [
      {
        type: TableRowNode.getType(),
        children: [
          {
            type: TableCellNode.getType(),
            children: children["left"],
            version: 1,
            direction: "ltr",
            format: "left",
            width: undefined,
            indent: 0,
            headerState: TableCellHeaderStates.NO_STATUS,
          },
          {
            type: TableCellNode.getType(),
            children: children["center"],
            version: 1,
            direction: "ltr",
            format: "left",
            width: undefined,
            indent: 0,
            headerState: TableCellHeaderStates.NO_STATUS,
          },
          {
            type: TableCellNode.getType(),
            children: children["right"],
            version: 1,
            direction: "ltr",
            format: "left",
            width: undefined,
            indent: 0,
            headerState: TableCellHeaderStates.NO_STATUS,
          },
        ] as SerializedTableCellNode[],
        version: 1,
        direction: "ltr",
        format: "left",
        indent: 0,
        // Lexical type is wrong this is fully supported.
        height: undefined as unknown as number,
      } as SerializedTableRowNode,
    ],
    version: 1,
    direction: "ltr",
    format: "left",
    indent: 0,
  };
}

export async function makeHeaderSerializedState(
  header: EmailHeaderProperties | null,
  handleImageType?: (url: string | undefined) => Promise<string>,
): Promise<SerialisedEditorDesign | null> {
  if (header === null || !(header.logo || header.text)) return null;

  const text: { [k: string]: SerializedParagraphNode[] } =
    header.text && header.text.align
      ? {
          [header.text.align]: [
            {
              type: "paragraph",
              format: header.text.align as ElementFormatType,
              textStyle: "",
              textFormat: 0,
              indent: 0,
              version: 1,
              children: [
                {
                  mode: "normal",
                  text: header.text.value,
                  format: 0,
                  style: `${header.text.color ? `color: ${header.text.color};` : ""}`,
                  type: "text",
                  version: 1,
                  detail: 0,
                } as SerializedTextNode,
              ],
              direction: null,
            },
          ],
        }
      : {};

  const logoSrc = handleImageType
    ? await handleImageType(header?.logo?.src)
    : (header?.logo?.src ?? "");
  const logoAspect = header.logo ? await getImageDimensions(logoSrc) : { width: 1, height: 1 };

  const logo: { [k: string]: SerializedImageNode[] } = header.logo
    ? {
        [header.logo.align]: [
          {
            url: logoSrc,
            type: "image",
            version: 2,
            format: header.logo.align as ElementFormatType,
            proportionOfMaxWidth: header.logo.proportionOfMaxWidth ?? 1,
            aspect: logoAspect.width / logoAspect.height,
          },
        ],
      }
    : {};

  const logoTable = header.logo?.version === 2 ? logo[header.logo.align][0] : headerTable(cloneDeep(logo));
  const textTable = header.text && header.text.align && header.logo?.version === 2 ? text[header.text?.align][0] : headerTable(text);

  return makeEmptySerializedState([
    ...(logoTable ? [logoTable] : []),
    ...(textTable ? [textTable] : []),
  ]);
}
