import { Box, Stack, StackProps, Tooltip } from "@mui/material";
import { FC } from "react";

export const TableStack: FC<
  StackProps & {
    tooltip?: string;
  }
> = ({ children, tooltip, ...props }) => {
  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      spacing={1}
      height="100%"
      sx={{ py: 2 }}
      {...props}
    >
      <Tooltip title={tooltip} arrow placement="top">
        {/* To avoid making every child a forward ref,
         just wrap in a content sized box */}
        <Box>{children}</Box>
      </Tooltip>
    </Stack>
  );
};
