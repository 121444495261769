import { Link, Stack, Typography } from "@mui/material";
import { getGridStringOperators, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { sortBy, uniqBy } from "lodash-es";
import { useMemo } from "react";
import { ChannelIcon } from "../../../../modules/channels/components/channel-icon";
import { ChannelName } from "../../../../modules/channels/types";
import { autocompleteOperator } from "../../../../modules/generic/components/crud-data-grid/data-grid-multi-select-operator";
import { UsedMomentInfo } from "../../../../modules/moment/server/supabase-moment-service";
import { UsedProgramInfo } from "../../../../modules/programs/server/supabase-program-service";
import { UsedSegmentInfo } from "../../../../modules/segment/server/supabase-segment-service";
import { useSendTableColumns } from "../../analytics/hooks/use-send-table-columns";
import { TableStack } from "../../analytics/send-table/table-stack";
import { TransformedSentMomentData } from "../hooks/use-sent-moment-data";

export function useSentMomentTableColumns({
  segments,
  programs,
  moments,
}: {
  segments: Pick<UsedSegmentInfo, "id" | "name">[];
  programs: Pick<UsedProgramInfo, "id" | "name">[];
  moments: Pick<UsedMomentInfo, "id" | "name">[];
}): GridColDef<TransformedSentMomentData>[] {
  const send_table_columns = useSendTableColumns();

  const columns: GridColDef<TransformedSentMomentData>[] = useMemo(
    () => [
      send_table_columns[0] as GridColDef<TransformedSentMomentData>,
      {
        field: "channel_name",
        headerName: "Channel",
        renderHeader: () => null,
        sortable: false,
        filterable: true,
        filterOperators: [
          autocompleteOperator([
            { label: "Slack", value: "slack" },
            { label: "Teams", value: "teams" },
            { label: "Work Email", value: "work_email" },
            { label: "Personal Email", value: "personal_email" },
            { label: "SMS", value: "twilio" },
          ]),
        ],
        flex: 0.1,
        renderCell: (params: GridRenderCellParams<TransformedSentMomentData, ChannelName>) =>
          params.value ? (
            <Stack justifyContent="center" alignItems="center" height="100%">
              <ChannelIcon channel={params.value} fontSize="small" />
            </Stack>
          ) : null,
      },
      {
        field: "publisher_name",
        headerName: "Published By",
        sortable: false,
        filterable: false,
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<
            TransformedSentMomentData,
            TransformedSentMomentData["publisher_name"]
          >,
        ) =>
          params.value ? (
            <TableStack>
              <Typography variant="body2">{params.value}</Typography>
              <Typography variant="caption">{params.row.publisher_email}</Typography>
            </TableStack>
          ) : (
            <TableStack>
              <Typography variant="body2">-</Typography>
            </TableStack>
          ),
      },
      {
        field: "moment_title",
        headerName: "Moment",
        sortable: false,
        filterable: true,
        filterOperators: [
          autocompleteOperator(
            sortBy(uniqBy(moments, "name"), (x) => x.name.toLocaleLowerCase()).map((moment) => ({
              label: moment.name,
              value: moment.name,
            })),
          ),
          ...getGridStringOperators().filter((op) => op.value === "contains"),
        ],
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<
            TransformedSentMomentData,
            TransformedSentMomentData["moment_title"]
          >,
        ) =>
          params.value ? (
            <TableStack>
              <Link target="_blank" href={`/moments/${params.row.moment_id}`}>
                {params.value}
              </Link>
            </TableStack>
          ) : null,
      },
      {
        field: "segment_name",
        headerName: "Segment Name",
        sortable: false,
        filterable: true,
        filterOperators: [
          autocompleteOperator(
            sortBy(uniqBy(segments, "name"), (x) => x.name.toLocaleLowerCase()).map((segment) => ({
              label: segment.name,
              value: segment.name,
            })),
          ),
        ],
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<
            TransformedSentMomentData,
            TransformedSentMomentData["segment_name"]
          >,
        ) =>
          params.value ? (
            <TableStack>
              <Typography variant="body2">{params.value}</Typography>
            </TableStack>
          ) : null,
      },
      {
        field: "program_name",
        headerName: "Program Name",
        sortable: false,
        filterable: true,
        filterOperators: [
          autocompleteOperator(
            sortBy(programs, (x) => x.name.toLocaleLowerCase()).map((program) => ({
              label: program.name,
              value: program.name,
            })),
          ),
        ],
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<
            TransformedSentMomentData,
            TransformedSentMomentData["program_name"]
          >,
        ) =>
          params.value ? (
            <TableStack>
              <Typography variant="body2">{params.value}</Typography>
            </TableStack>
          ) : null,
      },
      ...(send_table_columns.slice(1) as GridColDef<TransformedSentMomentData>[]),
    ],
    [send_table_columns, segments, programs, moments],
  );

  return columns;
}
