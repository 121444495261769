import { Alert, Button } from "@mui/material";
import { FC } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const UploadFileFailedToast: FC<{ filename: string; onClick: () => void }> = ({
  filename,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <Alert
      severity="error"
      action={
        <Button
          onClick={() => {
            toast.remove();
            onClick();
          }}
          data-analytics-id="upload-file-error-toast-try-again"
          variant="text"
        >
          {t("Try again")}
        </Button>
      }
      sx={{ alignItems: "center", "& .MuiAlert-action": { pt: 0 } }}
    >
      {t("There was a problem uploading '{{filename}}'", { filename })}
    </Alert>
  );
};
