import { Button } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { formatDatetimeShort, fromISO8601 } from "../../../../../utils/iso8601";
import { UUID } from "../../../../../utils/uuid";
import { Columns } from "../../../../generic/components/crud-data-grid/types";
import { FavoriteButton } from "../favorite-button";
import { SegmentRow } from "../types";

export function useSegmentCrudColumns({
  is_recipient_type,
  onRelatedMomentsButtonClick,
  onSegmentNameClick,
}: {
  is_recipient_type: boolean;
  onSegmentNameClick: (id: UUID) => void;
  onRelatedMomentsButtonClick: (data: {
    id: UUID;
    title: string;
    moments: SegmentRow["moments"];
  }) => void;
}): Columns<SegmentRow> {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      name: {
        headerName: t(is_recipient_type ? "Audience" : "Segment name"),
        flex: 3,
        editable: true,
        valueGetter: (_, row) =>
          row.name ??
          (row?.moments?.[0]?.title ? `Moment: ${row?.moments?.[0]?.title}` : null) ??
          "-",
        renderCell: (params) => (
          <Button
            data-analytics-id="segment-grid-segment-name"
            onClick={() => onSegmentNameClick(params.row.id)}
            color="primary"
            variant="text"
          >
            {params.value}
          </Button>
        ),
      },
      description: {
        headerName: t("Description"),
        flex: 4,
        editable: true,
        valueGetter: (_, row) => row.description ?? "",
      },
      created_at: {
        headerName: t("Created"),
        flex: 2,
        editable: false,
        valueGetter: (_, row) => row.created_at,
        valueFormatter: (value) => (value ? formatDatetimeShort(fromISO8601(value)) : ""),
      },
      moments: {
        headerName: t("Related Moments"),
        flex: 1,
        editable: false,
        renderCell: (params) => {
          const numMoments = params.row.moments?.length ?? 0;
          return (
            <Button
              data-analytics-id="segment-grid-related-moments"
              onClick={() =>
                onRelatedMomentsButtonClick({
                  id: params.row.id,
                  title: params.row.name || "",
                  moments: params.row.moments,
                })
              }
              disabled={numMoments === 0}
            >
              {t("{{count}} moments", { count: numMoments })}
            </Button>
          );
        },
      },
      is_favorite: {
        headerName: t("Favorite"),
        flex: 0.75,
        editable: true,
        renderCell: (params) => {
          return <FavoriteButton key={`favorite${params.row.id}`} row={params.row} />;
        },
      },
    }),
    [t, onRelatedMomentsButtonClick, is_recipient_type, onSegmentNameClick],
  );
}
