import { useSupabaseLoader } from "../../../server/supabase/hooks";
import { UseSupabaseLoaderState } from "../../../server/supabase/hooks/types";
import { SupabaseAccountService } from "../../generic/server/supabase-account-service";
import {
  personalEmailConfig,
  slackConfig,
  teamsConfig,
  twilioConfig,
  workEmailConfig,
} from "../../moment/component/config";
import { ChannelProps } from "../components/channel-card";

export function useEnabledChannels(): UseSupabaseLoaderState<ChannelProps[]> {
  return useSupabaseLoader(async ({ supabase, account_id }) => {
    const { data } = await new SupabaseAccountService(supabase).get(account_id);
    return [
      ...(data && data.enable_slack ? [slackConfig] : []),
      workEmailConfig,
      personalEmailConfig,
      ...(data && data.enable_teams ? [teamsConfig] : []),
      ...(data && data.enable_twilio ? [twilioConfig] : []),
    ];
  }, []);
}
