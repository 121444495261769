import { ImagesService } from "../server/cloudflare/images-service";
import { uuidv4 } from "./uuid";

export const convertEmailSvgImage = async (
  url: string,
  getToken: () => Promise<string>,
  channel: string,
): Promise<{ success: true; url: string } | { success: false; url: undefined }> => {
  const img = await fetch(url);
  const blob = await img.blob();

  if (!blob.type.includes("image/svg")) {
    return { success: true, url };
  }

  const imagesService = new ImagesService();
  const id = uuidv4() as string;
  const {file} = await convertSvgImage(blob, channel);
  return await imagesService.upload(file, id, await getToken());
};

export const convertSvgImage = (file: File | Blob, channel: string): Promise<{file: File; dimensions: {width: number; height: number;}}> => {
  const maxDimensions =
  channel === "slack"
    ? {
        width: 600,
        height: 400,
      }
    : {
        width: 1024,
        height: 10240,
      };
  const url = URL.createObjectURL(file);

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) return reject(Error("Could not get canvas context"));

      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = "high";

      const scale = Math.min(maxDimensions.width / img.width, maxDimensions.height / img.height);
      canvas.width = img.width * scale;
      canvas.height = img.height * scale;

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      canvas.toBlob((blob) => {
        if (!blob) return reject(Error("Could not get blob from canvas"));

        resolve({
          file: new File([blob], file instanceof File ? file.name : "", {
            type: "image/jpeg",
          }),
          dimensions: {
            width: img.width,
            height: img.height
          }});
      });
    };
    img.onerror = reject;
    img.src = url;
  });
};
