import { Stack } from "@mui/material";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-pro";
import { FC } from "react";

export const CsvExportButton: FC = () => {
  return (
    <Stack direction="row" justifyContent="flex-end" width="100%" py={1}>
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    </Stack>
  );
};
