import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Button, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { omit } from "lodash-es";
import { FC, useMemo, useState } from "react";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSupabaseCallback } from "../../../server/supabase/hooks";
import {
  formatAnnuallyRepeat,
  formatMonthlyRepeat,
  formatWeeklyRepeat,
  fromISO8601_DATE,
  toISO8601_DATE,
} from "../../../utils/iso8601";
import { uuidv4 } from "../../../utils/uuid";
import { SupabaseAccountPersonFieldService } from "../server/supabase-account-person-field-service";
import {
  SupabaseEventService,
  SupabaseScheduleEventService,
} from "../server/supabase-event-service";
import { Event, OptionalInterval, eventSchema } from "../types";

/** Form for editing `event`s; responsible for loading and saving */
export const EventForm: FC<{
  onChange: (event: Event) => void;
}> = ({ onChange }) => {
  const { t } = useTranslation();
  const [errorToDisplay, setErrorToDisplay] = useState<string>();
  const [validationErrorToDisplay, setValidationErrorToDisplay] = useState<string>();

  const defaultValues = useMemo(
    () => ({
      id: uuidv4(),
      key: uuidv4(),
      source: "WEB",
      title: "",

      date: toISO8601_DATE(new Date())!,
      repeat: "never" as OptionalInterval,
    }),
    [],
  );

  const methods = useForm<Event>({
    defaultValues,
    resolver: yupResolver(eventSchema),
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useWatch({
    control,
    name: ["date"],
  });
  const [date] = methods.getValues(["date"]);

  const saveValues = useSupabaseCallback(
    async ({ supabase }, values: Event) => {
      if (!values.title) throw new Error("Title expected");
      values.key = values.title.trim().toLowerCase().replace(/\W+/g, "_");
      const {
        data: dataSchedule,
        error: dataError,
        status,
      } = await new SupabaseScheduleEventService(supabase).upsert({
        id: values.id,
        event_key: values.key,
        title: values.title,
      });
      if (status === 409) {
        return setValidationErrorToDisplay(
          t("There is already an event called '{{eventTitle}}' please use another title", {
            eventTitle: values.title,
          }),
        );
      }
      if (dataError || !dataSchedule) {
        return setErrorToDisplay(dataError?.message);
      }

      const event = omit(
        { ...values, repeat: values.repeat === "never" ? null : values.repeat, number_of_repeats: values.repeat === "never" ? 0 : 100 },
        "title",
      );
      const { data, error } = await new SupabaseEventService(supabase).upsert(event);
      if (error || !data) {
        return setErrorToDisplay(error?.message);
      }
      await new SupabaseAccountPersonFieldService(supabase).insert({
        key: `event_${values.key}`,
        display_name: values.title,
        merge_sample: "December 25th",
        connection_merge_sample: "December 25th",
        merge_field: true,
        connection_merge_field: false,
        channel_merge_field: true,
        segmentation_field: false,
        field_type: "DATE",
        allow_initial: true,
        allow_repeats: values.repeat !== "never",
      });
      onChange({ ...data, title: dataSchedule.title, repeat: values.repeat });
      reset(defaultValues);
    },
    [defaultValues, onChange, reset, t],
  );

  if (errorToDisplay) return <Alert severity="error">{errorToDisplay}</Alert>;

  return (
    <FormProvider {...methods}>
      {validationErrorToDisplay && <Alert severity="warning">{validationErrorToDisplay}</Alert>}
      <Box sx={{ mt: 1 }}>
        <Controller
          name="title"
          control={control}
          render={({ field, fieldState, formState: { submitCount } }) => (
            <TextField
              {...field}
              label={t("Title")}
              error={Boolean(submitCount > 0 && fieldState.error)}
              helperText={submitCount > 0 && fieldState.error?.message}
              sx={{ mr: 2 }}
              id="event-title-input"
            />
          )}
        />
        <Controller
          name="date"
          control={control}
          render={({ field, fieldState }) => (
            <DatePicker
              {...field}
              value={fromISO8601_DATE(field.value)}
              onChange={(value) => field.onChange(toISO8601_DATE(value))}
              slotProps={{
                textField: {
                  id: "event-date-input",
                  error: Boolean(fieldState.error),
                  helperText: fieldState.error?.message,
                  sx: { mr: 2 },
                },
              }}
            />
          )}
        />
        <Controller
          name="repeat"
          control={control}
          render={({ field }) => (
            <Select<OptionalInterval | undefined | null>
              {...field}
              value={field.value}
              onChange={(value) => field.onChange(value)}
            >
              <MenuItem value={"never"}>{t("Doesn't repeat")}</MenuItem>
              <MenuItem value={"1 week"}>{formatWeeklyRepeat(date)}</MenuItem>
              <MenuItem value={"1 month"}>{formatMonthlyRepeat(date)}</MenuItem>
              <MenuItem value={"1 year"}>{formatAnnuallyRepeat(date)}</MenuItem>
            </Select>
          )}
        />
        <Button
          data-analytics-id="create-event-form-dialog-create"
          sx={{ ml: 1.7, py: 2 }}
          type="button"
          loading={isSubmitting}
          variant="contained"
          onClick={(e) => void handleSubmit(saveValues)(e).catch(console.error)}
        >
          {t("Create")}
        </Button>
      </Box>
    </FormProvider>
  );
};
