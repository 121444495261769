import yup from "../../../../utils/yup";

export const customEmailSchema = yup.object({
  address: yup
    .string()
    .email("Please enter a valid email address.")
    .required("Please enter a valid email address."),
  from_name: yup.string().optional().nullable(),
  reply_to: yup.string().optional().nullable(),
});

export type CustomEmail = yup.InferType<typeof customEmailSchema>;

export const customEmailDomainSchema = yup.object({
  domain: yup
    .string()
    .domain("Please enter a valid domain.")
    .required("Please enter a valid domain."),
});

export type CustomEmailDomain = yup.InferType<typeof customEmailDomainSchema>;
