import BlurLinearOutlined from "@mui/icons-material/BlurLinearOutlined";
import { Button, Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { includes, isEmpty, uniqWith } from "lodash-es";
import { FC, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSupabaseCallback } from "../../../server/supabase/hooks";
import { Database } from "../../../server/supabase/types/database-definitions";
import { useDialog } from "../../../utils/hooks/use-dialog";
import { fromISO8601_DATE, toISO8601, toISO8601_DATE } from "../../../utils/iso8601";
import { UUID } from "../../../utils/uuid";
import yup from "../../../utils/yup";
import { CrudDataGrid } from "../../generic/components/crud-data-grid/crud-data-grid";
import {
  RelatedMoments,
  RelatedMomentsDialog,
} from "../../generic/components/crud-data-grid/related-moments-dialog";
import { Columns, Row, RowOptions } from "../../generic/components/crud-data-grid/types";
import { SupabaseAccountPersonFieldService } from "../server/supabase-account-person-field-service";
import {
  SupabaseEventService,
  SupabaseScheduleEventService,
} from "../server/supabase-event-service";
import { eventKeyValidator } from "../types";
import { ConfirmDeleteDialog } from "./confirm-delete-dialog";

const SOURCE_EDITING_WHITELIST = ["MANUAL", "WEB"];

type EventRow = Database["public"]["Views"]["events_with_related_moments_calendar"]["Row"] & {
  id: UUID;
  moments: { id: UUID; title: string }[];
};

export const EventCrudDataGrid: FC<{
  events: Database["public"]["Views"]["events_with_related_moments_calendar"]["Row"][];
}> = ({ events }) => {
  const { t } = useTranslation();
  const [deleteEvent, setDeleteEvent] = useState<{
    event: Omit<(typeof processedEvents)[0], "options">;
    resolve: (value: boolean | PromiseLike<boolean>) => void;
  }>();
  const dialog = useDialog<RelatedMoments>();

  const processedEvents: (EventRow & { options: RowOptions })[] = uniqWith(
    events,
    (currentVal, otherVal) => {
      return currentVal.key === otherVal.key;
    },
  ).map((datum) => ({
    ...datum,

    id: datum.id!,
    key: datum.key,
    moments: datum.moments as { id: UUID; title: string }[],
    options: {
      preventEditing: !includes(SOURCE_EDITING_WHITELIST, datum.source),
      preventDeletion: !includes(SOURCE_EDITING_WHITELIST, datum.source) || !isEmpty(datum.moments),
      preventEditingReason: t("Unable to edit, this event is managed automatically"),
    },
  }));

  const onUpdate = useSupabaseCallback(
    async ({ supabase }, event: EventRow) => {
      const scheduleEventService = new SupabaseScheduleEventService(supabase);
      let scheduleEventResp = await scheduleEventService.update(
        event.key!,

        { title: event.title! },
      );
      if (scheduleEventResp.status === 404) {
        scheduleEventResp = await scheduleEventService.insert({
          title: event.title!,

          event_key: event.key!,
        });
      }

      if (!scheduleEventResp.data || scheduleEventResp.error) {
        toast.error(t("Something went wrong"));
        return;
      }
      const { data, error } = await new SupabaseEventService(supabase).update(event.id, {
        date: event.date!,
        repeat: event.repeat,
      });

      if (!data || error) {
        toast.error(t("Something went wrong"));
        return;
      }
      toast.success(t("Saved"));
      return {
        ...event,
        ...data,
      };
    },
    [t],
  );

  const onAdd = useSupabaseCallback(
    async ({ supabase }, event: EventRow) => {
      const {
        data: dataSchedule,
        error: errorSchedule,
        status,
      } = await new SupabaseScheduleEventService(supabase).insert({
        event_key: event.key!,

        title: event.title!,
      });

      if (status === 409) {
        toast.error(t("This name has been used before. Please provide a unique tag name."));
        return;
      }
      if (!dataSchedule || errorSchedule) {
        toast.error(t("Something went wrong"));
        return;
      }
      const { data, error } = await new SupabaseEventService(supabase).insert({
        id: event.id,

        key: event.key!,

        source: event.source!,

        date: event.date!,
        repeat: event.repeat,
        number_of_repeats: event.repeat ? 100 : 0
      });
      if (!data || error) {
        toast.error(t("Something went wrong"));
        return;
      }
      await new SupabaseAccountPersonFieldService(supabase).insert({
        key: `event_${event.key!}`,

        display_name: event.title!,
        merge_sample: "December 25th",
        connection_merge_sample: "December 25th",
        merge_field: true,
        connection_merge_field: false,
        channel_merge_field: true,
        segmentation_field: false,
        allow_initial: true,
        allow_repeats: true,
        field_type: "DATE",
      });
      toast.success(t("Saved"));
      return {
        ...event,
        ...data,
      };
    },
    [t],
  );

  const deleteRecord = useSupabaseCallback(
    async ({ supabase }, event: EventRow) => {
      const { data, error } = await new SupabaseEventService(supabase).update(event.id, {
        deleted_at: toISO8601(new Date()),
      });

      if (!data || error) {
        toast.error(t("Something went wrong"));
        return;
      }
      toast.success(t("Deleted"));
      return data;
    },
    [t],
  );

  const columns: Columns<EventRow> = {
    title: {
      headerName: t("Event Title"),
      flex: 3,
      editable: true,
      valueGetter: (_, row) => row.title ?? "-",
    },
    key: {
      headerName: t("Key/Merge Tag"),
      flex: 1,
      editable: true,
      options: {
        validator: async (value): Promise<string | undefined> => {
          return await eventKeyValidator
            .validate(value)
            .then(() => undefined)
            .catch((err: yup.ValidationError) => err.errors.join("\n"));
        },
      },
    },
    date: {
      headerName: t("Date"),
      flex: 1,
      type: "date" as const,
      editable: true,
      valueGetter: (_, row) => (row.date ? fromISO8601_DATE(row.date) : null),
      valueSetter: (value, row) => ({
        ...row,
        date: typeof value === "string" ? toISO8601_DATE(new Date(value)) : toISO8601_DATE(value),
      }),
    },
    repeat: {
      headerName: t("Repeat"),
      flex: 1,
      type: "string",
      editable: true,
      valueFormatter: (value) => (value ? value : t("Does not repeat")),
    },
    moments: {
      headerName: t("Related Moments"),
      flex: 1,
      editable: false,
      renderCell: (params) => {
        const numMoments = params.row.moments?.length ?? 0;
        return (
          <Button
            data-analytics-id="calendar-event-table-related-moments"
            onClick={() =>
              dialog.handleOpen({ title: params.row.title || "", moments: params.row.moments })
            }
            disabled={numMoments === 0}
          >
            {t("{{count}} moments", { count: numMoments })}
          </Button>
        );
      },
    },
  };

  return (
    <>
      <CrudDataGrid<Row<EventRow>>
        initialRows={processedEvents}
        columns={columns}
        initialData={{
          title: "",

          date: toISO8601_DATE(new Date())!,
          key: "",
          source: "MANUAL",
          repeat: null,
        }}
        onUpdate={onUpdate}
        onAdd={onAdd}
        onDelete={(event) => {
          return new Promise<boolean>((resolve) => {
            // Save the arguments to resolve or reject the promise later
            setDeleteEvent({ resolve, event });
          });
        }}
        gridOverrides={{
          isCellEditable: (params) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const row: Row<EventRow> = params.row;
            if (row.options?.preventEditing) return false;
            // Only allow setting `key` on new fields or those without a value,
            // no editing of existing keys.
            if (params.colDef?.field === "key") return Boolean(row.isNew || isEmpty(row.key));
            return true;
          },
        }}
        modifyActions={(actions, row) => [
          ...actions,
          ...(row
            ? [
                <GridActionsCellItem
                  key={`timeline${row.id}`}
                  icon={
                    <Tooltip title={t("View Timeline")}>
                      <BlurLinearOutlined />
                    </Tooltip>
                  }
                  label="timeline"
                  onClick={() => {
                    row.key && window.open(`/timeline/${row.key}`, "_blank");
                  }}
                  disabled={row.moments ? row.moments.length === 0 : true}
                />,
              ]
            : []),
        ]}
      />
      <RelatedMomentsDialog subjectNamedInTitle="event" dialog={dialog} />
      {deleteEvent && (
        <ConfirmDeleteDialog
          event={deleteEvent.event}
          onClose={() => {
            deleteEvent.resolve(false);
            setDeleteEvent(undefined);
          }}
          onConfirm={async () => {
            await deleteRecord(deleteEvent.event);
            deleteEvent.resolve(true);
            setDeleteEvent(undefined);
          }}
        />
      )}
    </>
  );
};
