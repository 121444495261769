import { Alert, LinearProgress } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "../../../modules/layout/components/page";
import { PageContent } from "../../../modules/layout/components/page-content";
import { PageHeader } from "../../../modules/layout/components/page-header";
import { PageHeading } from "../../../modules/layout/components/page-heading";
import { useUsedMomentsInfo } from "../../../modules/moment/hooks/use-moment";
import { useUsedProgramsInfo } from "../../../modules/moment/hooks/use-programs";
import { useUsedSegmentInfo } from "../../../modules/segment/server/use-segments";
import { AnalyticsSentMomentsTable } from "./components/analytics-sent-moments-table";

export const SentMomentsPage: FC = () => {
  const { t } = useTranslation();

  const segmentInfo = useUsedSegmentInfo();
  const programInfo = useUsedProgramsInfo();
  const momentInfo = useUsedMomentsInfo();

  return (
    <Page title="Sent Moments | ChangeEngine">
      <PageHeader>
        <PageHeading heading={t("Sent Moments")} />
      </PageHeader>
      <PageContent>
        {(segmentInfo.loading || programInfo.loading || momentInfo.loading) && <LinearProgress />}
        {(segmentInfo.error || programInfo.error || momentInfo.error) && (
          <Alert severity="error">{t("Unable to load data")}</Alert>
        )}
        {segmentInfo.data && programInfo.data && momentInfo.data && (
          <AnalyticsSentMomentsTable
            segments={segmentInfo.data}
            programs={programInfo.data}
            moments={momentInfo.data}
          />
        )}
      </PageContent>
    </Page>
  );
};

export default SentMomentsPage;
