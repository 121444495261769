import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode,
} from "@lexical/react/LexicalDecoratorBlockNode";
import { addClassNamesToElement } from "@lexical/utils";
import { DOMConversionMap, DOMExportOutput, ElementFormatType, LexicalNode, Spread } from "lexical";
import { InsertDividerCommand } from "../types/commands";
import { convertDividerElement, isDividerElement } from "./utils";

export type SerializedDividerNode = Spread<
  {
    type: "divider";
    divider: InsertDividerCommand;
  },
  SerializedDecoratorBlockNode
>;

export class DividerNode extends DecoratorBlockNode {
  __divider: InsertDividerCommand;

  constructor(divider: InsertDividerCommand, format?: ElementFormatType, key?: string) {
    super(format, key);
    this.__divider = divider;
  }

  decorate(): React.JSX.Element {
    return <></>;
  }

  static getType(): string {
    return "divider";
  }

  static clone(node: DividerNode): DividerNode {
    return new this(node.__divider, node.__format, node.__key);
  }

  createDOM(): HTMLElement {
    const dom = document.createElement("span");
    addClassNamesToElement(dom, "decorator-block-node");
    return dom;
  }

  exportDOM(): DOMExportOutput {
    const divider = document.createElement("hr");
    divider.setAttribute("data-divider-type", this.__divider?.type ?? "");
    return { element: divider };
  }

  getTextContent(): string {
    return "\n";
  }

  static importJSON(node: SerializedDividerNode): DividerNode {
    return new this(node.divider, node.format);
  }

  exportJSON(): SerializedDividerNode {
    return {
      type: "divider",
      divider: this.__divider,
      version: 1,
      format: this.__format,
    };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      hr: (node: HTMLElement) => {
        if (!isDividerElement(node)) return null;

        return {
          conversion: (n) => convertDividerElement(n, DividerNode),
          priority: 0,
        };
      },
    };
  }
}

export function $isDividerNode(node: LexicalNode | null | undefined): node is DividerNode {
  return node instanceof DividerNode;
}
