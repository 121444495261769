import DescriptionOutlined from "@mui/icons-material/DescriptionOutlined";
import { Box, Chip } from "@mui/material";
import { FC } from "react";

export const FileIconWithChip: FC = () => {
  return (
    <Box sx={{ position: "relative" }}>
      <DescriptionOutlined sx={{ fontSize: 120, color: "neutral.500" }} />
      <Chip
        label="PDF"
        size="small"
        color="primary"
        sx={{
          position: "absolute",
          bottom: 5,
          left: 5,
          fontSize: "0.7rem",
          backgroundColor: "primary.main",
        }}
      />
    </Box>
  );
};
