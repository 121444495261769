import { Box } from "@mui/material";
import { FC } from "react";
import { EmojiAvatar } from "../../../../../modules/moment/component/emoji/emoji-view";
import { useMomentAnalyticsContext } from "../../context/moment-stats-context";
import { Reaction } from "./types";

export const ReactionAvatar: FC<{ reaction?: Reaction }> = ({ reaction }) => {
  const { emojiCache } = useMomentAnalyticsContext();

  if (!reaction) return null;

  return (
    <Box width="100%" height="100%" display="flex" justifyContent="flex-start" alignItems="center">
      <EmojiAvatar
        emoji={reaction.emoji}
        emojiCacheEntry={emojiCache[reaction.emoji]}
        size="large"
      />
    </Box>
  );
};
