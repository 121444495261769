import { FC } from "react";
import { AccountDataGrid } from "../../modules/ce-admin/components/account-data-grid";
import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { CeAdminPageHeader } from "./ce-admin-page-header";

const CeAdminPage: FC = () => {
  return (
    <Page title="Adminstration | ChangeEngine">
      <PageHeader display="flex">
        <PageHeading heading={<CeAdminPageHeader />} subheading={"Manage Account Limits"} />
      </PageHeader>
      <PageContent>
        <AccountDataGrid />
      </PageContent>
    </Page>
  );
};

export default CeAdminPage;
