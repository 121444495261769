import { usePermissions } from "../../../server/auth/use-permissions";
import { useSupabaseLoader } from "../../../server/supabase/hooks";
import { UseSupabaseLoaderState } from "../../../server/supabase/hooks/types";
import {
  Program,
  programsOrder,
  SupabaseProgramService,
  SupabaseUsedProgramInfoService,
  UsedProgramInfo,
} from "../../programs/server/supabase-program-service";

export const usePrograms = (): UseSupabaseLoaderState<{
  editable: Program[];
  viewable: Program[];
}> => {
  const { ce_admin, super_admin, editable_program, read_only_program } = usePermissions();

  return useSupabaseLoader(
    async ({ supabase }) => {
      const { data } = await new SupabaseProgramService(supabase).getAll(null, {
        order: programsOrder,
      });

      if (!data) return { editable: [], viewable: [] };

      return {
        editable: data
          .filter((program) => program.visible || ce_admin)
          .filter(({ id }) => super_admin || editable_program.includes(id)),
        viewable: data
          .filter((program) => program.visible || ce_admin)
          .filter(
            ({ id }) => super_admin || [...editable_program, ...read_only_program].includes(id),
          ),
      };
    },
    [editable_program, read_only_program, ce_admin, super_admin],
  );
};

export function useUsedProgramsInfo(): ReturnType<typeof useSupabaseLoader<UsedProgramInfo[]>> {
  return useSupabaseLoader(async ({ supabase, account_id }) => {
    const { data, error } = await new SupabaseUsedProgramInfoService(supabase).getAll(account_id, {
      order: [{ column: "id" }],
    });

    if (error || !data) return [];
    return data;
  }, []);
}
