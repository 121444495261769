import { omit } from "lodash-es";
import { DependencyList, useCallback, useEffect } from "react";
import { useToken } from "../auth/use-token";

export type ApiResponse<T> =
  | {
      error: string;
      data: undefined;
      status: number;
    }
  | {
      error: undefined;
      data: T;
      status: number;
    };

export abstract class ApiService {
  abstract module: string;
  accessToken: string;

  constructor(accessToken: string) {
    this.accessToken = accessToken;
  }

  protected async request<T>(
    method: string,
    path: string,
    body?: unknown,
  ): Promise<ApiResponse<T>> {
    const response = await fetch(`/api/v1/${this.module}/${path}`, {
      method,
      body: body ? JSON.stringify(body) : null,
      headers: this.headers(),
    });
    const data = [204, 401, 403].includes(response.status)
      ? undefined
      : ((await response.json()) as T & { error: string });

    if (!response.ok) {
      return {
        error: data?.error || "Unknown error",
        data: undefined,
        status: response.status,
      };
    }

    return {
      error: undefined,
      data: omit(data, "error") as T,
      status: response.status,
    };
  }

  async get<T>(path: string): Promise<ApiResponse<T>> {
    return await this.request<T>("GET", path);
  }

  async put<T>(path: string, body?: unknown): Promise<ApiResponse<T>> {
    return await this.request<T>("PUT", path, body);
  }

  async post<T>(path: string, body?: unknown): Promise<ApiResponse<T>> {
    return await this.request<T>("POST", path, body);
  }

  async delete<T>(path: string): Promise<ApiResponse<T>> {
    return await this.request<T>("DELETE", path);
  }

  protected headers(): HeadersInit {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.accessToken}`,
    };
  }
}

export type ApiServiceCallback<T extends ApiService> = (arg0: {
  apiService: T;
}) => Promise<void> | void;

export function useApiService<T extends ApiService>(
  apiService: new (accessToken: string) => T,
  callback: ApiServiceCallback<T>,
  deps: DependencyList,
): void {
  const getToken = useToken();

  useEffect(
    () => {
      void (async () => {
        await callback({
          apiService: new apiService(await getToken()),
        });
      })().catch(console.error);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getToken, ...deps],
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useApiServiceCallback<T extends ApiService, U extends any[], V>(
  apiService: new (accessToken: string) => T,
  callback: (arg0: { apiService: T }, ...args: U) => Promise<V>,
  deps: DependencyList,
): (...args: U) => Promise<V> {
  const getToken = useToken();

  return useCallback(
    async (...args) => {
      return await callback(
        {
          apiService: new apiService(await getToken()),
        },
        ...args,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getToken, ...deps],
  );
}
