
// If the environment provides Pendo then it will provide
// the basic methods immediately from html and the advanced methods
// after loading the js without a rerender. If the environment does not have Pendo
// then no methods are defined ever

export type Pendo = pendo.Pendo;

export function usePendo(): Pendo | null {
  const pendo = window.pendo;
  if (typeof pendo === "undefined") {
    return null;
  }
  return pendo;
}
