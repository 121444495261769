import { $isTableCellNode, $isTableNode, $isTableRowNode } from "@lexical/table";
import { $isRootNode, LexicalNode } from "lexical";

export const $isInTableNode = (node: LexicalNode): boolean => {
  if ($isTableNode(node) || $isTableRowNode(node) || $isTableCellNode(node)) {
    return true;
  }

  const parent = node.getParent();
  if (!parent || $isRootNode(parent)) return false;

  return $isInTableNode(parent);
};
