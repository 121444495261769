import { Box, IconButton, TextField, useTheme } from "@mui/material";
import Fuse from "fuse.js";
import { ChangeEvent, ReactElement, SyntheticEvent, useContext, useState } from "react";
import { useSupabase } from "../../../../server/supabase/hooks";
import { X } from "../../../../styles/icons/x";
import { SidebarContext } from "../../../../utils/contexts/sidebar-context";
import {
    PopulatedProgram,
    SupabaseProgramService,
} from "../../../programs/server/supabase-program-service";
import { Item } from "./item";
import { Section } from "./section";

interface Props {
  onSelect: (e?: SyntheticEvent) => void;
}

export const ProgramsSelector = ({ onSelect }: Props): ReactElement => {
  const theme = useTheme();
  const [programs, setPrograms] = useState<PopulatedProgram[]>([]);
  const [search, setSearch] = useState<string>("");

  const { programsDirty, setProgramsDirty } = useContext(SidebarContext);
  useSupabase(
    async ({ supabase }) => {
      if (!programsDirty && programs.length > 0) return;

      const programService = new SupabaseProgramService(supabase);

      const { data } = await programService.getAllPopulated();

      const visiblePrograms = data?.filter((p) => p.visible);
      setPrograms(visiblePrograms ?? []);
      setProgramsDirty(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [programsDirty],
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
    setSearch(e.currentTarget.value);

  const fuse = new Fuse(programs, {
    keys: ["title"],
    threshold: 0.2,
  });

  const filteredPrograms = search ? fuse.search(search).map((result) => result.item) : programs;

  const programItems = [
    {
      title: "All Programs",
      onClick: { type: "href" as const, value: "/tabbed-programs" },
      href: "/tabbed-programs",
    },
    ...filteredPrograms.map(({ title }) => ({
      title: title || "",
      onClick: { type: "href" as const, value: `/tabbed-programs/${title!}` },
    })),
  ];
  return (
    <>
      <TextField
        sx={{ px: 4, py: 3, width: "100%" }}
        size="small"
        value={search}
        onChange={handleChange}
        inputProps={{ "data-testid": "programFilter" }}
        placeholder="Search"
        InputProps={{
          sx: { backgroundColor: "background.paper" },
          endAdornment: (
            <IconButton
              data-analytics-id="sidebar-programs-search-cancel"
              edge="end"
              onClick={() => setSearch("")}
              data-testid="cancelButton"
            >
              <X data-testid="XIcon" fontSize="small" />
            </IconButton>
          ),
        }}
      />
      <Section>
        <Box sx={{ "--nav-item-color": theme.palette.text.sidebar }} onClick={onSelect}>
          {programItems.map((item) => (
            <Item key={item.title} {...item} />
          ))}
        </Box>
      </Section>
    </>
  );
};
