import Star from "@mui/icons-material/Star";
import StarBorder from "@mui/icons-material/StarBorder";
import { Tooltip } from "@mui/material";
import { GridActionsCellItem, useGridApiContext } from "@mui/x-data-grid-pro";
import { FC, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSupabaseCallback } from "../../../../server/supabase/hooks";
import { SupabaseFavoriteSegmentService } from "../../server/supabase-segment-service";
import { SegmentRow } from "./types";

export const FavoriteButton: FC<{ row: SegmentRow }> = ({ row }) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const [disabled, setDisabled] = useState<boolean>(false);

  const favoriteSegment = useSupabaseCallback(
    async ({ supabase }, segment: SegmentRow) => {
      setDisabled(true);
      const { data, error } = await new SupabaseFavoriteSegmentService(supabase).insert({
        segment_id: segment.id,
      });

      setDisabled(false);
      if (!data || error) {
        toast.error(t("Something went wrong"));
        return;
      }

      toast.success(t("Favorited {{name}}", { name: segment.name }));
      apiRef.current.updateRows([{ id: segment.id, is_favorite: true }]);
    },
    [apiRef, t],
  );

  const unfavoriteSegment = useSupabaseCallback(
    async ({ account_id, supabase }, segment: SegmentRow) => {
      setDisabled(true);
      const error = await new SupabaseFavoriteSegmentService(supabase).unfavorite(
        account_id,
        segment.id,
      );

      setDisabled(false);
      if (error.error) {
        toast.error(t("Something went wrong"));
        return;
      }

      toast.success(t("Unfavorited {{name}}", { name: segment.name }));
      apiRef.current.updateRows([{ id: segment.id, is_favorite: false }]);
    },
    [apiRef, t],
  );

  const action = row.is_favorite ? "unfavorite" : "favorite";
  return (
    <GridActionsCellItem
      key={`favorite${row.id}`}
      disabled={disabled}
      icon={
        <Tooltip title={t(`Click to ${action} Segment`)}>
          {row.is_favorite ? <Star /> : <StarBorder />}
        </Tooltip>
      }
      label={action}
      onClick={() => {
        row.is_favorite
          ? unfavoriteSegment(row).catch(console.error)
          : favoriteSegment(row).catch(console.error);
      }}
    />
  );
};
