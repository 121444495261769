import { Box, TextField } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import { useDebouncedEffect } from "@react-hookz/web";
import { isEmpty } from "lodash-es";
import { FC, useState } from "react";

export const SentMomentsTableToolbar: FC = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <Box sx={{ flexGrow: 1 }} />
      <SearchBar />
    </GridToolbarContainer>
  );
};

const SearchBar: FC = () => {
  const [value, setValue] = useState("");
  const context = useGridApiContext();

  const { upsertFilterItem, deleteFilterItem } = context.current;

  useDebouncedEffect(
    () => {
      if (isEmpty(value)) {
        deleteFilterItem({ field: "moment_title", operator: "contains" });
        return;
      }

      upsertFilterItem({
        field: "moment_title",
        operator: "contains",
        value,
      });
    },
    [value, upsertFilterItem, deleteFilterItem],
    500,
  );

  return (
    <TextField
      variant="standard"
      placeholder="Search"
      onChange={(e) => setValue(e.target.value)}
      defaultValue={value}
    />
  );
};
