import { Alert, Button } from "@mui/material";
import { DataGridPro, DataGridProProps, GridColDef, GridLogicOperator } from "@mui/x-data-grid-pro";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMomentAnalyticsContext } from "../context/moment-stats-context";

import { TransformedGroupedMomentAnalytics } from "../hooks/utils";
import { AnalyticsSendsTableExpandedRow } from "./expanded-row";

type AnalyticsSendsTableProps<T extends TransformedGroupedMomentAnalytics> = DataGridProProps<T> & {
  columns: GridColDef<T>[];
};

export const AnalyticsSendsTable = <T extends TransformedGroupedMomentAnalytics>(
  props: AnalyticsSendsTableProps<T>,
): ReactElement => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const { statsDataSource } = useMomentAnalyticsContext();

  if (error) {
    return (
      <Alert
        severity="error"
        action={
          <Button
            data-analytics-id="failed-moment-analytics-refresh-page"
            variant="text"
            color="inherit"
            onClick={() => window.location.reload()}
          >
            {t("Reload page")}
          </Button>
        }
      >
        {t(
          "We couldn't load any analytics data for this Moment. " +
            "Try reloading the page - if that doesn't work, please " +
            "contact support@changeengine.com",
        )}
      </Alert>
    );
  }

  return (
    <DataGridPro<T>
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
      initialState={{
        pagination: {
          paginationModel: { pageSize: 50, page: 0 },
          rowCount: 0,
        },
        sorting: { sortModel: [{ field: "min_timestamp", sort: "desc" }] },
      }}
      unstable_dataSource={statsDataSource}
      unstable_onDataSourceError={(err) => {
        console.log(err);
        setError(true);
      }}
      disableRowSelectionOnClick
      getDetailPanelContent={({ row }) => (
        <AnalyticsSendsTableExpandedRow channel={row.channel_name} stats={row.rawData.stats} />
      )}
      getDetailPanelHeight={() => "auto"}
      getRowHeight={() => "auto"}
      slotProps={{
        filterPanel: { logicOperators: [GridLogicOperator.And] },
        loadingOverlay: {
          variant: "skeleton",
          noRowsVariant: "skeleton",
        },
      }}
      {...props}
    />
  );
};
