import { useAuth0 } from "@auth0/auth0-react";
import { useApiLoader, UseApiLoaderResponse } from "../../server/api/hooks/use-api-loader";
import { OrgUser } from "../../server/supabase/hooks/types";
import { useGetDesignHuddleAccessToken } from "./use-design-huddle-access-token";

/**
 * this hook provides the user with user-level access to design huddle,
 * and access to either a specific project, or all their organizations projects
 */
export function useDesignHuddle(projects: string[]): UseApiLoaderResponse<{ success: boolean }> {
  const { user } = useAuth0<OrgUser>();
  const getAccessToken = useGetDesignHuddleAccessToken();

  return useApiLoader(async () => {
    if (!user) return { success: false };
    const token = await getAccessToken(projects);
    DSHDLib.configure({
      access_token: token,
      visitor: false,
    });

    return { success: true };
  }, [projects, getAccessToken, user]);
}
