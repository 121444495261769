import { useCallback } from "react";
import { useToken } from "../../../server/auth/use-token";
import { AssetResult } from "../../assets/hooks/use-upsert-asset";
import { useGetDesignHuddleAccessToken } from "../../design-huddle/use-design-huddle-access-token";
import { copyImage, getDesignHuddleProjectRenderFromPageId } from "../../design-huddle/utils";
import { SaveAssetHandler } from "../types";

export const useSaveAssetCallback = ({
  onSave,
  title,
  projectId,
}: {
  title: string;
  onSave?: SaveAssetHandler;
  projectId: string;
}): (() => Promise<AssetResult | undefined>) => {
  const getToken = useToken();
  const getAccessToken = useGetDesignHuddleAccessToken();

  return useCallback(() => {
    return (async () => {
      const url = await getDesignHuddleProjectRenderFromPageId({
        getToken,
        accessToken: await getAccessToken([projectId]),
        projectId,
        format: "png",
        filename: projectId,
      });

      const response = await copyImage(getToken, url, `asset:thumbnail:${projectId}:${Date.now()}`);

      return response.success
        ? await onSave?.({ title, thumbnailUrl: response.url, projectId })
        : await onSave?.({ title, projectId });
    })();
  }, [getToken, getAccessToken, onSave, projectId, title]);
};
