import Close from "@mui/icons-material/Close";
import ControlPoint from "@mui/icons-material/ControlPoint";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC, ReactNode, useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useToken } from "../../server/auth/use-token";
import { ImagesService } from "../../server/cloudflare/images-service";
import { NameSpaceURL, UUID, uuidv4, uuidv5 } from "../../utils/uuid";
import { FilePicker } from "../generic/components/file-picker/file-picker";
import { AssetResult } from "./hooks/use-upsert-asset";
import { Asset } from "./server/digital-asset-service";
import { UploadFileFailedToast } from "./upload-file-failed-toast";

const UploadFileType = {
  image: [".png", ".gif", ".jpg", ".jpeg"],
  pdf: [".pdf"],
};

export type FileUploadProps = {
  open: boolean;
  heading: string;
  allowedFileTypes?: (keyof typeof UploadFileType)[];
  onClose: () => void;
  onCancel: () => void;
  onOpen: () => void;
  onChange?: () => void;
  onSave: (asset: {
    title: string;
    thumbnailUrl?: string | null;
    contentType: string;
    projectId?: string | null;
    pageId?: string | null;
    mimeType?: string | null;
    sourceDigitalAssetId?: UUID | null;
    publicUrl?: string | null;
  }) => Promise<AssetResult>;
  helperText?: string;
};

export interface UploadState {
  fileUrl: string | null;
  fileTitle: string | null;
  fileType: string | null;
}

export const FileUpload: FC<
  FileUploadProps & {
    renderActions: (actions: {
      uploadState: UploadState;
      handleSave: (url?: string) => Promise<boolean>;
      handleClose: () => void;
      asset: {
        public_url: string;
        thumbnail_url: string;
        mime_type?: string | null;
      } | null;
    }) => ReactNode;
    onSuccess: (fileTitle: string, asset: Asset) => void;
  }
> = ({
  open,
  heading,
  allowedFileTypes = ["image", "pdf"],
  onClose,
  onCancel,
  onOpen,
  onChange,
  onSave,
  renderActions,
  onSuccess,
  helperText,
}) => {
  const [currentValue, setCurrentValue] = useState<string | null>(null);
  const [fileTitle, setFileTitle] = useState<string | null>(null);
  const [fileType, setFileType] = useState<string | null>(null);
  const [showFileTypeError, setShowFileTypeError] = useState<boolean>(false);
  const [fileTitleError, setFileTitleError] = useState<string | null>(null);
  const getToken = useToken();
  const { t } = useTranslation();
  const supportedFormats = [
    ...(allowedFileTypes.includes("pdf") ? ["pdf"] : []),
    ...(allowedFileTypes.includes("image") ? ["png", "gif", "jpg"] : []),
  ].join(", ");

  const handleCancel = useCallback((): void => {
    setCurrentValue(null);
    setFileTitle(null);
    setFileTitleError(null);

    onCancel();
    setShowFileTypeError(false);
  }, [onCancel]);

  const handleSave = useCallback(
    async (url?: string): Promise<boolean> => {
      if (!currentValue || !fileTitle) return false;

      const uploadUrl = url || currentValue;
      const { success, asset, error } = await onSave({
        title: fileTitle,
        mimeType: fileType,
        publicUrl: uploadUrl,
        contentType: "Uploaded File",
        ...(fileType?.startsWith("image/") ? { thumbnailUrl: uploadUrl } : {}),
      });

      if (!success) {
        if (error?.key === "name") {
          setFileTitleError(error.message);
        } else {
          toast.error(error ? error.message : t("Failed to upload file. Please try again"));
        }
        return false;
      }

      onSuccess(fileTitle, asset);
      return true;
    },
    [currentValue, fileTitle, onSave, fileType, t, onSuccess],
  );

  const handleClose = useCallback((): void => {
    onChange?.();
    setCurrentValue(null);
    setFileTitle(null);

    onClose();
    setShowFileTypeError(false);
  }, [onChange, onClose]);

  const handleUpload = useCallback(
    async (file: File) => {
      setShowFileTypeError(false);

      const response = await new ImagesService().upload(
        file,
        uuidv5(NameSpaceURL, uuidv4()),
        await getToken(),
        file.type,
      );

      if (!response.success) {
        handleCancel();
        toast.custom(
          <UploadFileFailedToast
            filename={file.name}
            onClick={() => {
              toast.remove();
              onOpen();
            }}
          />,
        );

        console.error("Upload failed");
        return Promise.reject(new Error("Upload failed"));
      }

      setFileTitle(file.name);
      setFileType(file.type);

      setCurrentValue(response.url);
      return Promise.resolve(file);
    },
    [getToken, handleCancel, onOpen],
  );

  const handleRemoveUpload = useCallback(() => {
    setFileTitle(null);
    setFileType(null);
    setCurrentValue(null);
  }, []);

  const handleSetFileTitle = useCallback((newTitle: string) => {
    setFileTitle(newTitle);
    setFileTitleError(null);
  }, []);

  return (
    <Dialog open={open} onClose={handleCancel} slotProps={{ paper: { sx: { width: "600px" } } }}>
      <DialogTitle display="flex" justifyContent="space-between" component="div">
        <Typography variant="h6" flexGrow={1} mt={1}>
          {heading}
        </Typography>
        <IconButton
          onClick={handleCancel}
          data-analytics-id="upload-file-modal-close"
          title={t("Close")}
        >
          <Close data-testid="CloseIcon" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body2">
            {t(
              "Please note that files uploaded to the Content Library will be accessible by anyone with a link to the file.",
            )}
          </Typography>
          <FilePicker
            value={currentValue}
            handleUpload={handleUpload}
            accept={{
              ...(allowedFileTypes.includes("image") ? { "image/*": UploadFileType.image } : {}),
              ...(allowedFileTypes.includes("pdf")
                ? { "application/pdf": UploadFileType.pdf }
                : {}),
            }}
            text={{
              remove: t("Remove file"),
              uploadError: t("Only one file can be uploaded at a time"),
            }}
            content={
              <Box sx={{ p: 2 }}>
                <Typography variant="h1" sx={{ textAlign: "center" }}>
                  <ControlPoint color="primary" fontSize="large" />
                </Typography>
                <Typography variant="body1" sx={{ textAlign: "center" }}>
                  {t("Drag and drop a file here, or upload from your computer")}
                </Typography>
                <Typography variant="body2" sx={{ textAlign: "center" }} color="text.secondary">
                  {t("Supported formats: {{supportedFormats}}", { supportedFormats })}
                </Typography>
              </Box>
            }
            handleRemove={handleRemoveUpload}
            fileTypeError={() => {
              setShowFileTypeError(true);
            }}
          />
          {showFileTypeError && (
            <Alert severity="error">
              {t(
                "This file type is not currently supported. Supported formats: {{supportedFormats}}",
                { supportedFormats },
              )}
            </Alert>
          )}
          <TextField
            value={fileTitle ?? ""}
            id="file-title"
            placeholder={t("Title")}
            onChange={(e) => handleSetFileTitle(e.target.value)}
            error={Boolean(fileTitleError)}
            helperText={fileTitleError}
          ></TextField>
        </Stack>
        {helperText && (
          <Box sx={{ pt: 2 }}>
            <Typography variant="caption" color="text.secondary">
              {helperText}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyItems: "right", px: 2, mb: 2 }}>
        <Button onClick={handleCancel} data-analytics-id="upload-file-modal-cancel">
          {t("Cancel")}
        </Button>
        {renderActions({
          uploadState: {
            fileUrl: currentValue,
            fileTitle,
            fileType,
          },
          handleSave,
          handleClose,
          asset: currentValue
            ? {
                public_url: currentValue,
                thumbnail_url: currentValue,
                mime_type: fileType,
              }
            : null,
        })}
      </DialogActions>
    </Dialog>
  );
};
