import { atomFamily, atomWithDefault, loadable } from "jotai/utils";
import { isEqual } from "lodash-es";
import { Orientation } from "unsplash-js";
import { CustomTemplates } from "../../../pages/create-content/use-custom-templates";
import { toSlug } from "../../../utils/create-slug";
import { dhTemplateConfigFamily } from "../../content-previews/store/dh-template-config-family";
import { debugLabel } from "../../generate/store";
import { multiformatAtom } from "./multiformat-atom";
import { previewFamily } from "./preview-family";
import { previewUrlFamily } from "./preview-url-family";
import { Layout, TemplatePreview } from "./types";

const orientation = (layout: Layout | undefined): Orientation => {
  return layout?.image?.orientation &&
    ["landscape", "portrait", "squarish"].includes(layout.image.orientation.toLowerCase())
    ? (layout.image.orientation.toLowerCase() as Orientation)
    : "landscape";
};

export const previewsFamily = atomFamily(
  ({
    contentType,
    answers,
    customTemplates,
    useSelectedLogo,
  }: {
    contentType: string;
    answers: { answer: string }[];
    customTemplates: CustomTemplates | null;
    useSelectedLogo: boolean;
  }) =>
    debugLabel(
      `previewsFamily(${contentType}, ${answers[0].answer})`,
      atomWithDefault((get) => {
        const templateConfig = get(dhTemplateConfigFamily(contentType));
        const layouts = get(multiformatAtom)?.breakdown[contentType];
        const formatId = toSlug(contentType).replace(/-/g, "_");

        if (!templateConfig || !layouts) return;

        let customLayouts: TemplatePreview[] = [];
        if (
          customTemplates &&
          customTemplates[contentType] &&
          customTemplates[contentType].length > 0
        ) {
          customLayouts = customTemplates[contentType].flatMap((customTemplate) =>
            Array.from({ length: Math.min(customTemplate.page_count, layouts.length) }, (_, i) => {
              return {
                imageType: layouts[i].image?.type ?? "Photo",
                formatId,
                orientation: orientation(layouts[i]),
                preview: get(
                  loadable(
                    previewFamily({
                      formatId,
                      answers,
                      imgNum: layouts[i].dh_image_number?.toString(),
                      templateConfig: {
                        ...templateConfig,
                        templateCode: customTemplate.template.template_code ?? "",
                      },
                      imageType: layouts[i].image?.type ?? "Photo",
                      imageIndex: layouts[i].image?.index ?? 0,
                      orientation: orientation(layouts[i]),
                      useSelectedLogo,
                    }),
                  ),
                ),
                url: get(
                  loadable(
                    previewUrlFamily({
                      formatId,
                      answers,
                      imgNum: layouts[i].dh_image_number?.toString(),
                      templateConfig: {
                        ...templateConfig,
                        templateCode: customTemplate.template.template_code ?? "",
                      },
                      imageType: layouts[i].image?.type ?? "Photo",
                      imageIndex: layouts[i].image?.index ?? 0,
                      orientation: orientation(layouts[i]),
                      useSelectedLogo,
                    }),
                  ),
                ),
              };
            }),
          );
        }

        const previews = [
          ...customLayouts,
          ...layouts.map((layout) => ({
            imageType: layout.image?.type ?? "Photo",
            formatId,
            orientation: orientation(layout),
            preview: get(
              loadable(
                previewFamily({
                  formatId,
                  answers,
                  imgNum: layout.dh_image_number?.toString(),
                  templateConfig,
                  imageType: layout.image?.type ?? "Photo",
                  imageIndex: layout.image?.index ?? 0,
                  orientation: orientation(layout),
                  useSelectedLogo,
                }),
              ),
            ),
            url: get(
              loadable(
                previewUrlFamily({
                  formatId,
                  answers,
                  imgNum: layout.dh_image_number?.toString(),
                  templateConfig,
                  imageType: layout.image?.type ?? "Photo",
                  imageIndex: layout.image?.index ?? 0,
                  orientation: orientation(layout),
                  useSelectedLogo,
                }),
              ),
            ),
          })),
        ];
        return previews.filter((p) => p.preview.state !== "hasError" && p.url.state !== "hasError");
      }),
    ),
  isEqual,
);
