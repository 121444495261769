import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { UUID } from "../../../../../utils/uuid";
import { MomentFormMoment } from "../../../../moment/types/moment-form-moment";
import { Segment } from "../../../types";
import { SegmentFormSaveErrors } from "./segment-modal-form-wrapper";

export const UnarchiveWithNewNameModal: FC<{
  open: boolean;
  onSave: (segment: Segment | null) => Promise<SegmentFormSaveErrors | undefined>;
  onCancel: () => void;
  segmentId: UUID | undefined;
  segmentName: string | null | undefined;
}> = ({ open, onSave, onCancel, segmentId, segmentName }) => {
  const [saving, setSaving] = useState(false);
  const { t } = useTranslation();
  const methods = useForm<Pick<MomentFormMoment, "segment">>({
    defaultValues: { segment: { name: segmentName ?? "", id: segmentId } },
    context: {},
  });

  const { setError, control } = methods;

  const handleSave = useCallback(
    async (segment: Pick<MomentFormMoment, "segment">): Promise<void> => {
      setSaving(true);
      const errs = await onSave(segment.segment);
      if (errs) {
        Object.entries(errs).forEach(([key, value]) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setError(key, {
            type: "manual",
            message: value,
          });
        });
      }
      setSaving(false);
    },
    [onSave, setError],
  );

  return (
    <Dialog open={open} fullWidth>
      <FormProvider {...methods}>
        <DialogTitle>{t("Update Segment Name and Unarchive")}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            {t(
              "A Segment with this name already exists. Please update the Segment name below to a unique value.",
            )}
          </DialogContentText>
          <Controller
            name="segment.name"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                label={t("Segment name")}
                fullWidth
                value={field.value}
                helperText={
                  fieldState.error?.message ? t("This Segment name is already in use") : ""
                }
                error={Boolean(fieldState.error)}
                variant="standard"
                onChange={field.onChange}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-analytics-id="segment-grid-unarchive-dialog-cancel"
            onClick={onCancel}
            disabled={saving}
          >
            {t("Cancel")}
          </Button>
          <Button
            data-analytics-id="segment-grid-unarchive-dialog-rename-and-unarchive"
            variant="contained"
            type="submit"
            loading={saving}
            onClick={() => {
              void methods
                .handleSubmit((segment) => handleSave(segment))()
                .catch(console.error);
            }}
          >
            {t("Rename & Unarchive")}
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};
