import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSupabaseCallback } from "../../../server/supabase/hooks";
import { UUID } from "../../../utils/uuid";
import { Asset, AssetUpsert, SupabaseDigitalAssetService } from "../server/digital-asset-service";

export type AssetResult =
  | { success: true; asset: Asset; error?: undefined }
  | {
      success: false;
      error: { key: "name" | null; message: string } | undefined;
      asset?: undefined;
    };

export const useUpsertAssetCallback = (): ((
  update: AssetUpsert,
) => Promise<AssetResult | undefined>) => {
  const { t } = useTranslation();
  return useSupabaseCallback(
    async ({ supabase }, asset: AssetUpsert) => {
      const result = await new SupabaseDigitalAssetService(supabase).upsert(asset);
      if (!result || result?.error) {
        return {
          success: false,
          error: result?.error.message.includes("idx_unique_title_per_account_on_digital_assets")
            ? { key: "name", message: t("Asset title must be unique") }
            : undefined,
        };
      }

      return { success: true, asset: result.data };
    },
    [t],
  );
};

export const useCreateAssetCallback = (): (({
  title,
  thumbnailUrl,
  contentType,
  projectId,
  pageId,
  mimeType,
  sourceDigitalAssetId,
  publicUrl,
}: {
  title: string;
  thumbnailUrl?: string | null;
  contentType: string;
  projectId?: string | null;
  pageId?: string | null;
  mimeType?: string | null;
  sourceDigitalAssetId?: UUID | null;
  publicUrl?: string | null;
}) => Promise<AssetResult>) => {
  const upsert = useUpsertAssetCallback();
  return useCallback(
    async ({
      title,
      thumbnailUrl,
      contentType,
      projectId,
      pageId,
      mimeType,
      sourceDigitalAssetId,
      publicUrl,
    }) => {
      return (
        (await upsert({
          project_id: projectId,
          title,
          content_type: contentType,
          page_id: pageId,
          thumbnail_url: thumbnailUrl,
          mime_type: mimeType,
          source_digital_asset_id: sourceDigitalAssetId,
          public_url: publicUrl,
        })) ?? { success: false, error: undefined }
      );
    },
    [upsert],
  );
};

export const useUpdateAssetCallback = (): (({
  asset,
  title,
  thumbnailUrl,
  projectId,
  publicUrl,
}: {
  asset: Asset;
  title?: string;
  thumbnailUrl?: string;
  projectId?: string | null;
  publicUrl?: string | null;
}) => Promise<AssetResult>) => {
  const upsert = useUpsertAssetCallback();
  return useCallback(
    async ({ asset, title, thumbnailUrl, projectId, publicUrl }) => {
      return (
        (await upsert({
          ...asset,
          title: title ?? asset.title,
          thumbnail_url: thumbnailUrl ?? asset.thumbnail_url,
          project_id: projectId ?? asset.project_id,
          page_id: asset.page_id,
          public_url: publicUrl ?? asset.public_url,
        })) ?? { success: false, error: undefined }
      );
    },
    [upsert],
  );
};
