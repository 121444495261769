import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode,
} from "@lexical/react/LexicalDecoratorBlockNode";
import { addClassNamesToElement } from "@lexical/utils";
import {
  DOMConversionMap,
  DOMExportOutput,
  ElementFormatType,
  LexicalNode,
  NodeKey,
  Spread,
} from "lexical";
import { convertImageElement, isImageElement } from "./utils";

export type SerializedImageNode =
  | Spread<
      {
        version: 1;
        type: "image";
        url: string;
        dimensions?: { height: number; width: number };
      },
      SerializedDecoratorBlockNode
    >
  | Spread<
      {
        version: 2;
        type: "image";
        url: string;
        proportionOfMaxWidth: number;
        aspect: number;
        href?: string;
      },
      SerializedDecoratorBlockNode
    >;

export type ImageNodeDimensions = { height: number; width: number };

export class ImageNode extends DecoratorBlockNode {
  __url: string;
  __proportionOfMaxWidth: number;
  __aspect: number;
  __href: string | undefined;

  constructor(
    url: string,
    proportionOfMaxWidth: number,
    aspect: number,
    format?: ElementFormatType,
    href?: string,
    key?: NodeKey,
  ) {
    super(format, key);
    this.__url = url;
    this.__proportionOfMaxWidth = proportionOfMaxWidth;
    this.__aspect = aspect;
    this.__href = href;
  }

  static getType(): string {
    return "image";
  }

  static clone(node: ImageNode): ImageNode {
    return new this(
      node.__url,
      node.__proportionOfMaxWidth,
      node.__aspect,
      node.__format,
      node.__href,
      node.__key,
    );
  }

  static importJSON(serializedNode: SerializedImageNode): ImageNode {
    if (serializedNode.version === 1) {
      return new this(
        serializedNode.url,
        Math.min(1, (serializedNode.dimensions?.width ?? 600) / 600),
        (serializedNode.dimensions?.height ?? 400) / (serializedNode.dimensions?.width ?? 600),
        serializedNode.format,
      );
    }
    return new this(
      serializedNode.url,
      serializedNode.proportionOfMaxWidth,
      serializedNode.aspect,
      serializedNode.format,
      serializedNode.href,
    );
  }

  static importDOM(): DOMConversionMap | null {
    return {
      img: (imageNode: HTMLElement) => {
        if (!isImageElement(imageNode)) return null;

        return {
          conversion: (n) => convertImageElement(n, ImageNode),
          priority: 0,
        };
      },
    };
  }

  setNewUrl(url: string): void {
    const writable = this.getWritable();
    writable.__url = url;
  }

  setHref(href?: string): void {
    const writable = this.getWritable();
    writable.__href = href;
  }

  getHref(): string | undefined {
    return this.__href;
  }

  setSize(proportionOfMaxWidth: number, aspect: number): void {
    const writable = this.getWritable();
    writable.__proportionOfMaxWidth = proportionOfMaxWidth;
    writable.__aspect = aspect;
  }

  getAspect(): number {
    return this.__aspect;
  }

  exportJSON(): SerializedImageNode {
    return {
      type: "image",
      version: 2,
      url: this.__url,
      proportionOfMaxWidth: this.__proportionOfMaxWidth,
      aspect: this.__aspect,
      format: this.__format,
      href: this.__href,
    };
  }

  decorate(): React.JSX.Element {
    return <></>;
  }

  createDOM(): HTMLElement {
    const dom = document.createElement("div");
    addClassNamesToElement(dom, "decorator-block-node");
    return dom;
  }

  exportDOM(): DOMExportOutput {
    const dom = document.createElement("img");
    dom.src = this.__url;
    dom.style.width = `${(this.__proportionOfMaxWidth ?? 1) * 100}%`;
    dom.style.aspectRatio = `${this.__aspect ?? 1}`;
    dom.setAttribute("data-node-type", "image-node");
    return {
      element: dom,
    };
  }
}

export function $isImageNode(node: LexicalNode | null | undefined): node is ImageNode {
  return node instanceof ImageNode;
}
