import { yupResolver } from "@hookform/resolvers/yup";
import Close from "@mui/icons-material/Close";
import { Button, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { FC, JSX, useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { uuidv4 } from "../../../../../utils/uuid";
import { MomentFormMoment } from "../../../../moment/types/moment-form-moment";
import { Segment, makeDefaultSegment } from "../../../types";
import { segmentFormSchema } from "../../../types/segment-form-schema";

export type SegmentFormSaveErrors = {
  "segment.name"?: string;
};

export const SegmentModalFormWrapper: FC<{
  onSave: (
    segment: Segment | null,
    onlyNameEdited: boolean,
  ) => Promise<SegmentFormSaveErrors | undefined>;
  onCancel: () => void;
  defaultSegmentManager?: boolean;
  initialValues?: Pick<MomentFormMoment, "segment"> & { segment: { name: string } };
  isRecipientType: boolean;
  disableSave?: boolean;
  children?: JSX.Element;
  showButtons?: boolean;
}> = ({
  onSave,
  onCancel,
  defaultSegmentManager = false,
  initialValues,
  isRecipientType,
  children,
  showButtons = true,
}) => {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const methods = useForm({
    defaultValues: initialValues ?? {
      segment: { ...makeDefaultSegment(uuidv4()), is_recipient_type: isRecipientType },
    },
    resolver: defaultSegmentManager ? undefined : yupResolver(segmentFormSchema),
    context: {},
  });

  const {
    formState: { dirtyFields },
  } = methods;

  const onlyNameEdited = useCallback(() => {
    return !Object.keys(dirtyFields.segment ?? {}).some((key) => key !== "name");
  }, [dirtyFields]);

  const { setError } = methods;

  const handleSave = useCallback(
    async (segment: { segment: Partial<MomentFormMoment["segment"]> }): Promise<void> => {
      setSaving(true);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // TODO: Really should fix this; the is no guarantee this is a fully valid segment
      const errs = await onSave(segment.segment, onlyNameEdited());
      if (errs) {
        Object.entries(errs).forEach(([key, value]) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setError(key, {
            type: "manual",
            message: value,
          });
        });
      }
      setSaving(false);
    },
    [onSave, setError, onlyNameEdited],
  );

  return (
    <FormProvider {...methods}>
      <DialogTitle>
        {defaultSegmentManager ? t("Set default segment filters") : t("Segment Builder")}
      </DialogTitle>
      <IconButton
        data-analytics-id="segment-modal-form-close"
        aria-label="close"
        onClick={onCancel}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <Close data-testid="CloseIcon" />
      </IconButton>
      <DialogContent>{children}</DialogContent>
      {showButtons && (
        <DialogActions>
          <Button
            data-analytics-id="segment-modal-form-cancel"
            onClick={onCancel}
            disabled={saving}
            color="warning"
          >
            {t("Cancel")}
          </Button>
          <Button
            data-analytics-id="segment-modal-form-save"
            variant="contained"
            type="submit"
            loading={saving}
            onClick={() => {
              void methods
                .handleSubmit((x) => handleSave(x))()
                .catch(console.error);
            }}
          >
            {t("Save")}
          </Button>
        </DialogActions>
      )}
    </FormProvider>
  );
};
