import { groupBy, keyBy, mapValues, orderBy, partition } from "lodash-es";
import { capitalizeEachWord } from "../../utils/capitalize";
import { MergeTagV1, MergeTagV2, Tags } from "../chat-moment/components/chat-editor/token-picker";
import { ToTarget } from "../to/use-to-target";
import { TagGroup } from "./components/merge-tag-lookup";

export const sortTags = <T extends MergeTagV2>(
  tags: T[],
  activeSection?: "recipient" | "subject",
  val?: string,
): T[] => {
  return orderBy(tags, [
    (x) => x.category_key !== activeSection,
    (x) => x.category_key,
    (x) => val && (x.field_name.toLowerCase().startsWith(val.toLowerCase()) ? 0 : 1),
    (x) => x.field_name,
  ]);
};

export const groupTags = (
  tags: MergeTagV2[],
  { toTarget, target_connection_type, isManagerConnection, notify_channel }: ToTarget,
): TagGroup => {
  const [sharedTags, personSpecificTags] = partition(tags, (x) => x.category_key === null);

  let tagGroup: TagGroup = {};
  if (isManagerConnection) {
    tagGroup = {
      ...tagGroup,
      recipient: personSpecificTags
        .filter((x) => x.category_key && ["manager", "connection"].includes(x.category_key))
        .map((x) => ({
          ...x,
          category_key: "recipient",
          id: x.id.replace(
            `${x.category_key === "connection" ? target_connection_type : "manager"}.`,
            "recipient.",
          ),
        })),
    };
  }
  if (toTarget === "employee") {
    tagGroup = {
      ...tagGroup,
      recipient: personSpecificTags.map((x) => {
        if (x.category_key !== "subject") return x;

        return {
          ...x,
          category_key: "recipient",
          category_name: "Recipient",
          id: x.id.replace("subject.", "recipient."),
        };
      }),
    };
  }
  if (["notify", "manager", "connection"].includes(toTarget) || notify_channel) {
    tagGroup = {
      ...tagGroup,
      subject: personSpecificTags,
    };
  }

  const [customEvents, misc] = partition(sharedTags, (x) => x.field_key.startsWith("event_"));

  return {
    ...tagGroup,
    customEvents: sortTags(customEvents),
    miscellaneous: sortTags(misc),
  };
};

export const validMergeTags = (tags: Tags, toTarget: ToTarget): Tags => {
  const [tagsV1, tagsV2] = partition(tags, (x) => x.version === 1);
  const grouped = groupTags(tagsV2, toTarget);

  return [...tagsV1, ...Object.values(grouped).flat()];
};

type UnlayerMergeTag = { 
  name: string;
  value: string;
  // We need to use the `previewSample` property name here, rather than directly using `sample`
  // otherwise Unlayer will use the sample data as the merge tag display name in the editor.
  previewSample: string;
};
export type UnlayerMergeTags = {
  [group: string]: {
    name: string;
    mergeTags: { [key: string]: UnlayerMergeTag };
  };
};

export const mapUnlayerMergeTags = (
  mergeTags: Tags,
  toTarget: ToTarget,
  v2: boolean,
): UnlayerMergeTags => {
  if (v2) {
    const groupedTags = groupTags(
      mergeTags.filter((x) => x.version === 2),
      toTarget,
    );

    const recipientTags = sortTags(
      groupedTags.recipient?.map((x) => ({
        ...x,
        category_display_name:
          x.category_key === "recipient" ? "Recipient" : `Recipient's ${x.category_name}`,
        field_name:
          x.category_key === "recipient" ? x.field_name : `${x.category_name} ${x.field_name}`,
      })) ?? [],
      "recipient",
    );

    const subjectTags = sortTags(
      groupedTags.subject?.map((x) => ({
        ...x,
        category_display_name:
          x.category_key === "subject" ? "Subject" : `Subject's ${x.category_name}`,
        field_name:
          x.category_key === "subject" ? x.field_name : `${x.category_name} ${x.field_name}`,
      })) ?? [],
      "subject",
    );

    return mapValues(
      groupBy(
        [
          ...recipientTags,
          ...subjectTags,
          ...(groupedTags.customEvents?.map((x) => ({
            ...x,
            category_display_name: "Custom Events",
          })) ?? []),
          ...(groupedTags.miscellaneous?.map((x) => ({
            ...x,
            category_display_name: "Miscellaneous",
          })) ?? []),
        ],
        (x) => x.category_display_name,
      ),
      (tags, key) => ({
        name: capitalizeEachWord(key),
        mergeTags: mapValues(keyBy(tags, "id"), ({ field_name, id, sample }) => ({
          name: field_name,
          value: `{{${id}}}`,
          previewSample: sample,
        })),
      }),
    );
  }

  const v1Tags = mergeTags.filter((x) => x.version === 1);
  const map = (tags: MergeTagV1[]): { [key: string]: UnlayerMergeTag } =>
    mapValues(keyBy(tags, "id"), ({ name, id, manager, sample }) => ({
      name,
      value: `{{${id}}}`,
      manager,
      previewSample: sample,
    }));

  return {
    employee: {
      name: "Employee",
      mergeTags: map(v1Tags.filter((x) => !x.manager)),
    },
    manager: {
      name: "Manager",
      mergeTags: map(v1Tags.filter((x) => x.manager)),
    },
  };
};
