import { Avatar, Box, BoxProps, Card, Grid, IconButtonProps } from "@mui/material";
import {
    DataGridPro,
    DataGridProProps,
    GRID_TREE_DATA_GROUPING_FIELD,
    GridEventListener,
    GridPinnedColumns,
    GridSortModel,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { lowerCase, sortBy } from "lodash-es";
import { FC, PropsWithChildren, RefObject, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { propertyNotEmpty } from "../../utils/not-empty";
import { ConditionalWrapper } from "../generic/components/conditional-wrapper";
import { ExpandButton } from "../generic/components/expand-button/expand-button";
import { MomentCardActions } from "../moment-card/components/moment-card-actions";
import { UseMomentProperties } from "../moment-card/types";
import { ProgramIcon } from "../programs/components/program-icon";
import { programsOrder } from "../programs/server/supabase-program-service";
import { useColumns } from "./program-list-config";
import { AvailableColumns, MomentTableProps } from "./types";

interface Props {
  moments: UseMomentProperties[];
  reloadMoments?: () => void;
  expandedPrograms?: string[];
  onProgramExpansion?: (programId: string) => void;
  setProgramExpandedState?: (programIds: string[], isExpanded: boolean) => void;
  groupByProgram?: boolean;
  columns?: (keyof AvailableColumns)[];
  wrapInCard?: boolean;
}
export const ExpandAll: FC<
  {
    apiRef: RefObject<GridApiPro>;
    buttonProps?: Omit<IconButtonProps, "onClick">;
  } & BoxProps
> = ({ apiRef, ...props }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleExpandAll = useCallback((): void => {
    if (!apiRef.current) return;
    const newExpandedState = !expanded;
    const orchestrator = apiRef.current;
    setExpanded(newExpandedState);
    orchestrator.getAllRowIds().map((id) => {
      if (orchestrator.getRowNode(id)?.type === "group") {
        orchestrator.setRowChildrenExpansion(id, newExpandedState);
      }
    });
  }, [apiRef, expanded]);

  return (
    <Box
      sx={{
        display: "table",
        ml: "auto",
        mb: 3,
      }}
      {...props}
    >
      <ExpandButton
        initialState={expanded}
        onClick={handleExpandAll}
        minimizedMessage={t("Expand")}
        expandedMessage={t("Collapse")}
      />
    </Box>
  );
};

export const ProgramListView: FC<Props & PropsWithChildren> = ({
  moments,
  reloadMoments,
  groupByProgram = true,
  children,
  columns = ["status", "when", "segment", "channel"],
  wrapInCard = true,
}) => {
  const apiRef = useGridApiRef();

  const getTreeDataPath: DataGridProProps<MomentTableProps>["getTreeDataPath"] = (row) =>
    row.hierarchy;

  const groupingColDef: DataGridProProps<MomentTableProps>["groupingColDef"] = {
    headerName: "Program",
    minWidth: 390,
    hideDescendantCount: true,
    pinnable: true,
    sortable: false,
    valueGetter: (_, row) => {
      if (row.type === "program") {
        const { theme_colour, theme_icon } = row.data;
        const id = lowerCase(row.title).replaceAll(" ", "-");

        return (
          <Grid container spacing={1} id={id}>
            <Grid>
              <Avatar sx={{ bgcolor: theme_colour, width: "30px", height: "30px" }}>
                <ProgramIcon data={theme_icon} fontSize="small" />
              </Avatar>
            </Grid>
            <Grid sx={{ mt: 0.5 }}>
              {row.title}
            </Grid>
          </Grid>
        );
      } else if (row.type === "moment") {
        const { valid_on_save, status } = row.data;
        const publishable = valid_on_save || status === "active" || status === "finished";

        let offset = 10;
        if (!publishable) {
          offset += 58;
        }

        return (
          <Grid container sx={{ width: 1420 }}>
            <Grid sx={{ my: "auto" }}>
              <MomentCardActions reload={reloadMoments} moment={row.data} showSequenceIcon small />
            </Grid>
            <Grid sx={{ my: "auto", ml: `${offset}px` }}>{row.title}</Grid>
          </Grid>
        );
      }
    },
  };

  const pinnedColumns: GridPinnedColumns | undefined = undefined;
  const sortModel: GridSortModel = [{ field: GRID_TREE_DATA_GROUPING_FIELD, sort: "asc" }];

  const rows = useMemo(() => {
    const nonEmptyMoments = moments.filter(propertyNotEmpty("program"));

    const momentRows: MomentTableProps[] = nonEmptyMoments.map((moment) => {
      const { title, status, id, program, segment, channel } = moment;

      return {
        id: (id ?? "") + "_" + (title ?? ""),
        hierarchy: [program.id as string, id as string],
        title: title ?? "",
        type: "moment",
        status: status ?? "draft",
        segment: segment?.name || "",
        channel: channel,
        data: moment,
      };
    });

    if (groupByProgram) {
      const groupedPrograms = sortBy(
        Object.values(
          Object.fromEntries(
            nonEmptyMoments.map(({ program }) => {
              return [`${program.order}-${program.title}`, program];
            }),
          ),
        ),
        programsOrder.map((p) => p.column),
      );

      groupedPrograms.forEach((program) => {
        momentRows.push({
          id: `${program.id ?? ""}_${program.order}`,
          hierarchy: [program.id as string],
          title: program.title,
          type: "program",
          data: program,
        });
      });
    }

    return momentRows;
  }, [moments, groupByProgram]);

  const navigate = useNavigate();

  const handleCellClick: GridEventListener<"cellClick"> = (params) => {
    const row = params.row as MomentTableProps;
    switch (params.field) {
      case "actions":
        break;
      default:
        if (row.type === "moment" && row.data.id !== null) {
          void navigate(`/moments/${row.data.id}`);
        }
        break;
    }
  };

  const cols = useColumns({ columns, reload: reloadMoments });

  return (
    <>
      {groupByProgram && <ExpandAll apiRef={apiRef} />}
      <ConditionalWrapper
        condition={wrapInCard}
        wrapper={(c) => <Card sx={{ width: "100%" }}>{c}</Card>}
      >
        <DataGridPro<MomentTableProps>
          apiRef={apiRef}
          rows={rows}
          columns={cols}
          treeData={groupByProgram}
          getTreeDataPath={getTreeDataPath}
          groupingColDef={groupingColDef}
          onCellClick={handleCellClick}
          initialState={{
            pinnedColumns,
            sorting: {
              sortModel,
            },
          }}
          sortingOrder={["asc", "desc"]}
          hideFooterSelectedRowCount
          hideFooter
          autoHeight
          sx={{
            "& .MuiDataGrid-row, & .MuiDataGrid-row *": { cursor: "pointer" },
          }}
          slots={{
            noRowsOverlay: () => (
              <Box ml={2} mt={4}>
                {children}
              </Box>
            ),
          }}
        />
      </ConditionalWrapper>
    </>
  );
};
