import { Alert, Button } from "@mui/material";
import { FC } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const UploadFileSuccessToast: FC<{ fileTitle: string; onClick: () => void }> = ({
  fileTitle,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <Alert
      severity="success"
      action={
        <Button
          onClick={() => {
            toast.remove();
            onClick();
          }}
          data-analytics-id="upload-file-success-toast"
          variant="text"
        >
          {t("View File")}
        </Button>
      }
      sx={{ alignItems: "center", "& .MuiAlert-action": { pt: 0 } }}
    >
      {t(`"${fileTitle}" uploaded successfully`)}
    </Alert>
  );
};
