import { Link, Stack, Typography } from "@mui/material";
import {
  getGridDateOperators,
  getGridStringOperators,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import { sortBy, uniqBy } from "lodash-es";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AnalyticsDatetime } from "../../../../../pages/moments/analytics/generic/analytics-datetime";
import { TableStack } from "../../../../../pages/moments/analytics/send-table/table-stack";
import { fromISO8601, ISO8601 } from "../../../../../utils/iso8601";
import { propertyNotEmpty } from "../../../../../utils/not-empty";
import { ChannelIcon } from "../../../../channels/components/channel-icon";
import { ChannelName } from "../../../../channels/types";
import { autocompleteOperator } from "../../../../generic/components/crud-data-grid/data-grid-multi-select-operator";
import { PublishToggleAction } from "../../../../moment-card/components/actions/publish-toggle";
import { CardArchiveButton } from "../../../../moment-card/components/card-archive-button";
import { allowPublishToggle } from "../../../../moment-card/components/moment-card-actions";
import { statusToPill } from "../../../../moment-card/utils";
import { MomentStatusDetail } from "../../../../moment/server/supabase-moment-service";

export function useRelatedMomentsColumns({
  moments,
  reload,
}: {
  reload: () => void;
  moments?: MomentStatusDetail[];
}): GridColDef<MomentStatusDetail>[] {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        field: "channel",
        headerName: t("Channel"),
        renderHeader: () => null,
        sortable: false,
        filterable: true,
        filterOperators: [
          autocompleteOperator([
            { label: "Slack", value: "slack" },
            { label: "Teams", value: "teams" },
            { label: "Work Email", value: "work_email" },
            { label: "Personal Email", value: "personal_email" },
            { label: "SMS", value: "twilio" },
          ]),
        ],
        flex: 0.1,
        renderCell: (params: GridRenderCellParams<MomentStatusDetail, ChannelName>) =>
          params.value ? (
            <Stack justifyContent="center" alignItems="center" height="100%">
              <ChannelIcon channel={params.value} fontSize="small" />
            </Stack>
          ) : null,
      },
      {
        field: "title",
        headerName: t("Moment"),
        sortable: true,
        filterable: true,
        filterOperators: [
          autocompleteOperator(
            sortBy(uniqBy(moments?.filter(propertyNotEmpty("title")) ?? [], "title"), (x) =>
              x.title.toLocaleLowerCase(),
            ).map((moment) => ({
              label: moment.title,
              value: moment.title,
            })),
          ),
          ...getGridStringOperators().filter((op) => op.value === "contains"),
        ],
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<MomentStatusDetail, MomentStatusDetail["title"]>,
        ) =>
          params.value ? (
            <TableStack>
              <Link target="_blank" href={`/moments/${params.row.id}`}>
                {params.value}
              </Link>
            </TableStack>
          ) : null,
      },
      {
        field: "status",
        headerName: t("Status"),
        sortable: false,
        filterable: true,
        filterOperators: [
          autocompleteOperator([
            { label: "Active", value: "active" },
            { label: "Draft", value: "draft" },
            { label: "Finished", value: "finished" },
            { label: "Paused", value: "paused" },
          ]),
        ],
        flex: 0.25,
        renderCell: (
          params: GridRenderCellParams<MomentStatusDetail, MomentStatusDetail["status"]>,
        ) => (params.value ? <TableStack>{statusToPill(params.value)}</TableStack> : null),
      },
      {
        field: "last_status_update",
        type: "dateTime",
        headerName: t("Status Updated"),
        filterOperators: getGridDateOperators(true).filter(
          (operator) => operator.value === "before" || operator.value === "after",
        ),
        flex: 0.75,
        valueGetter: (val) => fromISO8601(val),
        renderCell: (params: GridRenderCellParams<MomentStatusDetail, ISO8601>) =>
          params.value ? (
            <TableStack>
              <AnalyticsDatetime datetime={params.value} />
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="body2">{params.row.status_updater_name}</Typography>
                <Typography variant="caption" color="textSecondary">
                  ({params.row.status_updater_email})
                </Typography>
              </Stack>
            </TableStack>
          ) : null,
      },
      {
        field: "actions",
        type: "actions",
        align: "left",
        headerAlign: "left",
        flex: 0.25,
        getActions: (params: GridRowParams<MomentStatusDetail>) => {
          if (!params.row.id) return [];

          const actions = [
            <CardArchiveButton
              key="archive-button"
              id={params.row.id}
              isDeleted={params.row.status === "archived"}
              isArchived={params.row.status === "archived"}
              isActive={params.row.status === "active"}
              unsavedChanges={false}
              status={params.row.status}
              onClicked={reload}
            />,
          ];

          const showPublish = allowPublishToggle(params.row, params.row.status === "active");

          if (!showPublish) return actions;

          return [
            ...actions,
            <PublishToggleAction
              key="publish-toggle"
              id={params.row.id}
              status={params.row.status}
              reload={reload}
            />,
          ];
        },
      },
    ],
    [moments, t, reload],
  );
}
