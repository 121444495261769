import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SwipeOutlined from "@mui/icons-material/SwipeOutlined";
import { Stack } from "@mui/material";
import { DataGridPro, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  filterStatType,
  GroupedMomentAnalyticsData,
} from "../../../../../modules/stats/server/analytics-service";
import { ISO8601 } from "../../../../../utils/iso8601";
import { propertyNotEmpty } from "../../../../../utils/not-empty";
import { AnalyticsDatetime } from "../../generic/analytics-datetime";
import { EmptyState } from "../../generic/empty-state";
import { MissingByPerson } from "./missing-by-person";
import { NestedPersonTable } from "./nested-person-table";
import { InteractionsByPerson } from "./types";

export const AnalyticsSendsTableExpandedInteractionsByPerson: FC<{
  stats: GroupedMomentAnalyticsData["stats"];
}> = ({ stats }) => {
  const { t } = useTranslation();

  const { data, missing } = useMemo(() => {
    const interactions = filterStatType("click", stats);

    const deliveries = filterStatType("delivery", stats)
      .map((item) => item.data)
      .filter(propertyNotEmpty("recipient_data"));

    const mapped = interactions.reduce(
      (acc, { data: innerData }) => {
        const id = innerData.recipient_data?.person_id ?? "unknown";

        if (!acc[id])
          acc[id] = {
            first_name: innerData.recipient_data?.first_name ?? t("-"),
            last_name: innerData.recipient_data?.last_name ?? t("-"),
            urls: {},
          };

        acc[id].urls[innerData.url] = {
          first_interaction: innerData.min_timestamp,
          last_interaction: innerData.max_timestamp,
          clicks: innerData.count,
        };

        return acc;
      },
      {} as Record<string, InteractionsByPerson>,
    );

    return {
      data: Object.values(mapped),
      missing: deliveries.filter(
        ({ recipient_data: { person_id } }) =>
          person_id && !Object.keys(mapped).includes(person_id),
      ),
    };
  }, [stats, t]);

  const rows = useMemo(() => {
    return data.map((item, id) => {
      return {
        id,
        first_name: item.first_name,
        last_name: item.last_name,
        clicks: Object.values(item.urls).reduce((acc, { clicks }) => acc + clicks, 0),
        ...Object.values(item.urls).reduce(
          (acc, { first_interaction, last_interaction }) => {
            acc.last_interaction =
              last_interaction === null
                ? acc.last_interaction
                : acc.last_interaction === null
                  ? last_interaction
                  : last_interaction > acc.last_interaction
                    ? last_interaction
                    : acc.last_interaction;

            acc.first_interaction =
              first_interaction === null
                ? acc.first_interaction
                : acc.first_interaction === null
                  ? first_interaction
                  : first_interaction < acc.first_interaction
                    ? first_interaction
                    : acc.first_interaction;

            return acc;
          },
          { first_interaction: null, last_interaction: null } as {
            first_interaction: ISO8601 | null;
            last_interaction: ISO8601 | null;
          },
        ),

        urls: item.urls,
      };
    });
  }, [data]);

  const columns = useMemo(() => {
    const columnData: GridColDef<(typeof rows)[0]>[] = [
      {
        field: "first_name",
        headerName: t("First Name"),
        flex: 1,
      },
      {
        field: "last_name",
        headerName: t("Last Name"),
        flex: 1,
      },
      {
        field: "clicks",
        headerName: t("Clicks"),
        flex: 0.5,
      },
      {
        field: "first_interaction",
        headerName: t("First Interaction"),
        flex: 2,
        renderCell: (params: GridRenderCellParams<(typeof rows)[0], ISO8601>) =>
          params.value ? (
            <Stack direction="row" spacing={1} alignItems="center" height="100%">
              <AnalyticsDatetime datetime={params.value} />
            </Stack>
          ) : null,
      },
      {
        field: "last_interaction",
        headerName: t("Last Interaction"),
        flex: 2,
        renderCell: (params: GridRenderCellParams<(typeof rows)[0], ISO8601>) =>
          params.value ? (
            <Stack direction="row" spacing={1} alignItems="center" height="100%">
              <AnalyticsDatetime datetime={params.value} />
            </Stack>
          ) : null,
      },
    ];
    return columnData;
  }, [t]);

  if (rows.length === 0)
    return (
      <EmptyState
        symbol={<SwipeOutlined fontSize="large" />}
        title={t("No interactions yet")}
        subtitle={t(
          "It could be that no-one has interacted with a link yet, or there are no links in this Moment. " +
            "Check back later if you're expecting to see some data here. We'll also show people who " +
            "haven't interacted with any links once we start receiving this data.",
        )}
      />
    );

  return (
    <Stack spacing={2}>
      <DataGridPro
        pagination
        pageSizeOptions={[10, 25, 50, 100]}
        initialState={{ pagination: { paginationModel: { pageSize: 25 } } }}
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        getDetailPanelContent={({ row }) => <NestedPersonTable row={row} />}
        getDetailPanelHeight={() => "auto"}
        slots={{
          detailPanelCollapseIcon: ExpandLess,
          detailPanelExpandIcon: ExpandMore,
        }}
      />
      {missing.length > 0 && <MissingByPerson missing={missing} />}
    </Stack>
  );
};
