import { Stack, Typography } from "@mui/material";
import { DataGridPro, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  filterStatType,
  GroupedMomentAnalyticsData,
  NarrowedGroupedAnalyticsData,
} from "../../../../modules/stats/server/analytics-service";
import { EmptyState } from "../generic/empty-state";
import { calculatePercentage } from "../utils";

export const AnalyticsSendsTableExpandedIssues: FC<{
  stats: GroupedMomentAnalyticsData["stats"];
}> = ({ stats }) => {
  const { t } = useTranslation();

  const { data, overall } = useMemo(() => {
    const deliveries = filterStatType("delivery", stats);
    const errors = filterStatType("error", stats);

    return {
      data: errors.map((item) => item.data),
      overall: { total: deliveries.length + errors.length, count: errors.length },
    };
  }, [stats]);

  const rows = useMemo(() => {
    return data.map((item, id) => {
      return {
        id,
        first_name: item.recipient_data?.first_name ?? t("-"),
        last_name: item.recipient_data?.last_name ?? t("-"),
        work_email: item.recipient_data?.email ?? t("-"),
        delivery_address: item.recipient_data?.display ?? t("-"),
        error: item.error,
      };
    });
  }, [data, t]);

  const columns = useMemo(() => {
    const columnData: GridColDef<(typeof rows)[0]>[] = [
      {
        field: "first_name",
        headerName: t("First Name"),
        flex: 1,
      },
      {
        field: "last_name",
        headerName: t("Last Name"),
        flex: 1,
      },
      {
        field: "work_email",
        headerName: t("Work Email"),
        flex: 1,
      },
      {
        field: "delivery_address",
        headerName: t("Delivery Address"),
        flex: 1,
      },
      {
        field: "error",
        headerName: t("Issue"),
        flex: 3,
        renderCell: (params: GridRenderCellParams<(typeof rows)[0], (typeof rows)[0]["error"]>) => (
          <ErrorMessage error={params.value} />
        ),
      },
    ];
    return columnData;
  }, [t]);

  const percent = calculatePercentage(overall.count, overall.total);

  if (rows.length === 0)
    return (
      <EmptyState
        symbol="🤞"
        title={t("No issues")}
        subtitle={t("Let's hope it stays that way...")}
      />
    );

  return (
    <Stack spacing={2} direction="column">
      <Stack direction="row" spacing={2} alignItems="flex-end">
        <Typography variant="h4">{t(`${percent}%`)}</Typography>
        <Typography variant="body1" color="text.secondary">
          <Trans i18nKey="countOverallRecipients" values={{ count: overall.count }} />
        </Typography>
      </Stack>
      <Typography variant="body2">
        {t(
          "Delivery to these recipients failed. Wherever possible, we'll provide an explanation of what caused the failure.",
        )}
      </Typography>
      <DataGridPro
        pagination
        pageSizeOptions={[10, 25, 50, 100]}
        initialState={{ pagination: { paginationModel: { pageSize: 25 } } }}
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
      />
    </Stack>
  );
};

const ErrorMessage: FC<{
  error?: NarrowedGroupedAnalyticsData<"error">["error"];
}> = ({ error }) => {
  const { t } = useTranslation();

  if (!error) return t("Unknown Error");

  if (error.type === "error") {
    return error.data.message;
  }

  return `Bounced. Reason: ${error.data.reason}. Bounce Type: ${error.data.type ?? "-"}. Sub Type ${error.data.sub_type ?? "-"}`;
};
