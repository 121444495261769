import { Stack, Typography } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { CsvExportButton } from "../../../../modules/generic/components/csv-export-button";
import { GroupedMomentAnalyticsData } from "../../../../modules/stats/server/analytics-service";

export const AnalyticsSendsTableMissingOpens: FC<{
  deliveries: Extract<
    GroupedMomentAnalyticsData["stats"][number],
    { stat_type: "delivery" }
  >["data"][];
}> = ({ deliveries }) => {
  const { t } = useTranslation();

  const rows = useMemo(() => {
    return deliveries.map((item, id) => {
      return {
        id,
        first_name: item.recipient_data?.first_name ?? t("-"),
        last_name: item.recipient_data?.last_name ?? t("-"),
        email: item.recipient_data?.display ?? t("-"),
      };
    });
  }, [deliveries, t]);

  const columns = useMemo(() => {
    const columnData: GridColDef<(typeof rows)[0]>[] = [
      {
        field: "first_name",
        headerName: t("First Name"),
        flex: 1,
      },
      {
        field: "last_name",
        headerName: t("Last Name"),
        flex: 1,
      },
      {
        field: "email",
        headerName: t("Work Email"),
        flex: 1,
      },
    ];
    return columnData;
  }, [t]);

  if (rows.length === 0) return null;

  return (
    <Stack spacing={1} direction="column">
      <Typography variant="body2">
        <Trans i18nKey="countMissingOpens" values={{ count: deliveries.length }}>
          <strong>placeholder</strong>
        </Trans>
      </Typography>
      <DataGridPro
        pagination
        pageSizeOptions={[10, 25, 50, 100]}
        initialState={{ pagination: { paginationModel: { pageSize: 25 } } }}
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        slots={{ toolbar: CsvExportButton }}
      />
    </Stack>
  );
};
