import { MenuTextMatch } from "@lexical/react/LexicalTypeaheadMenuPlugin";

type BalanceResult = { balanced: true } | { balanced: false; errorStartPos: number };

export function getCurlyBraceBalance(text: string): BalanceResult {
  let openBraceCount = 0;
  let lastOpenPos: number | null = null;

  for (let i = 0; i < text.length; i++) {
    // When we find two consecutive opening braces
    if (text[i] === "{" && i + 1 < text.length && text[i + 1] === "{") {
      if (openBraceCount === 0) {
        lastOpenPos = i; // Store the position of the opening brace pair
      }
      openBraceCount++;
      i++; // skip next character since we processed a pair
    }
    // When we find two consecutive closing braces
    else if (text[i] === "}" && i + 1 < text.length && text[i + 1] === "}") {
      openBraceCount--;
      if (openBraceCount < 0) {
        return {
          balanced: false,
          errorStartPos: i,
        };
      }
      i++; // skip next character since we processed a pair
    }
  }

  if (openBraceCount === 0) {
    return { balanced: true };
  } else {
    return {
      balanced: false,
      errorStartPos: lastOpenPos || 0,
    };
  }
}

export function checkForCurlyBraces(text: string): MenuTextMatch | null {
  if (text.endsWith("{{")) {
    return {
      leadOffset: 0,
      matchingString: "",
      replaceableString: "{{",
    };
  }

  const mergeTagTypeaheadRegex = new RegExp(".*\\{\\{\\s*([^{}\\s]+)");
  const match = mergeTagTypeaheadRegex.exec(text);

  if (match !== null && text.endsWith(match[1])) {
    return {
      leadOffset: 0,
      matchingString: match[1],
      replaceableString: `{{${match[1]}`,
    };
  }

  return null;
}
