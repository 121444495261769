import Add from "@mui/icons-material/Add";
import { Box, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router";
import { uuidv4 } from "../../../utils/uuid";
import { IconCard } from "../../generic/components/icon-card";

interface Props {
  programColor: string | null;
  programId?: string;
}

export const CreateMomentCard: FC<Props> = ({ programColor, programId }) => {
  const navigate = useNavigate();

  return (
    <IconCard
      sx={{ height: "100%" }}
      title="Create new moment"
      icon={{
        icon: (
          <Add
            sx={{
              fontSize: 80,
              "&:hover": {
                color: programColor,
              },
            }}
          />
        ),
        onClick: () => {
          const params = new URLSearchParams();
          if (programId) {
            params.append("program_id", programId);
          }
          void navigate({
            pathname: `/moments/${uuidv4()}`,
            search: `?${params.toString()}`,
          });
        },
        sx: {
          bgcolor: programColor,
          "&:hover": {
            backgroundColor: "action.hover",
            cursor: "pointer",
            opacity: 0.5,
          },
        },
      }}
    >
      <Box sx={{ mb: 10 }}></Box>

      <Grid size={{xs:12}} sx={{ mb: 3 }}>
        <Typography
          variant="subtitle2"
          color={"rgb(130, 130, 130)"}
          align="center"
          justifyContent={"center"}
        >
          Add a scheduled or event-based moment
        </Typography>
      </Grid>
    </IconCard>
  );
};
