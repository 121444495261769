import { $generateNodesFromDOM } from "@lexical/html";
import { $isListItemNode, ListItemNode } from "@lexical/list";
import { $isDecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode";
import {
  $createTableNode,
  $createTableRowNode,
  $isTableCellNode,
  $isTableNode,
  $isTableRowNode,
  TableNode,
} from "@lexical/table";
import {
  $createTextNode,
  $getNodeByKey,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  LexicalEditor,
  LexicalNode,
  SerializedElementNode,
  SerializedLexicalNode,
} from "lexical";
import { ChannelName } from "../../../channels/types";
import { isValidNest } from "../../../moment/types/validators";
import { $isInTableNode } from "./is-in-table-node";

export function generateNodesFromHtml(
  editor: LexicalEditor,
  html: string,
  channel: NonNullable<ChannelName>,
  nodeFilters: ((node: LexicalNode) => boolean)[] = [],
): LexicalNode[] {
  const parser = new DOMParser();
  const dom = parser.parseFromString(html, "text/html");

  const nodes = $generateNodesFromDOM(editor, dom);

  return nodes
    .filter((node) => nodeFilters.every((filter) => filter(node)))
    .flatMap((node) => getNode(node, channel, selectionInTable()));
}

function exportJson(
  node: LexicalNode,
): (SerializedLexicalNode | SerializedElementNode) & { key: string } {
  if ($isListItemNode(node)) node.getIndent = makeGetIndent(node).bind(node);

  const data = node.exportJSON();

  if ($isElementNode(node)) {
    return {
      ...data,
      key: node.getKey(),
      children: node.getChildren().map(exportJson),
    };
  }

  return { ...data, key: node.getKey() };
}

function getNode(
  node: LexicalNode,
  channel: NonNullable<ChannelName>,
  inTableCell: boolean,
): LexicalNode[] {
  if ($isTableNode(node)) return handleTableNode(node, inTableCell, channel);

  const valid = isValidNest(exportJson(node), channel);

  const nodes = [node];
  if (!valid.valid && valid.key) {
    const invalidNode = $getNodeByKey(valid.key);

    if (invalidNode) {
      if ($isDecoratorBlockNode(invalidNode)) {
        // move to root
        invalidNode.remove();
        invalidNode && nodes.push(invalidNode);
      } else {
        invalidNode.replace($createTextNode(invalidNode.getTextContent()), false);
      }
    }
  }

  return nodes;
}

function handleTableNode(
  node: TableNode,
  inTableCell: boolean,
  channel: NonNullable<ChannelName>,
): LexicalNode[] {
  const colWidths = node.getColWidths();
  return node
    .getChildren()
    .filter($isTableRowNode)
    .flatMap((row) => {
      if (inTableCell) {
        // When pasting into a table cell, we want to extract all nodes and paste the content
        // we do not support nesting table.
        return row
          .getChildren()
          .filter($isTableCellNode)
          .flatMap((cell) => {
            return cell.getChildren().flatMap((child) => getNode(child, channel, true));
          });
      } else {
        // When pasting outside of a table cell, we want to create a new table for each row
        const newTable = $createTableNode();
        const newRow = $createTableRowNode();
        if (colWidths) newTable.setColWidths(colWidths);
        row
          .getChildren()
          .filter($isTableCellNode)
          .forEach((cell) => {
            // Ensure cell contents are valid
            const cellNodes = cell.getChildren().flatMap((x) => getNode(x, channel, true));
            cell.clear();
            cell.append(...cellNodes);
            newRow.append(cell);
          });

        newTable.append(newRow);
        return [newTable];
      }
    });
}

function selectionInTable(): boolean {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) return false;

  return $isInTableNode(selection.anchor.getNode());
}

function makeGetIndent(node: ListItemNode): () => number {
  return function getIndent() {
    const parent = node.getParent();

    if (parent === null) {
      return node.getLatest().__indent;
    }

    let listNodeParent = parent.getParent();
    let indentLevel = 0;
    while ($isListItemNode(listNodeParent)) {
      listNodeParent = listNodeParent.getParent()?.getParent() ?? null;
      indentLevel++;
    }

    return indentLevel;
  };
}
