import {
  Alert,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../../../../server/auth/use-permissions";
import { ClipboardList } from "../../../../../styles/icons/clipboard-list";
import { Scrollbar } from "../../../../generic/components/scrollbar";
import { SeverityPill } from "../../../../generic/components/severity-pill";
import { CustomEmailDomain } from "../../server/supabase-custom-email-domain-service";
import { AddCustomEmailDomain } from "./add-domain";
import { CNameRecordsDialog } from "./cnames-dialog";
import { DeleteButton } from "./delete-button";
import { NoCustomDomainsMessage } from "./no-domains";
import { RefreshButton } from "./refresh-button";

export const EmailDomainConfigurationTable: FC<{
  customDomains: CustomEmailDomain[];
  reload: () => void;
}> = ({ customDomains, reload }) => {
  const { t } = useTranslation();
  const { ce_admin } = usePermissions();

  const [cnameDialogCustomEmailDomain, setCnameDialogCustomEmailDomain] =
    useState<CustomEmailDomain | null>(null);

  return (
    <Scrollbar>
      {customDomains.length === 0 && <NoCustomDomainsMessage />}
      {ce_admin ? (
        <AddCustomEmailDomain onAdd={reload} />
      ) : (
        <Alert severity="info" sx={{ mt: 2, mb: 2 }}>
          <Typography variant="body2">
            {customDomains.length === 0
              ? t("Please contact support to configure a custom domain")
              : t("Please contact support to configure an additional custom domain")}
          </Typography>
        </Alert>
      )}
      {customDomains.length > 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="75%">{t("Domain")}</TableCell>
                <TableCell width="15%">{t("Status")}</TableCell>
                <TableCell>{t("Actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customDomains.map((customEmailDomain) => {
                return (
                  <Fragment key={customEmailDomain.id}>
                    <TableRow hover key={customEmailDomain.id}>
                      <TableCell width="65%">
                        <Typography color="textSecondary" variant="body2">
                          {customEmailDomain.domain}
                        </Typography>
                      </TableCell>
                      <TableCell width="15%">
                        <SeverityPill color={customEmailDomain.verified ? "success" : "info"}>
                          {customEmailDomain.verified ? t("Active") : t("Pending")}
                        </SeverityPill>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row">
                          {!customEmailDomain.verified && (
                            <RefreshButton
                              customEmailDomain={customEmailDomain}
                              onVerified={reload}
                            />
                          )}
                          <Tooltip
                            title={t("View CNAME Records for {{domain}}", {
                              domain: customEmailDomain.domain,
                            })}
                            placement="right"
                          >
                            <IconButton
                              data-analytics-id="email-domain-table-view-cname"
                              onClick={() => setCnameDialogCustomEmailDomain(customEmailDomain)}
                            >
                              <ClipboardList fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          {!customEmailDomain.verified && (
                            <DeleteButton customEmailDomain={customEmailDomain} onDelete={reload} />
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {cnameDialogCustomEmailDomain && (
        <CNameRecordsDialog
          customEmailDomain={cnameDialogCustomEmailDomain}
          onClose={() => setCnameDialogCustomEmailDomain(null)}
        />
      )}
    </Scrollbar>
  );
};
