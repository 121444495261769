import { Avatar, Box, Chip, Popover, Stack, SxProps, Theme, Typography } from "@mui/material";
import { groupBy, isEmpty, keys, toPairs } from "lodash-es";
import { FC, MouseEvent, useState } from "react";
import { EmojiCache, EmojiCacheEntry, makeEmojiCache } from "../../../emoji/emoji-cache";

export const EmojiView: FC<{
  emojis: { [key: string]: number };
  config?:
    | { type: "cache"; value: EmojiCache }
    | { type: "usedCustomEmojis"; value: { [key: string]: string } };
}> = ({ emojis, config }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const emojiCache =
    config?.type === "cache"
      ? config.value
      : makeEmojiCache(
          keys(emojis),
          config?.type === "usedCustomEmojis" ? config.value : undefined,
        );

  if (isEmpty(emojis))
    return (
      <Typography color="textSecondary" variant="body2">
        No reactions yet
      </Typography>
    );

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (): void => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? "emoji-popover" : undefined;

  const { emojiPresent } = groupBy(toPairs(emojis), ([key]) =>
    emojiCache[key]?.data ? "emojiPresent" : "emojiNotPresent",
  );

  const sortedEmojis = (emojiPresent ?? []).sort((a, b) => (a[1] < b[1] ? 1 : -1));

  const truncated = sortedEmojis.length > 6;

  return (
    <>
      <Stack direction="row" spacing={1} width="100%">
        {sortedEmojis.slice(0, 3).map(([key, value]) => (
          <EmojiChip key={key} emoji={key} emojiCacheEntry={emojiCache[key]} label={value} />
        ))}
      </Stack>
      {sortedEmojis.length > 3 && (
        <Stack direction="row" spacing={1} sx={{ mt: 1 }} width="100%">
          {sortedEmojis.slice(3, truncated ? 5 : 6).map(([key, value]) => (
            <EmojiChip key={key} emoji={key} emojiCacheEntry={emojiCache[key]} label={value} />
          ))}
          {truncated && (
            <Box
              aria-owns={popoverOpen ? popoverId : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <EmojiChip label={"..."} />
            </Box>
          )}
        </Stack>
      )}
      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        sx={{
          pointerEvents: "none",
        }}
        disableRestoreFocus
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <AllEmojiView emojis={emojis} config={config} />
      </Popover>
    </>
  );
};

export const AllEmojiView: FC<{
  emojis: { [key: string]: number };
  config?:
    | { type: "cache"; value: EmojiCache }
    | { type: "usedCustomEmojis"; value: { [key: string]: string } };
}> = ({ emojis, config }) => {
  const emojiCache =
    config?.type === "cache"
      ? config.value
      : makeEmojiCache(
          keys(emojis),
          config?.type === "usedCustomEmojis" ? config.value : undefined,
        );

  const { emojiPresent, emojiNotPresent } = groupBy(toPairs(emojis), ([key]) =>
    emojiCache[key]?.data ? "emojiPresent" : "emojiNotPresent",
  );

  const sortedEmojis = (emojiPresent ?? []).sort((a, b) => (a[1] < b[1] ? 1 : -1));

  return (
    <>
      <Box sx={{ m: 1 }}>
        {sortedEmojis.map(([key, value]) => (
          <EmojiChip
            key={key}
            emoji={key}
            emojiCacheEntry={emojiCache[key]}
            label={value}
            size={"medium"}
            sx={{ m: 0.25 }}
          />
        ))}
      </Box>
      {!isEmpty(emojiNotPresent ?? []) && (
        <Box sx={{ m: 1 }}>
          {emojiNotPresent
            .sort((a, b) => (a[1] < b[1] ? 1 : -1))
            .map(([key, value]) => (
              <EmojiChip
                key={key}
                emoji={key}
                emojiCacheEntry={emojiCache[key]}
                label={value}
                size={"medium"}
                sx={{ m: 0.25 }}
              />
            ))}
        </Box>
      )}
    </>
  );
};

export const EmojiChip: FC<{
  emoji?: string;
  emojiCacheEntry?: EmojiCacheEntry;
  label: number | string;
  size?: "small" | "medium";
  sx?: SxProps<Theme>;
}> = ({ emoji, label, emojiCacheEntry, size, sx }) => {
  return (
    <Chip
      size={size ?? "small"}
      avatar={
        emoji ? (
          <EmojiAvatar emoji={emoji} emojiCacheEntry={emojiCacheEntry} size={size} />
        ) : undefined
      }
      label={label.toLocaleString()}
      variant="outlined"
      sx={{ p: "2px", ...(sx ?? {}) }}
    />
  );
};

export const EmojiAvatar: FC<{
  emoji: string;
  emojiCacheEntry?: EmojiCacheEntry;
  size?: "small" | "medium" | "large";
}> = ({ emoji, emojiCacheEntry, size }) => {
  const sizes = { small: 18, medium: 22, large: 26 };

  const px = sizes[size || "small"];

  const sx = {
    width: px,
    height: px,
    fontSize: `${px - 1}px`,
    backgroundColor: "transparent",
  };

  if (emojiCacheEntry?.type === "url")
    return <Avatar src={emojiCacheEntry.data} alt={`${emoji} emoji`} sx={sx} />;

  if (emojiCacheEntry) return <Avatar sx={sx}>{emojiCacheEntry?.data}</Avatar>;

  return <Avatar sx={{ ...sx, fontSize: "1vw" }}>{emoji}</Avatar>;
};
