import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { DataGridPro, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  filterStatType,
  GroupedMomentAnalyticsData,
} from "../../../../../modules/stats/server/analytics-service";
import { fromISO8601 } from "../../../../../utils/iso8601";
import { EmptyState } from "../../generic/empty-state";
import { NestedReactionTable } from "./nested-reaction-table";
import { ReactionAvatar } from "./reaction-avatar";
import { ReactionsByEmoji } from "./types";

export const AnalyticsSendsTableExpandedReactionsByEmoji: FC<{
  stats: GroupedMomentAnalyticsData["stats"];
}> = ({ stats }) => {
  const { t } = useTranslation();

  const { data } = useMemo(() => {
    const reactions = filterStatType("reaction", stats);

    const mapped = reactions.reduce(
      (acc, { data: innerData }) => {
        const emoji = innerData.reaction;

        if (!acc[emoji])
          acc[emoji] = {
            reaction: { emoji, custom_url: innerData.custom_url },
            people: [],
          };

        acc[emoji].people.push({
          first_name: innerData.reactor_data?.first_name ?? t("-"),
          last_name: innerData.reactor_data?.last_name ?? t("-"),
          timestamp: fromISO8601(innerData.timestamp),
        });

        return acc;
      },
      {} as Record<string, ReactionsByEmoji>,
    );

    return { data: Object.values(mapped) };
  }, [stats, t]);

  const rows = useMemo(() => {
    return data.map((item, id) => {
      return {
        id,
        reaction: item.reaction,
        reactions: item.people.length,
        people: item.people,
        first_reaction: item.people.reduce(
          (acc, { timestamp }) => (acc ? (timestamp < acc ? timestamp : acc) : timestamp),
          new Date("2999-12-31"),
        ),
        last_reaction: item.people.reduce(
          (acc, { timestamp }) => (acc ? (timestamp > acc ? timestamp : acc) : timestamp),
          new Date("1970-01-01"),
        ),
      };
    });
  }, [data]);

  const columns = useMemo(() => {
    const columnData: GridColDef<(typeof rows)[0]>[] = [
      {
        field: "reaction",
        headerName: t("Emoji"),
        flex: 0.5,
        renderCell: (
          params: GridRenderCellParams<ReactionsByEmoji, ReactionsByEmoji["reaction"]>,
        ) => <ReactionAvatar reaction={params.value} />,
      },
      {
        field: "reactions",
        headerName: t("Total Reactions"),
        flex: 0.5,
      },
      {
        field: "first_reaction",
        headerName: t("First usage"),
        flex: 2,
        type: "dateTime",
      },
      {
        field: "last_reaction",
        headerName: t("Most recent usage"),
        flex: 2,
        type: "dateTime",
      },
    ];
    return columnData;
  }, [t]);

  if (rows.length === 0)
    return (
      <EmptyState symbol={"😴"} title={t("No reactions yet")} subtitle={t("Check back soon")} />
    );

  return (
    <DataGridPro
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
      initialState={{ pagination: { paginationModel: { pageSize: 25 } } }}
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      getDetailPanelContent={({ row }) => <NestedReactionTable row={row} />}
      getDetailPanelHeight={() => "auto"}
      slots={{
        detailPanelCollapseIcon: ExpandLess,
        detailPanelExpandIcon: ExpandMore,
      }}
    />
  );
};
