import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Paper, Stack, Tab } from "@mui/material";
import { FC, SyntheticEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChannelName, isEmailChannel } from "../../../../modules/channels/types";
import { isChatChannel } from "../../../../modules/moment/component/channel/channel";
import { GroupedMomentAnalyticsData } from "../../../../modules/stats/server/analytics-service";
import { AnalyticsSendsTableExpandedDeliveries } from "./expanded-delivered";
import { AnalyticsSendsTableExpandedInteractions } from "./expanded-interactions/expanded-interactions";
import { AnalyticsSendsTableExpandedIssues } from "./expanded-issues";
import { AnalyticsSendsTableExpandedOpens } from "./expanded-opens";
import { AnalyticsSendsTableExpandedReactions } from "./expanded-reactions/expanded-reactions";

type Tabs = "delivered" | "opens" | "interactions" | "issues" | "reactions";

export const AnalyticsSendsTableExpandedRow: FC<{
  channel: ChannelName;
  stats: GroupedMomentAnalyticsData["stats"];
}> = ({ channel, stats }) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState<Tabs>("delivered");

  const handleChange = useCallback(
    (_: SyntheticEvent, newValue: Tabs) => {
      if (!isChatChannel(channel) && newValue === "reactions") return;

      if (!isEmailChannel(channel) && newValue === "opens") return;

      setTab(newValue);
    },
    [channel],
  );

  return (
    <Stack sx={{ px: 2, height: "100%", boxSizing: "border-box" }} direction="column">
      <TabContext value={tab}>
        <Stack direction="column" spacing={2} sx={{ my: 2 }}>
          <TabList onChange={handleChange}>
            <Tab label={t("Delivered")} value="delivered" />
            {isEmailChannel(channel) && <Tab label={t("Unique Opens")} value="opens" />}
            <Tab label={t("Interacted")} value="interactions" />
            {isChatChannel(channel) && <Tab label={t("Reactions")} value="reactions" />}
            <Tab label={t("Issues")} value="issues" />
          </TabList>
          <Paper elevation={6} sx={{ px: 3, py: 2 }}>
            <TabPanel value="delivered">
              <AnalyticsSendsTableExpandedDeliveries
                stats={stats}
                switchToIssuesTab={() => setTab("issues")}
              />
            </TabPanel>
            {isEmailChannel(channel) && (
              <TabPanel value="opens">
                <AnalyticsSendsTableExpandedOpens stats={stats} />
              </TabPanel>
            )}
            <TabPanel value="interactions">
              <AnalyticsSendsTableExpandedInteractions stats={stats} />
            </TabPanel>
            {isChatChannel(channel) && (
              <TabPanel value="reactions">
                <AnalyticsSendsTableExpandedReactions stats={stats} />
              </TabPanel>
            )}
            <TabPanel value="issues">
              <AnalyticsSendsTableExpandedIssues stats={stats} />
            </TabPanel>
          </Paper>
        </Stack>
      </TabContext>
    </Stack>
  );
};
