import { FC } from "react";

import { MomentAnalyticsCellDataDisplay } from "../hooks/utils";

export const ValueCellDisplay: FC<{
  cellData?: MomentAnalyticsCellDataDisplay;
}> = ({ cellData }) => (
  <>
    {cellData?.type === "number" && <>{cellData.value}</>}
    {cellData?.type === "percentage" && <>{`${cellData.value}%`}</>}
    {cellData?.type === null && <>-</>}
  </>
);
