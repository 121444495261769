import ReduceCapacity from "@mui/icons-material/ReduceCapacity";
import {
  Autocomplete,
  Popover,
  TextField,
  TextFieldProps,
  ToggleButton,
  ToggleButtonProps,
} from "@mui/material";
import { memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSupabase } from "../../server/supabase/hooks";
import { propertyNotEmpty } from "../../utils/not-empty";
import { UUID } from "../../utils/uuid";
import { SupabaseSegmentService } from "../segment/server/supabase-segment-service";

export type SegmentFilterProps = Omit<ToggleButtonProps, "onChange" | "value"> & {
  onChange: (segment?: UUID) => void;
};
export const SegmentFilter = memo(function SegmentSelector({
  onChange,
  ...props
}: SegmentFilterProps) {
  const [segments, setSegments] = useState<{ label: string; id: UUID }[]>([]);
  const [value, setValue] = useState<UUID | undefined>();
  const selectedSegment = useMemo(
    () => segments.find(({ id }) => id === value) || null,
    [value, segments],
  );

  useSupabase(async ({ supabase, account_id }) => {
    const { data } = await new SupabaseSegmentService(supabase).getAll(account_id, {
      filter: [
        { key: "name", operator: "neq", value: "" },
        { key: "account_id", operator: "eq", value: account_id },
      ],
      order: [{ column: "id" }],
    });

    if (data) {
      const segmentData = data
        .filter(propertyNotEmpty("name"))
        .map(({ id, name }) => ({ label: name, id }));

      setSegments(segmentData);
    }
  }, []);

  const handleChange = useCallback(
    (_: unknown, v?: { id: UUID } | null) => {
      const id = v?.id;
      setValue(id);
      onChange(id);
    },
    [onChange],
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <ToggleButton
        data-analytics-id="tabbed-programs-segment-filter"
        value="segment"
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
        title="Segment filter"
        selected={!!value}
        {...props}
        color={value ? "primary" : undefined}
      >
        <ReduceCapacity />
      </ToggleButton>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        PaperProps={{
          sx: { border: "none", paddingTop: 1 },
        }}
      >
        <Autocomplete
          id="combo-box-demo"
          options={segments}
          sx={{ width: 300 }}
          renderInput={AutoCompleteInput}
          onChange={handleChange}
          value={selectedSegment}
          disableCloseOnSelect={true}
          openOnFocus={true}
        />
      </Popover>
    </>
  );
});

const AutoCompleteInput = (params: TextFieldProps): ReactNode => {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    setTimeout(() => {
      ref.current?.focus();
    }, 200);
  }, []);

  return <TextField {...params} label="Segment" inputRef={ref} />;
};
