import { AppBar, Button, Card, CardContent, CardMedia, Toolbar, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Stack } from "@mui/system";
import { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { neutral } from "../../../../styles/theme/base-theme-options";

const bgColor = "#0D1035";

export const AppError: FC<{ onRetry: () => void }> = ({ onRetry }) => {
  const { t } = useTranslation();
  useEffect(() => {
    const timeout = setTimeout(() => {
      document.title = t("Something went wrong");
    }, 4500);
    return () => clearTimeout(timeout);
  }, [t]);

  useEffect(() => {
    document.body.style.backgroundColor = bgColor;
  }, []);

  const handleClick = useCallback(() => {
    onRetry();

    window.location.assign("/");
  }, [onRetry]);

  return (
    <>
      <AppBar>
        <Toolbar sx={{ bgcolor: neutral[900] }}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <img src="/logo.svg" alt="logo" height={30} />
          </Stack>
        </Toolbar>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          height="100vh"
          sx={{ bgcolor: bgColor }}
        >
          <Card
            sx={{
              maxWidth: 800,
              borderColor: "#C2C2C2",
              borderWidth: 1,
              borderStyle: "solid",
              bgcolor: grey[50],
            }}
          >
            <CardMedia
              component="img"
              image="https://images.changeengine.com/uh-oh.svg"
              title="error"
            />
            <CardContent>
              <Stack
                direction="column"
                alignItems="center"
                spacing={2}
                sx={{ my: 2, bgcolor: grey[50] }}
              >
                <Typography variant="body1">
                  {t(
                    "Don't worry, we're aware and will be fixing it soon. For now, try heading back to your dashboard.",
                  )}
                </Typography>
                <Typography variant="body1">
                  {t("If the problem persists, please contact support@changeengine.com")}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                  data-analytics-id="app-error-go-to-dashboard"
                  sx={{
                    bgcolor: "#5048E5",
                    color: "#fff",
                    textTransform: "none",
                    borderRadius: "6px",
                    m: 4,
                  }}
                >
                  {t("Go to dashboard")}
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </AppBar>
    </>
  );
};
