import { useDeepCompareEffect } from "@react-hookz/web";
import { DependencyList, useCallback, useEffect, useState } from "react";
import { UseSupabaseLoaderState } from "../../supabase/hooks/types";

export type ApiLoaderOptions = {
  waiting?: boolean;
};

export type UseApiLoaderResponse<T> = UseSupabaseLoaderState<T> & { reload: () => void };
/**
 * An Effect for loading data from API with a reload handler
 * NOTE: Reloading whilst loading dose not cancel and can show the first requests data
 */
export function useApiLoader<T>(
  callback: () => Promise<T>,
  deps: DependencyList,
  { waiting = false }: ApiLoaderOptions = {},
): UseApiLoaderResponse<T> {
  const [reloadCount, setReloadCount] = useState(0);
  const [state, setState] = useState<UseSupabaseLoaderState<T>>({
    data: null,
    loading: true,
    error: null,
  });
  useEffect(() => {
    if (!state.loading) return;
    if (waiting) return;
    if (reloadCount === 0) return;
    callback()
      .then((data) => setState({ data, loading: false, error: null }))
      .catch((error) => setState({ data: null, loading: false, error: error as Error }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loading, reloadCount, waiting]); // Do not add callback

  const reload = useCallback(() => {
    setState({ data: null, loading: true, error: null });
    // Reload even if you were still loading
    setReloadCount(reloadCount + 1);
  }, [reloadCount]);

  useDeepCompareEffect(reload, deps);

  return { ...state, reload };
}
