import { useSupabaseLoader } from "../../../server/supabase/hooks";
import { UUID } from "../../../utils/uuid";
import {
  DigitalAssetLogRecord,
  SupabaseDigitalAssetLogService,
} from "../server/digital-asset-log-service";

export function useAssetLog(
  id: UUID,
): ReturnType<
  typeof useSupabaseLoader<Pick<DigitalAssetLogRecord, "user_name" | "created_at" | "action">>
> {
  return useSupabaseLoader(
    async ({ supabase }) => {
      const { data, error } = await new SupabaseDigitalAssetLogService(
        supabase,
      ).getAssetCreatorByDigitalAssetID(id);

      if (error) throw error;

      return data;
    },
    [id],
  );
}
