import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { FC, Fragment } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Scrollbar } from "../../../../generic/components/scrollbar";
import { CustomEmailDomain } from "../../server/supabase-custom-email-domain-service";

export const CNameRecordsDialog: FC<{
  customEmailDomain?: CustomEmailDomain;
  onClose: () => void;
}> = ({ customEmailDomain, onClose }) => {
  const { t } = useTranslation();

  if (!customEmailDomain) return null;

  const cnameRecords = customEmailDomain.cname_records;

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>{t("CNAME Records to Add")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Trans>
            To complete the verification process, you have to add all of the following records to
            the DNS configuration for your domain. When we detect these records in the DNS
            configuration for your domain, the status for the domain changes to &quot;Active&quot;.
            This process is usually complete within 72 hours.
          </Trans>
        </DialogContentText>
        <Scrollbar>
          <Table sx={{ mt: 2 }}>
            <TableHead>
              <TableRow>
                <TableCell>{t("Name/Host")}</TableCell>
                <TableCell>{t("Type")}</TableCell>
                <TableCell>{t("Record value")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cnameRecords.map((token) => {
                return (
                  <Fragment key={token}>
                    <TableRow hover key={token}>
                      <TableCell>
                        {token}._domainkey.{customEmailDomain.domain}
                      </TableCell>
                      <TableCell>CNAME</TableCell>
                      <TableCell>{token}.dkim.amazonses.com</TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </Scrollbar>
      </DialogContent>
      <DialogActions>
        <Button data-analytics-id="email-domain-table-cname-dialog-close" onClick={onClose}>
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
