import Close from "@mui/icons-material/Close";
import ContentCopy from "@mui/icons-material/ContentCopy";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import FileDownloadOutlined from "@mui/icons-material/FileDownloadOutlined";
import LinkOutlined from "@mui/icons-material/LinkOutlined";
import SyncOutlined from "@mui/icons-material/SyncOutlined";
import {
  Alert,
  Box,
  Button,
  CardMedia,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDialog } from "../../utils/hooks/use-dialog";
import { formatDatetime, fromISO8601 } from "../../utils/iso8601";
import { FileIconWithChip } from "../generic/components/icon-with-chip";
import { DeleteConfirmation } from "./asset-preview-modal";
import { useAssetLog } from "./hooks/use-asset-log";
import { useDeleteAssetCallback } from "./hooks/use-delete-asset-callback";
import { useDuplicateAssetCallback } from "./hooks/use-duplicate-asset-callback";
import { LinkToAssetButton } from "./link-to-asset-button";
import { ReplaceFileModal } from "./replace-file-modal";
import { Asset } from "./server/digital-asset-service";

export const UploadedFileAssetPreviewModal: FC<{
  open: boolean;
  onClose: () => void;
  asset: Asset;
  onChange: () => void;
  onDeleted?: () => void;
  onViewFileClick: (asset: Asset) => void;
}> = ({ asset, open, onClose, onChange, onDeleted, onViewFileClick }) => {
  const { t } = useTranslation();
  const [copying, setCopying] = useState(false);

  const creator = useAssetLog(asset.id);

  const deleteConfirmation = useDialog();
  const deleteAsset = useDeleteAssetCallback();

  const handleConfirmDelete = useCallback(async () => {
    deleteConfirmation.handleClose();

    try {
      await deleteAsset(asset.id);
      toast.custom(<Alert severity="success">{t(`"${asset.title}" deleted`)}</Alert>, {
        duration: 2000,
      });
      onDeleted?.();

      onChange?.();
    } catch (e) {
      console.error(e);
      toast.custom(
        <Alert severity="error">{t("Failed to delete asset. Please try again later")}</Alert>,
        {
          duration: 3000,
        },
      );
    }
  }, [asset.id, asset.title, deleteAsset, deleteConfirmation, onChange, onDeleted, t]);

  const duplicateConfirmation = useDialog();
  const duplicateAsset = useDuplicateAssetCallback();

  const handleConfirmDuplicate = useCallback(async () => {
    duplicateConfirmation.handleClose();
    setCopying(true);

    const { success } = await duplicateAsset(asset);
    if (success) {
      toast.custom(<Alert severity="success">{t(`"${asset.title}" duplicated`)}</Alert>, {
        duration: 2000,
      });
    } else {
      toast.custom(
        <Alert severity="error">{t("Failed to duplicate content. Please try again later")}</Alert>,
        {
          duration: 3000,
        },
      );
    }

    onChange?.();
    onClose();
    setCopying(false);
  }, [asset, duplicateConfirmation, onChange, onClose, t, duplicateAsset]);

  const changeFileDialog = useDialog();

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle display="flex" justifyContent="space-between" component="div">
          <Stack spacing={0} width="100%">
            <Box sx={{ p: 0, m: 0 }} display="flex" alignItems="center" width="100%">
              <Typography variant="h6" flexGrow={1}>
                {asset.title}
              </Typography>
              <IconButton
                onClick={onClose}
                data-analytics-id="content-library-preview-close"
                title={t("Close")}
                size="small"
              >
                <Close data-testid="CloseIcon" />
              </IconButton>
            </Box>
            <LinkToAssetButton asset={asset} />
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <Typography variant="body2">
              {t(
                "Please note that files uploaded to the Content Library will be accessible by anyone with a link to the file.",
              )}
            </Typography>
          </Box>
          {asset.mime_type === "application/pdf" ? (
            <Stack display="flex" alignItems="center" justifyContent="center" mt={4} gap={2}>
              <FileIconWithChip />
              <Button
                data-analytics-id="content-library-preview"
                onClick={() => {
                  if (!asset.public_url) {
                    toast.custom(
                      <Alert severity="error">{t("Unable to download Content")}</Alert>,
                      {
                        duration: 2000,
                      },
                    );
                    return;
                  }

                  const element = document.createElement("a");
                  const url = new URL(asset.public_url);
                  element.href = url.toString();
                  element.target = "_blank";
                  element.click();
                }}
              >
                {t("Preview")}
              </Button>
            </Stack>
          ) : (
            <CardMedia src={asset.thumbnail_url || ""} component="img" />
          )}
          <Stack gap={1}>
            <Box mb={2} mt={2}>
              <Chip label={t("Uploaded File")} />
            </Box>
            {creator.data ? (
              <>
                <Typography variant="body2">
                  {creator.data.action === "Updated"
                    ? t("Updated by")
                    : asset.source_digital_asset_id
                      ? t("Duplicated by")
                      : t("Added by")}{" "}
                  {creator.data.user_name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {formatDatetime(fromISO8601(creator.data.created_at))}
                </Typography>
              </>
            ) : (
              !creator.error && (
                <>
                  <Skeleton variant="text" width={100} />
                  <Skeleton variant="text" width={200} />
                </>
              )
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyItems: "right", px: 2, mb: 2, whiteSpace: "nowrap" }}>
          <Button
            startIcon={<LinkOutlined />}
            data-analytics-id="content-library-uploaded-file-get-link"
            onClick={() => {
              if (!asset.public_url) {
                toast.custom(<Alert severity="error">{t("Unable to get Link")}</Alert>, {
                  duration: 2000,
                });
                return;
              }

              void navigator.clipboard.writeText(asset.public_url);
              toast.custom(<Alert severity="success">{t("Link Copied to Clipboard")}</Alert>, {
                duration: 2000,
              });
            }}
          >
            {t("Get Link")}
          </Button>
          <Button
            startIcon={<FileDownloadOutlined />}
            data-analytics-id="content-library-uploaded-file-download"
            onClick={() => {
              if (!asset.public_url) {
                toast.custom(<Alert severity="error">{t("Unable to download Content")}</Alert>, {
                  duration: 2000,
                });
                return;
              }

              const element = document.createElement("a");
              const url = new URL(asset.public_url);
              url.searchParams.append("download", "true");
              element.href = url.toString();
              element.download = asset.title;
              element.click();
              toast.custom(<Alert severity="success">{t("Downloading file...")}</Alert>, {
                duration: 2000,
              });
            }}
          >
            {t("Download")}
          </Button>
          <Button
            startIcon={<ContentCopy data-testid="ContentCopyIcon" />}
            data-analytics-id="content-library-uploaded-file-duplicate"
            onClick={duplicateConfirmation.handleOpen}
            loading={copying}
          >
            {t("Duplicate")}
          </Button>
          <Button
            startIcon={<SyncOutlined />}
            data-analytics-id="content-library-uploaded-file-change"
            onClick={changeFileDialog.handleOpen}
          >
            {t("Change")}
          </Button>
          <Button
            startIcon={<DeleteOutline data-testid="DeleteOutlineIcon" />}
            data-analytics-id="content-library-preview-delete"
            onClick={deleteConfirmation.handleOpen}
            color="error"
          >
            {t("Delete")}
          </Button>
        </DialogActions>
      </Dialog>
      <DuplicateConfirmation
        asset={asset}
        open={duplicateConfirmation.open}
        onClose={duplicateConfirmation.handleClose}
        onConfirm={() => void handleConfirmDuplicate()}
      />
      <DeleteConfirmation
        asset={asset}
        open={deleteConfirmation.open}
        onClose={deleteConfirmation.handleClose}
        onConfirm={() => void handleConfirmDelete()}
      />
      <ReplaceFileModal
        open={changeFileDialog.open}
        onOpen={changeFileDialog.handleOpen}
        onClose={() => {
          changeFileDialog.handleClose();
          onClose();
        }}
        onCancel={changeFileDialog.handleClose}
        onChange={onChange}
        onViewFileClick={onViewFileClick}
        assetToReplace={asset}
      />
    </>
  );
};

const DuplicateConfirmation: FC<{
  asset: Asset;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}> = ({ asset, open, onClose, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t(`Duplicate "${asset.title}"?`)}</DialogTitle>
      <DialogContent>{t("This will duplicate the file in your Content Library.")}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} data-analytics-id="asset-duplicate-cancel" variant="text">
          {t("Cancel")}
        </Button>
        <Button onClick={onConfirm} data-analytics-id="asset-duplicate-confirm" variant="contained">
          {t("Duplicate")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
