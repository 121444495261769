import { Card, CardContent, Grid, GridProps, LinearProgress } from "@mui/material";
import { FC, PropsWithChildren, useState } from "react";
import { useSearchParams } from "react-router";
import { useFlag } from "../../server/optimizely";
import { useSupabase } from "../../server/supabase/hooks";
import { Database } from "../../server/supabase/types/database-definitions";
import { UUID } from "../../utils/uuid";
import { AIMomentCard } from "../ai-moment-card/ai-moment-card";
import { useAccount } from "../generic/hooks/use-account";
import { CreateMomentCard } from "../moment-card/components/create-moment-card";
import { MomentCardMemo } from "../moment-card/components/moment-card";
import { UseMomentProperties } from "../moment-card/types";
import { SupabaseProgramService } from "../programs/server/supabase-program-service";

export interface MomentGridProps extends GridProps {
  moments: UseMomentProperties[];
  programId?: UUID;
  showCreate?: boolean;
  showProgram?: boolean;
  generateMoment?: boolean;
}

export const MomentGrid: FC<MomentGridProps & PropsWithChildren> = ({
  moments,
  programId,
  showCreate = true,
  showProgram = false,
  children,
  generateMoment = false,
  ...gridProps
}) => {
  const [drag_and_drop] = useFlag("drag_and_drop");
  const { account } = useAccount();
  const [program, setProgram] = useState<
    Database["public"]["Views"]["populated_programs"]["Row"] | null
  >(null);
  useSupabase(
    async ({ supabase }) => {
      if (programId) {
        const { data } = await new SupabaseProgramService(supabase).getPopulated(programId);
        setProgram(data);
      }
    },
    [programId],
  );

  const [searchParams] = useSearchParams();

  if (programId && !program) return <LinearProgress />;

  return (
    <Grid
      container
      spacing={2}
      gridAutoRows="1fr"
      columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 6 }}
      data-testid="moment-grid"
      {...gridProps}
    >
      {moments.map((moment) => (
        <Grid  key={moment.id} size={{xs: 1}} minWidth={300}>
          <MomentCardMemo
            account={account}
            draggable={drag_and_drop}
            moment={moment}
            showProgram={showProgram}
          />
        </Grid>
      ))}

      {generateMoment && (
        <Grid key="generated" size={{xs: 1}} minWidth={300}>
          <AIMomentCard query={searchParams.get("q") ?? ""} />
        </Grid>
      )}

      {moments.length === 0 && children && (
        <Grid size={{xs: 1}}>
          <Card sx={{ height: "100%" }}>
            <CardContent>{children}</CardContent>
          </Card>
        </Grid>
      )}
      {showCreate && program && (
        <Grid size={{xs: 1}}>
          <CreateMomentCard programColor={program.theme_colour} programId={programId} />
        </Grid>
      )}
    </Grid>
  );
};
