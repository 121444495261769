import { isEmpty } from "lodash-es";
import { SerialisedEditorDesign } from "../types/serialised-editor-design";

// The editorState.isEmpty() function only returns true if there are no nodes except the root in the state
// However, by default we start with paragraph node, so we check here to see if the paragraphs themselves have content
export const editorIsEmpty = (editorState?: SerialisedEditorDesign | null): boolean => {
  if (!editorState || isEmpty(editorState)) {
    return true;
  }

  return !editorState?.root?.children?.some((x) => {
    if (["list", "image", "design-huddle", "buttonlist"].includes(x.type)) {
      return true;
    }
    if ("children" in x && Array.isArray(x.children) && x.children.length > 0) {
      return true;
    }
    return false;
  });
};
