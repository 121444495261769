export const resizeImageFromUrl = async (
  url: string,
  { height, width }: { height: number; width: number },
  maintainAspectRatio = true,
): Promise<File> => {
  const img = await fetch(url);
  const blob = await img.blob();
  return resizeImage(blob, { height, width }, maintainAspectRatio);
};

export const resizeImage = (
  file: File | Blob,
  { height, width }: { height: number; width: number },
  maintainAspectRatio = true,
): Promise<File> => {
  const url = URL.createObjectURL(file);

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) return reject(Error("Could not get canvas context"));

      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = "high";

      canvas.height = height;
      canvas.width = width;

      if (maintainAspectRatio) {
        ctx.fillStyle = "white";

        const ratio = img.naturalWidth / img.naturalHeight;

        let newWidth = Math.round(width);
        let newHeight = Math.round(width / ratio);
        if (newHeight > height) {
          newHeight = Math.round(height);
          newWidth = Math.round(newHeight * ratio);
        }

        canvas.height = newHeight;
        canvas.width = newWidth;

        ctx.drawImage(img, 0, 0, newWidth, newHeight);
      } else {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      }

      canvas.toBlob((blob) => {
        if (!blob) return reject(Error("Could not get blob from canvas"));

        resolve(
          new File([blob], file instanceof File ? file.name : "", {
            type: file.type ?? "image/jpeg",
          }),
        );
      });
    };
    img.onerror = reject;
    img.src = url;
  });
};

export const resizeImageProportionally = (
  file: File | Blob,
  { maxWidth, maxHeight }: { maxWidth: number; maxHeight: number },
): Promise<File> => {
  // If landscape, then width is the limiting factor
  // If portrait, then height is the limiting factor
  // Don't resize if image is smaller than max dimensions

  const url = URL.createObjectURL(file);

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) return reject(Error("Could not get canvas context"));

      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = "high";

      if (img.naturalWidth < maxWidth && img.naturalHeight < maxHeight)
        return resolve(
          new File([file], file instanceof File ? file.name : "", { type: "image/jpeg" }),
        );

      const ratio = img.naturalWidth / img.naturalHeight;

      let newWidth = maxWidth;
      let newHeight = maxWidth / ratio;
      if (newHeight > maxHeight) {
        newHeight = maxHeight;
        newWidth = newHeight * ratio;
      }

      canvas.height = newHeight;
      canvas.width = newWidth;

      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      canvas.toBlob((blob) => {
        if (!blob) return reject(Error("Could not get blob from canvas"));

        resolve(
          new File([blob], file instanceof File ? file.name : "", {
            type: file.type ?? "image/jpeg",
          }),
        );
      });
    };
    img.onerror = reject;
    img.src = url;
  });
};

export const resizeImageProportionallyFromUrl = async (
  url: string,
  { maxWidth, maxHeight }: { maxWidth: number; maxHeight: number },
): Promise<File> => {
  const img = await fetch(url);
  const blob = await img.blob();
  return resizeImageProportionally(blob, { maxWidth, maxHeight });
};

export const getImageDimensions = (
  proportionOfMaxWidth: number,
  aspect: number,
  maxWidth: number,
): { width: number; height: number } => {
  const size = {
    width: (proportionOfMaxWidth ?? 1) * maxWidth,
    height: ((proportionOfMaxWidth ?? 1) * maxWidth) / (aspect ?? 1),
  };

  const ratio = size.width / size.height;

  let newWidth = size.width;
  let newHeight = size.width / ratio;
  if (newHeight > size.height) {
    newHeight = size.height;
    newWidth = newHeight * ratio;
  }

  return { width: newWidth, height: newHeight };
};
