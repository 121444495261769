import Newspaper from "@mui/icons-material/Newspaper";
import { Box, Chip, Grid, Tooltip, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ChannelIcon } from "../../channels/components/channel-icon";
import { ChannelName } from "../../channels/types";
import { isNewsletter } from "../../moment/hooks/use-newsletter-redirect";
import { getTargetType } from "../../to/use-to-target";
import { ChannelChip, SegmentChip } from "./moment-card-chip";

export const SegmentAndChannel: FC<{
  moment: {
    target_channel: boolean | null;
    notify_channel: boolean | null;
    target_manager: boolean | null;
    target_connection_type: string | null;
    channel: ChannelName | null;
    additional_recipients_json?:
      | { display?: string | null; destination: string; teams_link_name?: string | null }[]
      | null;
    segment?: { name: string | null } | null;
    use_lexical_editor: boolean | null;
    only_additional_recipients: boolean | null;
  };
}> = ({ moment }) => {
  const { t } = useTranslation();
  const toTarget = getTargetType(moment);

  const isChatChannel = moment.target_channel || moment.notify_channel;

  return (
    <Box sx={{ pt: 2, pb: 1 }}>
      <Grid size={{ xs: 12 }}>
        <Typography align="left" fontSize={12} color="rgb(180, 180, 180)" sx={{ paddingTop: 1 }}>
          {isChatChannel ? t("Chat Channel") : t("Segment")}
        </Typography>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mb: -1,
        }}
      >
        <Grid size={{ xs: 10 }}>
          <Box
            sx={{
              mr: 2,
              overflow: "auto",
              display: "flex",
              flexDirection: "row",
              "::-webkit-scrollbar": {
                width: "0px",
                background: "transparent",
              },
            }}
            data-testid="segment-section"
          >
            <Chip
              label={
                isChatChannel ? (
                  <ChannelChip moment={moment} />
                ) : (
                  <SegmentChip
                    segmentName={moment.segment?.name}
                    toTarget={toTarget}
                  />
                )
              }
              sx={{ my: 0.5, py: 1, mr: 1, overflow: "hidden" }}
              variant="outlined"
              size="small"
            />
          </Box>
        </Grid>
        <Box
          sx={{
            justifyContent: "right",
          }}
          data-testid="moment-card-channel"
        >
          <ChannelIcon channel={moment.channel} />
        </Box>
        {isNewsletter(moment) && (
          <Box data-testid="moment-card-newsletter">
            <Tooltip title={t("Newsletter")}>
              <Newspaper />
            </Tooltip>
          </Box>
        )}
      </Box>
    </Box>
  );
};
