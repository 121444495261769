import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Box, Button, Divider, Menu, Stack } from "@mui/material";
import { Dispatch, FC, SetStateAction, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterSection } from "../../filter/filter-section";

function toOptions(contentTypes: string[]): { [key: string]: string } {
  return contentTypes.reduce((acc, key) => {
    acc[key] = key;
    return acc;
  }, {} as { [key: string]: string });
}

export const ContentLibraryFilter: FC<{
  handleApplyFilter: () => void;
  selectedContentTypes: string[];
  setSelectedContentTypes: Dispatch<SetStateAction<string[]>>;
  contentTypes: string[];
}> = ({ handleApplyFilter, selectedContentTypes, setSelectedContentTypes, contentTypes }) => {
  const { t } = useTranslation();

  const [dialog, setDialog] = useState<boolean>(false);
  const filterIcon = useRef(null);

  const handleApply = useCallback(() => {
    handleApplyFilter();
    setDialog(false);
  }, [handleApplyFilter]);

  const handleClose = useCallback(() => {
    setDialog(false);
  }, [setDialog]);

  const handleSelectAll = useCallback(() => {
    setSelectedContentTypes(contentTypes);
  }, [contentTypes, setSelectedContentTypes]);

  const handleDeselectAll = useCallback(() => {
    setSelectedContentTypes([]);
  }, [setSelectedContentTypes]);

  const isAllSelected = useMemo(
    () => selectedContentTypes.length === contentTypes.length,
    [selectedContentTypes, contentTypes],
  );

  const isNoneSelected = useMemo(() => selectedContentTypes.length === 0, [selectedContentTypes]);

  return (
    <>
      <Box ref={filterIcon}>
        <Button
          data-analytics-id="content-library-filter"
          onClick={() => setDialog(true)}
          aria-label="filterButton"
          startIcon={<FilterAltOutlinedIcon />}
        >
          {t("Filter")}
        </Button>
      </Box>
      <Menu
        anchorEl={filterIcon.current}
        open={dialog}
        onClose={() => setDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{
            backgroundColor: "background.paper",
            color: "text.primary",
            pb: 3,
            px: 3,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Stack direction="row" spacing={2} pt={2}>
              <Button
                size="small"
                disabled={isAllSelected}
                onClick={handleSelectAll}
                data-analytics-id="content-library-filter-select-all"
              >
                {t("Select All")}
              </Button>
              <Button
                size="small"
                disabled={isNoneSelected}
                onClick={handleDeselectAll}
                data-analytics-id="content-library-filter-deselect-all"
              >
                {t("Deselect All")}
              </Button>
            </Stack>
            <FilterSection
              title="Content types"
              options={toOptions(contentTypes)}
              selectedItems={selectedContentTypes}
              onChange={(values) => {
                setSelectedContentTypes(values);
              }}
              omitAllOption
            />
          </Stack>
          <Divider />
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexWrap: "wrap",
              pt: 3,
            }}
          >
            <Box sx={{ flexGrow: 1 }} />

            <Button
              data-analytics-id="content-library-filter-menu-cancel"
              variant="outlined"
              type="reset"
              onClick={handleClose}
            >
              {t("Cancel")}
            </Button>

            <Button
              data-analytics-id="content-library-filter-menu-apply"
              type="submit"
              sx={{ ml: 2 }}
              variant="contained"
              onClick={handleApply}
            >
              {t("Apply Filter")}
            </Button>
          </Box>
        </Box>
      </Menu>
    </>
  );
};
