import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { FC, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSupabaseCallback } from "../../../../../server/supabase/hooks";
import { Database } from "../../../../../server/supabase/types/database-definitions";
import { XCircle } from "../../../../../styles/icons/x-circle";
import {
  CustomEmailDomain,
  SupabaseCustomEmailDomainService,
} from "../../server/supabase-custom-email-domain-service";

export const DeleteButton: FC<{
  customEmailDomain: CustomEmailDomain;
  onDelete: () => void;
}> = ({ customEmailDomain, onDelete }) => {
  const { t } = useTranslation();
  const [removing, setRemoving] = useState(false);

  const handleDelete = useSupabaseCallback(
    async (
      { supabase },
      emailDomain: Database["public"]["Tables"]["custom_email_domain"]["Row"],
    ): Promise<void> => {
      setRemoving(true);

      const error = await new SupabaseCustomEmailDomainService(supabase).delete(emailDomain.id);

      if (!error) {
        toast.success(t("{{domain}} deleted successfully.", { domain: emailDomain.domain }));
        onDelete();
      } else {
        toast.error(
          t("Something went wrong when deleting {{domain}}", { domain: emailDomain.domain }),
        );
        setRemoving(false);
      }
    },
    [onDelete, t],
  );
  return (
    <Tooltip title={t("Delete {{domain}}", { domain: customEmailDomain.domain })} placement="right">
      {removing ? (
        <IconButton data-analytics-id="email-domain-table-deleting">
          <CircularProgress size="small" />
        </IconButton>
      ) : (
        <IconButton
          data-analytics-id="email-domain-table-delete"
          onClick={() => {
            void handleDelete(customEmailDomain).catch(console.error);
          }}
        >
          <XCircle data-testid="XCircleIcon" fontSize="small" />
        </IconButton>
      )}
    </Tooltip>
  );
};
