/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { PostgrestMaybeSingleResponse, PostgrestSingleResponse } from "@supabase/postgrest-js";
import {
  SupabaseService,
  SupabaseServiceView,
} from "../../../server/supabase/base-supabase-service";
import { Database, Json } from "../../../server/supabase/types/database-definitions";
import { ISO8601, toISO8601 } from "../../../utils/iso8601";
import { UUID } from "../../../utils/uuid";
import { MomentCardMoment } from "../../moment-card/types";
import { Segment } from "../../segment/types";

export type MomentWithSchedule = Omit<
  Database["public"]["Views"]["moments"]["Row"],
  "text_moment_id"
> & {
  schedule: Database["public"]["Views"]["schedule_when_v2"]["Row"] | null;
};
export type MomentStatusDetail = Database["public"]["Views"]["moment_status_detail"]["Row"];

export type UsedMomentInfo = Database["public"]["Views"]["used_moments_info"]["Row"];

export type PublishMoment = Database["public"]["Tables"]["moment"]["Row"] & {
  segment: Segment;
  schedule: Database["public"]["Tables"]["schedule"]["Row"] | null;
  email_moment: Database["public"]["Tables"]["email_moment"]["Row"] | null;
  chat_moment: Database["public"]["Tables"]["chat_moment"]["Row"] | null;
};

export class SupabaseUseMomentInfoService extends SupabaseServiceView<"used_moments_info"> {
  table = "used_moments_info" as const;
}

export class SupabaseMomentService extends SupabaseService<"moment"> {
  table = "moment" as const;

  async importMoment(data: Json, source_moment_id?: UUID) {
    return await this.client.rpc("import_moment", { data, source_moment_id });
  }

  async getMomentWithSchedule(id: UUID) {
    const r = this.log_errors(
      await this.client
        .from("moments")
        .select("*, schedule:schedule_when_v2(*)")
        .eq("id", id)
        .maybeSingle(),
    );
    return r as PostgrestMaybeSingleResponse<Exclude<typeof r.data, null>>;
  }

  async getMomentToPublish(id: UUID) {
    const r = this.log_errors(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await this.client
        .from(this.table)
        .select("*, schedule(*), email_moment(*), chat_moment(*)")
        .eq("id", id)
        .single(),
    );

    return r as PostgrestSingleResponse<Omit<PublishMoment, "segment">>;
  }

  async getEventMomentCardMoments(account_id: UUID, key: string) {
    const r = this.log_errors(
      await this.getAllFromSelection(
        account_id,
        this.client
          .from("moments")
          .select<
            "*, segment(*), program!moment_program_id_fkey(*, icon(*)), schedule:schedule_when_v2!inner(*)",
            Database["public"]["Views"]["moments"]["Row"]
          >("*, segment(*), program!moment_program_id_fkey(*, icon(*)), schedule:schedule_when_v2!inner(*)")
          .eq("schedule.event_key", key),
        { order: [{ column: "id" }] },
      ),
    );
    return r;
  }

  async copyMoment(id: UUID, new_title?: string | null) {
    return this.log_errors(
      await this.client.rpc("copy_moment", new_title ? { id, new_title } : { id }),
    );
  }

  async deliveredOutsideWorkingHours(id: UUID) {
    return this.log_errors(
      await this.client.rpc("moment_delivered_outside_working_hours", { moment_id_: id }),
    );
  }
}

export class SupabaseMomentRunningService extends SupabaseService<"moment_running"> {
  table = "moment_running" as const;
  async getByMomentID(moment_id: UUID) {
    return this.log_errors(
      await this.client
        .from(this.table)
        .select("*")
        .eq("moment_id", moment_id)
        .is("deleted_at", null),
    );
  }

  async softDelete(id: UUID) {
    return this.log_errors(
      await this.client
        .from(this.table)
        .update({ deleted_at: toISO8601(new Date()) })
        .eq("moment_id", id)
        .is("deleted_at", null)
        .select("moment_id"),
    );
  }
}
export abstract class BaseSupabaseMomentCardMomentService<
  T extends "moments_recently_edited" | "moments_sent" | "moments_upcoming",
> extends SupabaseServiceView<T> {
  getAllQuery() {
    return this.client
      .from(this.table)
      .select<
        "*, segment(*), program!moment_program_id_fkey(*, icon(*)), schedule:schedule_when_v2(*)",
        Database["public"]["Views"][T]["Row"]
      >("*, segment(*), program!moment_program_id_fkey(*, icon(*)), schedule:schedule_when_v2(*)");
  }
}

export type MomentCardMomentActivity = MomentCardMoment & {
  attime: ISO8601 | null;
};

export class SupabaseMomentStatusDetailService extends SupabaseServiceView<"moment_status_detail"> {
  table = "moment_status_detail" as const;
}

export class SupabaseMomentCardMomentService extends SupabaseServiceView<"moments"> {
  table = "moments" as const;

  getAllQuery() {
    return this.client
      .from(this.table)
      .select<
        "*, segment(*), program!moment_program_id_fkey(*, icon(*)), schedule:schedule_when_v2(*)",
        Database["public"]["Views"]["moments"]["Row"]
      >("*, segment(*), program!moment_program_id_fkey(*, icon(*)), schedule:schedule_when_v2(*)");
  }
}

export class SupabaseMomentsRecentlyEditedService extends BaseSupabaseMomentCardMomentService<"moments_recently_edited"> {
  table = "moments_recently_edited" as const;
}

export class SupabaseMomentsSentService extends BaseSupabaseMomentCardMomentService<"moments_sent"> {
  table = "moments_sent" as const;
}

export type MomentCardMomentActivityStats = MomentCardMomentActivity &
  Database["public"]["Views"]["moment_history_page_stats"]["Row"];


export class SupabaseMomentsUpcomingService extends BaseSupabaseMomentCardMomentService<"moments_upcoming"> {
  table = "moments_upcoming" as const;
}

export class SupabaseEmailDesignMomentInfoService extends SupabaseServiceView<"email_design_moment_info"> {
  table = "email_design_moment_info" as const;
}
