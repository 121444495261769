import { GridGetRowsParams } from "@mui/x-data-grid-pro";
import { pick } from "lodash-es";
import { useMemo } from "react";
import {
  SentMomentData,
  SupabaseSentMomentDataService,
} from "../../../../modules/stats/server/analytics-service";
import { useSupabaseCallback } from "../../../../server/supabase/hooks";
import { toISO8601 } from "../../../../utils/iso8601";
import { TypedGridDataSource } from "../../analytics/hooks/use-grouped-moment-analytics-data";
import {
  TransformedGroupedMomentAnalytics,
  transformGroupedMomentAnalytics,
} from "../../analytics/hooks/utils";

export type TransformedSentMomentData = TransformedGroupedMomentAnalytics &
  Pick<
    SentMomentData,
    | "program_id"
    | "program_name"
    | "segment_id"
    | "segment_name"
    | "moment_title"
    | "moment_id"
    | "publisher_name"
    | "publisher_email"
  >;

export function useSentMomentDataDataSource(): TypedGridDataSource<TransformedSentMomentData> {
  const loader = useSupabaseCallback(
    async (
      { supabase, account_id },
      { start, end, sortModel, filterModel }: GridGetRowsParams,
    ): Promise<{
      rows: TransformedSentMomentData[];
      rowCount: number;
    }> => {
      // Throw error if start is not a number
      if (typeof start !== "number") throw new Error("Invalid start value");

      const service = new SupabaseSentMomentDataService(supabase);
      const query = service.client
        .from(service.table)
        .select("*", { count: "estimated" })
        .eq("account_id", account_id);

      // We only allow custom filtering by min_timestamp, and using "AND" logic
      filterModel.items.forEach((filter) => {
        if (filter.field === "min_timestamp") {
          if (!filter.value) return;

          // If it's not a Date, we don't want to filter
          if (!(filter.value instanceof Date)) return;

          if (filter.operator === "after") {
            query.gt("min_timestamp", toISO8601(filter.value));
          }

          if (filter.operator === "before") {
            query.lt("min_timestamp", toISO8601(filter.value));
          }
        }

        if (filter.field === "moment_title") {
          if (!filter.value) return;

          if (!(typeof filter.value === "string")) return;

          if (filter.operator === "contains") {
            query.ilike("moment_title", `%${filter.value}%`);
          }

          if (filter.operator === "equals") {
            query.ilike("moment_title", filter.value);
          }
        }

        if (filter.field === "channel_name") {
          if (!filter.value) return;

          if (!(typeof filter.value === "string")) return;

          query.eq("channel_name", filter.value);
        }

        if (filter.field === "segment_name") {
          if (!filter.value) return;

          if (!(typeof filter.value === "string")) return;

          query.eq("segment_name", filter.value);
        }

        if (filter.field === "program_name") {
          if (!filter.value) return;

          if (!(typeof filter.value === "string")) return;

          query.eq("program_name", filter.value);
        }
      });

      sortModel.forEach((sort) => {
        query.order(sort.field, { ascending: sort.sort === "asc" });
      });

      const resp = await query.range(start, end);

      if (resp.error) {
        throw new Error("Unable to load Moment stats");
      }

      if (!resp.data) return { rows: [], rowCount: 0 };

      const rows = resp.data.map((d, i) => ({
        ...transformGroupedMomentAnalytics(d, i),
        ...pick(d, [
          "program_id",
          "program_name",
          "segment_id",
          "segment_name",
          "moment_title",
          "moment_id",
          "publisher_name",
          "publisher_email",
        ]),
      }));

      return {
        rows,
        rowCount: resp.count ?? resp.data.length,
      };
    },
    [],
  );

  return useMemo(
    () => ({
      getRows: async (params) => {
        const resp = await loader(params);

        if (!resp) throw new Error("Unable to load Moment stats");

        return resp;
      },
    }),
    [loader],
  );
}
