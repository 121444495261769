import { Button } from "@mui/material";
import { FC, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { FileUpload, FileUploadProps } from "./file-upload";
import { Asset } from "./server/digital-asset-service";
import { UploadFileSuccessToast } from "./upload-file-success-toast";

export const UploadFileModal: FC<
  FileUploadProps & {
    onViewFileClick: (asset: Asset) => void;
  }
> = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <FileUpload
      {...props}
      renderActions={({ uploadState, handleSave, handleClose }) => (
        <Button
          disabled={!uploadState.fileUrl || !uploadState.fileTitle}
          loading={loading}
          onClick={() => {
            void (async () => {
              setLoading(true);
              const success = await handleSave();
              success && handleClose();
              setLoading(false);
            })();
          }}
          data-analytics-id="upload-file-modal-begin-upload"
          data-testid="upload-modal-button"
        >
          {t("Upload")}
        </Button>
      )}
      onSuccess={(fileTitle, asset) => {
        toast.custom(
          <UploadFileSuccessToast
            fileTitle={fileTitle}
            onClick={() => {
              if (asset) {
                props.onViewFileClick(asset);
              }
            }}
          />,
        );
      }}
    />
  );
};
