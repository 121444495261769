import { useSetAtom } from "jotai";
import { useSupabaseLoader } from "../../../server/supabase/hooks";
import { UUID } from "../../../utils/uuid";
import { EventKey, SupabaseEventService } from "../../event/server/supabase-event-service";
import { useAccountLimit } from "../../generic/hooks/use-account-limit";
import { SegmentStateService } from "../../segment/server/segment-state-service";
import { MomentLoader } from "../server/moment-loader";
import { SupabaseUseMomentInfoService, UsedMomentInfo } from "../server/supabase-moment-service";
import { MomentFormMoment } from "../types/moment-form-moment";
import { eventsAtom, segmentStateAtom } from "../types/store";

export const useMoment = (
  id: UUID | undefined,
  options?: { program_id?: UUID },
): ReturnType<typeof useSupabaseLoader<MomentFormMoment | undefined>> => {
  const setSegmentStateAtom = useSetAtom(segmentStateAtom);
  const setEventsAtom = useSetAtom(eventsAtom);

  const { data: allow_all_and_upcoming_segment, loading } = useAccountLimit(
    "allow_all_and_upcoming_segment",
  );

  return useSupabaseLoader(
    async ({ supabase, account_id }) => {
      if (!id || loading) return;

      const { data, error } = await new MomentLoader(supabase, account_id).load(id, options);

      if (error || !data) throw new Error(error || "Moment not found");

      const [segmentState, { data: events }] = await Promise.all([
        new SegmentStateService(supabase).load(
          data.id,
          account_id,
          data.segment?.id,
          allow_all_and_upcoming_segment,
        ),
        new SupabaseEventService(supabase).getKeys({
          includeLibrary: false,
          mustIncludeKey: data.schedule?.event?.key,
        }),
      ]);

      setSegmentStateAtom(segmentState);
      setEventsAtom((events ?? []) as EventKey[]);

      return data;
    },
    [id, options, setSegmentStateAtom, setEventsAtom, allow_all_and_upcoming_segment, loading],
  );
};

export function useUsedMomentsInfo(): ReturnType<typeof useSupabaseLoader<UsedMomentInfo[]>> {
  return useSupabaseLoader(async ({ supabase, account_id }) => {
    const { data, error } = await new SupabaseUseMomentInfoService(supabase).getAll(account_id, {
      order: [{ column: "id" }],
    });

    if (error || !data) return [];
    return data;
  }, []);
}
