import { serviceURL } from "../../utils/service-url";

export class ImagesService {
  async upload(
    file: Buffer | File,
    name: string,
    accessToken: string,
    contentType?: string,
    updateTrackingVersion = false,
  ): Promise<{ success: true; url: string } | { success: false; url: undefined }> {
    const resp = await fetch(
      `${serviceURL("images")}upload/${name}${updateTrackingVersion ? `?version=${Date.now()}` : ""}`,
      {
        method: "PUT",
        body: file,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          ...(contentType ? { "Content-Type": contentType } : {}),
        },
      },
    );

    if (!resp.ok) {
      return { success: false, url: undefined };
    }

    const location = resp.headers.get("location");
    if (!location) {
      return { success: false, url: undefined };
    }

    return { success: true, url: location };
  }
}
