import { DialogController, useDialog } from "../../../../../utils/hooks/use-dialog";
import { UUID } from "../../../../../utils/uuid";
import { EditModalTab } from "../segment-modal/tabbed-edit-segment-modal/tabbed-edit-segment-modal";

export type SegmentCrudDialogState =
  | { type: "add"; data: undefined }
  | { type: "edit"; data: { segment_id?: UUID; defaultTab?: EditModalTab } }
  | { type: "clone"; data: { segment_id?: UUID } }
  | {
      type: "delete";
      data: { segment_id: UUID; resolve: (value: boolean | PromiseLike<boolean>) => void };
    }
  | { type: "unarchive"; data: { segment_id: UUID; segment_name: string | null } }
  | { type: "unarchiveWithNewName"; data: { segment_id: UUID; segment_name: string | null } };

export function useSegmentCrudDatagridDialog(): DialogController<SegmentCrudDialogState> {
  return useDialog();
}
