import ImageSearch from "@mui/icons-material/ImageSearch";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useCallback, useMemo } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useFlag } from "../../server/optimizely";
import { assetLinkUrl } from "../../utils/asset-link-url";
import { Asset } from "./server/digital-asset-service";

export const LinkToAssetButton: FC<{ asset: Asset }> = ({ asset }) => {
  const [content_insertion] = useFlag("content_insertion");
  const { t } = useTranslation();
  const assetLink = useMemo(() => assetLinkUrl(asset.id), [asset.id]);

  const handleClick = useCallback(() => {
    if (!assetLink) return;

    void navigator.clipboard.writeText(assetLink);
    toast.success(t("Link copied to clipboard"));
  }, [assetLink, t]);

  return (
    content_insertion &&
    assetLink && (
      <Box>
        <Button
          data-analytics-id="content-library-link-to-preview"
          onClick={handleClick}
          startIcon={<ImageSearch />}
          variant="text"
          size="small"
          sx={{
            p: 0,
            m: 0,
            "&:hover": { backgroundColor: "transparent", textDecoration: "underline" },
          }}
        >
          <Typography variant="caption" color="primary">
            {t("Copy link to preview")}
          </Typography>
        </Button>
      </Box>
    )
  );
};
