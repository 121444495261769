/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SupabaseService } from "../../../server/supabase/base-supabase-service";

import { DayOfWeek } from "../../../utils/day-of-week";
import { IntervalJson } from "../../../utils/interval";
import { INTERVAL, ISO8601_DATE, ISO8601_TIME } from "../../../utils/iso8601";

export type ScheduleWhen = { when: string };
export class SupabaseScheduleService extends SupabaseService<"schedule"> {
  table = "schedule" as const;

  async getNextSends(
    date: ISO8601_DATE,
    event_initial: boolean,
    event_number_of_repeats: number,
    repeat: INTERVAL,
    offset: IntervalJson,
    dow: DayOfWeek,
    send_at: ISO8601_TIME,
    time_zone: string,
    series_length?: number,
  ) {
    return this.log_errors(
      await this.client
        .rpc("naive_next_sends", {
          date,
          event_initial,
          event_number_of_repeats,
          repeat,
          offset,
          dow,
          send_at,
          time_zone,
          series_length: series_length ?? 5,
        })
        .select(),
    );
  }
}
