import { useSupabaseLoader } from "../../../server/supabase/hooks";
import { UUID } from "../../../utils/uuid";
import {
  MomentStatusDetail,
  SupabaseMomentStatusDetailService,
} from "../../moment/server/supabase-moment-service";

export function useSegmentRelatedMoments(
  segment_id: UUID,
): ReturnType<typeof useSupabaseLoader<MomentStatusDetail[]>> {
  return useSupabaseLoader(
    async ({ supabase, account_id }) => {
      const { data, error } = await new SupabaseMomentStatusDetailService(supabase).getAll(
        account_id,
        {
          order: [{ column: "id" }],
          filter: [
            { key: "segment_id", operator: "eq", value: segment_id },
            { key: "status", operator: "neq", value: "archived" },
          ],
        },
      );

      if (error || !data) return [];
      return data;
    },
    [segment_id],
  );
}
