import { useAtomCallback } from "jotai/utils";
import { find } from "lodash-es";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { useAsset } from "../../modules/assets/hooks/use-asset";
import { useUpdateAssetCallback } from "../../modules/assets/hooks/use-upsert-asset";
import { ErrorModal } from "../../modules/design-huddle/error-modal";
import { useDesignHuddle } from "../../modules/design-huddle/use-design-huddle";
import { useDesignHuddleProjectClone } from "../../modules/design-huddle/use-design-huddle-project-clone";
import { EditContent } from "../../modules/edit-content/edit-content";
import { LoadingModal } from "../../modules/edit-content/loading-modal";
import { SaveAssetHandler } from "../../modules/edit-content/types";
import { existingProjectFamily } from "../../modules/generate/store";
import { Page } from "../../modules/layout/components/page";
import { useApiLoader } from "../../server/api/hooks/use-api-loader";
import { useDialog } from "../../utils/hooks/use-dialog";
import { UUID } from "../../utils/uuid";

const AssetPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams() as { id: UUID };
  const { data: asset, loading: assetLoading, error: assetRetrievalError } = useAsset(id);

  const { loading: designHuddleLoading, error: designHuddleError } =
    useDesignHuddle(asset && asset.project_id ? [asset.project_id] : []) ||
    asset?.project_id === "dummy";

  const [ready, setReady] = useState(false);
  const handleReady = useCallback(() => setReady(true), []);
  const errorDialog = useDialog();

  const cloneProject = useDesignHuddleProjectClone();

  const getProjectData = useAtomCallback(
    useCallback((get) => get(existingProjectFamily(asset?.project_id)), [asset?.project_id]),
  );
  const [pageNumber, setPageNumber] = useState<number>();

  useEffect(() => {
    const loadProjectAndFindPage = async (): Promise<void> => {
      if (!asset?.page_id) {
        setPageNumber(1);
        return;
      }

      try {
        const project = await getProjectData();
        const page = find(project?.pages, { page_id: asset.page_id });

        if (page) {
          setPageNumber(page.page_number);
        }
      } catch (e) {
        console.error(e);
        errorDialog.handleOpen();
      }
    };

    if (!errorDialog.open) {
      void loadProjectAndFindPage();
    }
  }, [asset?.project_id, asset?.page_id, getProjectData, errorDialog]);

  const {
    data: projectId,
    error: projectCloneError,
    loading: cloning,
  } = useApiLoader(async () => {
    if (!designHuddleLoading && asset?.project_id) {
      return await cloneProject(asset?.project_id, asset?.page_id);
    }
  }, [asset?.page_id, asset?.project_id, cloneProject, designHuddleLoading]);

  useEffect(() => {
    if (
      !errorDialog.open &&
      (designHuddleError || Boolean(assetRetrievalError) || Boolean(projectCloneError))
    ) {
      errorDialog.setOpen(true);
    }
  }, [errorDialog, designHuddleError, assetRetrievalError, projectCloneError]);

  const updateAsset = useUpdateAssetCallback();

  const handleSaveAsset: SaveAssetHandler = useCallback(
    (opts) => {
      if (!asset) return Promise.reject(new Error("Asset not found"));

      return updateAsset({
        asset,
        projectId,
        ...opts,
      });
    },
    [asset, projectId, updateAsset],
  );

  const handleCloseErrorDialog = useCallback(() => {
    if (window.history.length > 1) {
      void navigate(-1);
    } else {
      void navigate("/content-library");
    }
    errorDialog.handleClose();
  }, [errorDialog, navigate]);

  if (errorDialog.open) {
    return <ErrorModal open={errorDialog.open} handleClose={handleCloseErrorDialog} />;
  }
  if (!asset || !pageNumber) return null;

  return (
    <Page title={t(asset.title)} sx={{ height: "100%" }}>
      {projectId && (
        <EditContent
          assetId={asset.id}
          title={asset.title}
          projectId={projectId}
          page={pageNumber}
          onReady={handleReady}
          onSave={handleSaveAsset}
        />
      )}
      {asset.project_id !== "dummy" && (
        <LoadingModal open={!ready || designHuddleLoading || cloning || assetLoading} />
      )}
    </Page>
  );
};

export default AssetPage;
