import yup from "../../../utils/yup";

export const emailLayoutSettingsSchema = yup.object({
  background_colour: yup.string().default("#ffffff"),
  paper_colour: yup.string().default("#ffffff"),
  max_paper_width: yup.number().default(650),
  paper_padding_side: yup.number().default(35),
  paper_padding_bottom: yup.number().default(35),
  paper_padding_top: yup.number().default(0),
  paragraph_padding: yup.number().default(10),
  border: yup.object({
    width: yup.number().default(0),
    colour: yup.string().default("#000000"),
  }),
  column_spacing_type: yup.string().oneOf(["spacing", "padding", "none"]).default("spacing"),
  column_spacing: yup.number().nullable().default(5),
  column_padding: yup.number().nullable().default(null),
});
export type EmailLayoutSettings = yup.InferType<typeof emailLayoutSettingsSchema>;

export const emailFooterLayoutSettingsSchema = yup.object({
  paper_padding_side: yup.number().default(35),
  paper_padding_bottom: yup.number().default(35),
  paper_padding_top: yup.number().default(0),
});
export type EmailFooterLayoutSettings = yup.InferType<typeof emailFooterLayoutSettingsSchema>;
