import ZoomInRounded from "@mui/icons-material/ZoomInRounded";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardProps,
  Chip,
  Fab,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { FC, useState } from "react";
import { FileIconWithChip } from "../generic/components/icon-with-chip";
import { Asset } from "./server/digital-asset-service";

export const AssetCard: FC<
  { asset: Asset; selected?: boolean; disableZoomIcon?: boolean } & CardProps
> = ({ asset, selected, disableZoomIcon = false, ...props }) => {
  const [hover, setHovering] = useState<boolean>(false);

  return (
    <Card
      variant="outlined"
      {...props}
      sx={selected ? { borderWidth: "4px", borderColor: "primary.main" } : {}}
    >
      <CardActionArea
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <Box
          sx={{
            height: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...(asset.mime_type !== "application/pdf"
              ? {
                  backgroundImage: `url(${asset.thumbnail_url})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                }
              : {}),
          }}
        >
          <>
            {asset.content_type === "Uploaded File" && asset.mime_type === "application/pdf" && (
              <FileIconWithChip />
            )}
            {selected && hover && !disableZoomIcon && (
              <Fab color="primary">
                <ZoomInRounded />
              </Fab>
            )}
          </>
        </Box>
        <CardContent>
          <Stack spacing={2}>
            <Typography variant="subtitle1" noWrap textOverflow="ellipsis">
              {asset.title}
            </Typography>
            {asset.content_type && (
              <Stack direction="row">
                <Chip
                  label={asset.content_type}
                  data-testid={`${asset.content_type}-${asset.title}`}
                />
              </Stack>
            )}
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
