import { Box, Stack } from "@mui/material";
import { DataGridPro, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AnalyticsDatetime } from "../../generic/analytics-datetime";
import { ReactionAvatar } from "./reaction-avatar";
import { ReactionsByPerson } from "./types";

type RowType = ReactionsByPerson["reactions"][0] & { id: number };

export const NestedPersonTable: FC<{ row: ReactionsByPerson }> = ({ row }) => {
  const { t } = useTranslation();

  const columns: GridColDef<RowType>[] = useMemo(
    () => [
      {
        field: "reaction",
        headerName: t("Emoji"),
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<RowType, RowType["reaction"]>) => (
          <ReactionAvatar reaction={params.value} />
        ),
      },
      {
        field: "timestamp",
        headerName: t("Reaction Time"),
        flex: 4,
        type: "dateTime",
        renderCell: (params: GridRenderCellParams<RowType, Date>) =>
          params.value ? (
            <Stack direction="row" spacing={1} alignItems="center" height="100%">
              <AnalyticsDatetime datetime={params.value} />
            </Stack>
          ) : null,
      },
    ],
    [t],
  );

  const reactions = useMemo(
    () => row.reactions.map((reaction, index) => ({ id: index, ...reaction })),
    [row],
  );

  return (
    <Box sx={{ ml: "50px" }}>
      <DataGridPro
        hideFooterRowCount
        rows={reactions}
        columns={columns}
        disableRowSelectionOnClick
      />
    </Box>
  );
};
