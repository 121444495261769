import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Stack } from "@mui/material";
import { DataGridPro, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  filterStatType,
  GroupedMomentAnalyticsData,
} from "../../../../../modules/stats/server/analytics-service";
import { fromISO8601, ISO8601 } from "../../../../../utils/iso8601";
import { AnalyticsDatetime } from "../../generic/analytics-datetime";
import { EmptyState } from "../../generic/empty-state";
import { NestedPersonTable } from "./nested-person-table";
import { ReactionsByPerson } from "./types";

export const AnalyticsSendsTableExpandedReactionsByPerson: FC<{
  stats: GroupedMomentAnalyticsData["stats"];
}> = ({ stats }) => {
  const { t } = useTranslation();

  const { data } = useMemo(() => {
    const reactions = filterStatType("reaction", stats);

    const mapped = reactions.reduce(
      (acc, { data: innerData }) => {
        const id = innerData.reactor_data?.person_id ?? "unknown";

        if (!acc[id])
          acc[id] = {
            first_name: innerData.reactor_data?.first_name ?? t("-"),
            last_name: innerData.reactor_data?.last_name ?? t("-"),
            reactions: [],
          };

        acc[id].reactions.push({
          timestamp: fromISO8601(innerData.timestamp),
          reaction: {
            emoji: innerData.reaction,
            custom_url: innerData.custom_url,
          },
        });

        return acc;
      },
      {} as Record<string, ReactionsByPerson>,
    );

    return { data: Object.values(mapped) };
  }, [stats, t]);

  const rows = useMemo(() => {
    return data.map((item, id) => {
      return {
        id,
        first_name: item.first_name,
        last_name: item.last_name,
        reactions: item.reactions,
        count: item.reactions.length,
        first_reaction: item.reactions.reduce(
          (acc, { timestamp }) => (acc < timestamp ? acc : timestamp),
          new Date("2999-12-31"),
        ),
        last_reaction: item.reactions.reduce(
          (acc, { timestamp }) => (acc > timestamp ? acc : timestamp),
          new Date("1970-01-01"),
        ),
      };
    });
  }, [data]);

  const columns = useMemo(() => {
    const columnData: GridColDef<(typeof rows)[0]>[] = [
      {
        field: "first_name",
        headerName: t("First Name"),
        flex: 1,
      },
      {
        field: "last_name",
        headerName: t("Last Name"),
        flex: 1,
      },
      {
        field: "count",
        headerName: t("Total Reactions"),
        flex: 0.5,
      },
      {
        field: "first_reaction",
        headerName: t("First Reaction"),
        flex: 2,
        renderCell: (params: GridRenderCellParams<(typeof rows)[0], ISO8601>) =>
          params.value ? (
            <Stack direction="row" spacing={1} alignItems="center" height="100%">
              <AnalyticsDatetime datetime={params.value} />
            </Stack>
          ) : null,
      },
      {
        field: "last_reaction",
        headerName: t("Most Recent Reaction"),
        flex: 2,
        renderCell: (params: GridRenderCellParams<(typeof rows)[0], ISO8601>) =>
          params.value ? (
            <Stack direction="row" spacing={1} alignItems="center" height="100%">
              <AnalyticsDatetime datetime={params.value} />
            </Stack>
          ) : null,
      },
    ];
    return columnData;
  }, [t]);

  if (rows.length === 0)
    return (
      <EmptyState symbol={"😴"} title={t("No reactions yet")} subtitle={t("Check back soon")} />
    );

  return (
    <Stack spacing={2}>
      <DataGridPro
        pagination
        pageSizeOptions={[10, 25, 50, 100]}
        initialState={{ pagination: { paginationModel: { pageSize: 25 } } }}
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        getDetailPanelContent={({ row }) => <NestedPersonTable row={row} />}
        getDetailPanelHeight={() => "auto"}
        slots={{
          detailPanelCollapseIcon: ExpandLess,
          detailPanelExpandIcon: ExpandMore,
        }}
      />
    </Stack>
  );
};
