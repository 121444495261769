import { WithAuthenticationRequiredOptions, useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import "draft-js/dist/Draft.css";
import { ComponentType, FC } from "react";
import { ErrorSplashScreen } from "./modules/generic/components/splash-screen";
import NotFound from "./pages/not-found";
import { usePermissions } from "./server/auth/use-permissions";

type Props<P> = {
  Component: ComponentType<P>;
  type?: "super_admin" | "ce_admin";
  options?: WithAuthenticationRequiredOptions;
} & P;

export const ProtectedRoute = <P extends object>({
  Component,
  type,
  options = {},
  ...props
}: Props<P>): React.JSX.Element => {
  const { error } = useAuth0();
  if(error) return <ErrorSplashScreen />;
  const AuthenticatedComponent = withAuthenticationRequired<P>(
    withPermissions(Component, type),
    options,
  );
  return <AuthenticatedComponent {...props as P} />;
};

const withPermissions = <P extends object>(
  Component: ComponentType<P>,
  type?: "super_admin" | "ce_admin",
): FC<P> => {
  return function WithPermissions(props: P): React.JSX.Element {
    const perms = usePermissions();

    if (type && !perms?.[type]) {
      return <NotFound />;
    }

    return <Component {...props} />;
  };
};
