import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";
import { useSupabaseCallback } from "../../../../../server/supabase/hooks";
import {
  CustomEmailDomain,
  SupabaseCustomEmailDomainService,
} from "../../server/supabase-custom-email-domain-service";
import { customEmailDomainSchema } from "../../types";

export const AddCustomEmailDomain: FC<{
  onAdd: (domain: CustomEmailDomain) => void;
}> = ({ onAdd }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const { handleSubmit, control } = useForm({
    defaultValues: { domain: "" },
    resolver: yupResolver(customEmailDomainSchema),
  });

  const onSubmit = useSupabaseCallback(
    async ({ supabase }, values: { domain: string }): Promise<void> => {
      setSaving(true);

      const service = new SupabaseCustomEmailDomainService(supabase);

      const { data, error } = await service.insert({ cname_records: [], domain: values.domain });

      setSaving(false);

      if (error || !data) {
        toast.error(
          t("Something went wrong when adding {{domain}}", {
            domain: values.domain,
          }),
        );
        return;
      }

      toast.success(
        t("{{domain}} added successfully.", {
          domain: values.domain,
        }),
      );
      onAdd(data);
      setOpen(false);
    },
    [onAdd, t],
  );

  const handleClickOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    // Prevent closing the dialog if we're saving
    if (!saving) {
      setOpen(false);
    }
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form id="create-domain-form" onSubmit={handleSubmit(onSubmit)}>
      <Button
        data-analytics-id="email-domain-table-add-domain"
        color="primary"
        size="large"
        sx={{ mt: 3, mb: 4 }}
        variant="contained"
        onClick={handleClickOpen}
      >
        {t("Add Domain")}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("Add Domain")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans>
              Verify an entire domain. When you verify a new domain, we provide a set of DNS
              records. You have to add these records to the DNS configuration of the domain.
            </Trans>
          </DialogContentText>
          <Controller
            name="domain"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                margin="dense"
                id="domain"
                label={t("Domain")}
                type="text"
                fullWidth
                variant="standard"
                value={field.value}
                placeholder="example.com"
                onChange={field.onChange}
                helperText={error?.message}
                error={Boolean(error)}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-analytics-id="email-domain-add-domain-dialog-add"
            disabled={saving}
            type="submit"
            form="create-domain-form"
          >
            {saving ? <CircularProgress size={20} /> : t("Add")}
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};
