import { RawDraftContentState } from "draft-js";
import { draftToMarkdown, DraftToMarkdownOptions } from "markdown-draft-js";

export const toMarkdown = (content: RawDraftContentState): string =>
  draftToMarkdown(content, {
    preserveNewlines: false,
    escapeMarkdownCharacters: false,
    styleItems: supportedMrkdwnStyles,
    entityItems: supportedEntities,
  });

const unsupported = {
  open: function () {
    return "";
  },

  close: function () {
    return "";
  },
};

const supportedMrkdwnStyles: DraftToMarkdownOptions["styleItems"] = {
  // Block level styles
  "unordered-list-item": {
    open: function () {
      return " • ";
    },

    close: function () {
      return "";
    },
  },

  "ordered-list-item": {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    open: function (block = null, number = 1) {
      return ` ${number}. `;
    },

    close: function () {
      return "";
    },
  },

  blockquote: unsupported,
  "header-one": unsupported,
  "header-two": unsupported,
  "header-three": unsupported,
  "header-four": unsupported,
  "header-five": unsupported,
  "header-six": unsupported,
  "code-block": unsupported,
  CODE: unsupported,

  // Inline styles
  BOLD: {
    open: function () {
      return "*";
    },

    close: function () {
      return "*";
    },
  },
  ITALIC: {
    open: function () {
      return "_";
    },

    close: function () {
      return "_";
    },
  },
};

export const supportedEntities: DraftToMarkdownOptions["entityItems"] = {
  LINK: {
    open: function (entity) {
      // Hopfully thier types are wrong.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return `<${entity?.data?.url || entity?.data?.href}|`;
    },

    close: function () {
      return `>`;
    },
  },
};
