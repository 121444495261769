import Add from "@mui/icons-material/Add";
import { Button, FormControlLabel, FormGroup, Stack, Switch } from "@mui/material";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

export const SegmentCrudDataGridHeader: FC<{
  openAddDialog: () => void;
  showArchived: boolean;
  setShowArchived: (showArchived: boolean) => void;
  is_recipient_type: boolean;
}> = ({ openAddDialog, showArchived, setShowArchived, is_recipient_type }) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
      <Button
        data-analytics-id={`segment-grid-add-${is_recipient_type ? "audience" : "segment"}`}
        color="primary"
        startIcon={<Add />}
        onClick={openAddDialog}
      >
        <Trans>Add {is_recipient_type ? "Audience" : "Segment"}</Trans>
      </Button>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch checked={showArchived} onChange={(_, checked) => setShowArchived(checked)} />
          }
          label={t("Show archived?")}
          labelPlacement="start"
        />
      </FormGroup>
    </Stack>
  );
};
