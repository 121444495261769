import yup from "../../../utils/yup";

const emailHeadingTextSettings = yup.object({
  font_size: yup.number().default(24),
  bold: yup.boolean().default(false),
  font_colour: yup.string().default("#000000"),
});

export const emailTextSettingsSchema = yup.object({
  font: yup.string().default("Arial"),
  fallback_font: yup.string().nullable(),
  enable_web_fonts: yup.boolean().default(false),
  font_size: yup.number().default(16),
  font_colour: yup.string().default("#000000"),
  line_height: yup.number().default(1.5),
  header_font: yup.string().default("Arial"),
  header_fallback_font: yup.string().nullable(),
  heading1: emailHeadingTextSettings.default({
    ...emailHeadingTextSettings.getDefault(),
    font_size: 26,
    font_colour: "#4A4A4A",
  }),
  heading2: emailHeadingTextSettings.default({
    ...emailHeadingTextSettings.getDefault(),
    font_size: 24,
    font_colour: "#4A4A4A",
  }),
  heading3: emailHeadingTextSettings.default({
    ...emailHeadingTextSettings.getDefault(),
    font_size: 22,
    font_colour: "#4A4A4A",
  }),
  heading4: emailHeadingTextSettings.default({
    ...emailHeadingTextSettings.getDefault(),
    font_size: 18,
    font_colour: "#4A4A4A",
  }),
  link_color: yup.string().default("#0000EE"),
});

export type EmailHeadingTextSettings = yup.InferType<typeof emailHeadingTextSettings>;
export type EmailTextSettings = yup.InferType<typeof emailTextSettingsSchema>;
