import "draft-js/dist/Draft.css";
import { lazy } from "react";
import { Navigate, Route } from "react-router";
import { App } from "./app";
import { SplashScreen } from "./modules/generic/components/splash-screen";
import { ApplicationLayout } from "./modules/layout/components/application-layout";
import { GenerateLayout } from "./modules/layout/components/generate-layout";
import { CeAdminLayout, Layout } from "./modules/layout/components/layout";
import MomentCardCopyPage from "./modules/moment-card/components/drag-and-drop/moment-card-copy-page";
import CeAdminDesignHuddleBrandPage from "./pages/ce-admin/ce-admin-design-huddle-brand-page";
import CeAdminPage from "./pages/ce-admin/ce-admin-page";
import CreateAccount from "./pages/ce-admin/create-account-page";
import ManageDiscoverPage from "./pages/ce-admin/manage-discover-page";
import EmailAddressConfigurationPage from "./pages/channels/email/email-address-configuration-page";
import EmailDomainConfigurationPage from "./pages/channels/email/email-domain-configuration-page";
import SlackPage from "./pages/channels/slack-page";
import AssetPage from "./pages/content-library/asset-page";
import ContentLibraryPage from "./pages/content-library/content-library-page";
import CreateContentEditPage from "./pages/create-content/create-content-edit-page";
import CreateContentPage from "./pages/create-content/create-content-page";
import CreateContentPreviewPage from "./pages/create-content/create-content-preview-page";
import DiscoverPage from "./pages/discover/discover-page";
import DiscoverProgramPage from "./pages/discover/discover-program-page";
import DiscoverSearchPage from "./pages/discover/discover-search-page";
import DiscoverSequencePage from "./pages/discover/discover-sequence-page";
import DiscoverTemplatePage from "./pages/discover/discover-template-preview-page";
import EventsPage from "./pages/events/events-page";
import LogoutPage from "./pages/logout/logout-page";
import EmployeeFieldsPage from "./pages/manage/employee-fields-page";
import ManageChannelsPage from "./pages/manage/manage-channels-page";
import ManageEmployeesPage from "./pages/manage/manage-employees-page";
import ManageEventsAndTagsPage from "./pages/manage/manage-events-and-tags-page";
import ManageProgramsPage from "./pages/manage/manage-programs-page";
import ManageSegmentsPage from "./pages/manage/manage-segments-page";
import CopyMomentPage from "./pages/moments/copy/copy-moment-page";
import MomentsDashboardPage from "./pages/moments/dashboard/moments-dashboard-page";
import GenerateMomentPage from "./pages/moments/generate/generate-moment-page";
import SentMomentsPage from "./pages/moments/history/sent-moments-page";
import NewMomentPage from "./pages/moments/new-moment-page";
import NotFound from "./pages/not-found";
import OutboxRecipientPage from "./pages/outbox-recipient-page";
import RecipientActivityPage from "./pages/people/recipient-activity-page";
import PipePage from "./pages/pipe/pipe-page";
import ProgramsPage from "./pages/programs-page";
import RecipePage from "./pages/recipes/recipe-page";
import RecipesPage from "./pages/recipes/recipes-page";
import TabbedProgramsPage from "./pages/tabbed-programs-page";
import TabbedProgramPage from "./pages/tabbed-programs-page/tabbed-program-page";
import TimelinePage from "./pages/timeline/timeline-page";
import { ProtectedRoute } from "./protected-route";

const MomentFormPage = lazy(() => import("./pages/moments/moment-form-page"));
const CalendarPage = lazy(() => import("./pages/calendar/calendar-page"));
const PersonPage = lazy(() => import("./pages/people/person-page"));
const AnalyticsPage = lazy(() => import("./pages/analytics/analytics-page"));
const BrandKitPage = lazy(() => import("./pages/brand-kit/brand-kit-page"));


export const Routes = (
  <>
    <Route path="/" element={<App />}>
      <Route index element={<SplashScreen />}></Route>
      <Route element={<GenerateLayout />}>
        <Route path="/generate-moment" element={<GenerateMomentPage />} />
      </Route>
      <Route element={<ProtectedRoute Component={Layout} />}>
        <Route path="/moments/:id" element={<MomentFormPage />} />
        <Route path="/moments/:id/*" element={<MomentFormPage />} />
        <Route path="/moments/:id/newsletter" element={<MomentFormPage isNewsletter />} />
        <Route path="/moments/:id/newsletter/*" element={<MomentFormPage isNewsletter />} />
        <Route path="/moments/dashboard" element={<MomentsDashboardPage />} />
        <Route path="/programs/*" element={<ProgramsPage />} />
        <Route path="/tabbed-programs" element={<TabbedProgramsPage />} />
        <Route path="/tabbed-programs/:id" element={<TabbedProgramPage />} />
        <Route path="/moments" element={<NewMomentPage />} />
        <Route path="/moments/newsletter" element={<NewMomentPage newsletter />} />
        <Route path="/moments/copy/:id" element={<CopyMomentPage />} />
        <Route path="/create-content" element={<CreateContentPage />} />
        <Route path="/create-moment" element={<Navigate replace to="/create-content" />} />
        <Route path="/create-content/preview" element={<CreateContentPreviewPage />} />
        <Route path="/generate-moment" element={<GenerateMomentPage />} />
        <Route path="/timeline/:id" element={<TimelinePage />} />
        <Route path="/calendar" element={<CalendarPage />} />
        <Route path="/recipes" element={<RecipesPage />} />
        <Route path="/recipes/:id" element={<RecipePage />} />

        <Route path="/content-library" element={<ContentLibraryPage />} />
        <Route path="/moments/history" element={<SentMomentsPage />} />

        {/* Superadmin */}
        <Route
          path="/analytics/*"
          element={<ProtectedRoute type="super_admin" Component={AnalyticsPage} />}
        />

        <Route
          path="/recipient-activity"
          element={<ProtectedRoute type="super_admin" Component={RecipientActivityPage} />}
        />
        <Route
          path="/recipient-activity/:person_id/*"
          element={<ProtectedRoute type="super_admin" Component={PersonPage} />}
        />
        <Route
          path="/channels/email/custom-address"
          element={<ProtectedRoute type="super_admin" Component={EmailAddressConfigurationPage} />}
        />
        <Route
          path="/channels/email/custom-domain"
          element={<ProtectedRoute type="super_admin" Component={EmailDomainConfigurationPage} />}
        />
        <Route
          path="/channels/slack"
          element={<ProtectedRoute type="super_admin" Component={SlackPage} />}
        />

        <Route
          path="/manage/employees/*"
          element={<ProtectedRoute type="super_admin" Component={ManageEmployeesPage} />}
        />
        <Route
          path="/manage/channels/*"
          element={<ProtectedRoute type="super_admin" Component={ManageChannelsPage} />}
        />
        <Route
          path="/manage/employee_data/*"
          element={<ProtectedRoute type="super_admin" Component={ManageEventsAndTagsPage} />}
        />
        <Route
          path="/events"
          element={<ProtectedRoute type="super_admin" Component={EventsPage} />}
        />
        <Route
          path="/manage/segments/*"
          element={<ProtectedRoute type="super_admin" Component={ManageSegmentsPage} />}
        />
        <Route
          path="/manage/fields"
          element={<ProtectedRoute type="super_admin" Component={EmployeeFieldsPage} />}
        />
        <Route
          path="/manage/programs"
          element={<ProtectedRoute type="super_admin" Component={ManageProgramsPage} />}
        />
        <Route
          path="/manage/brand/*"
          element={<ProtectedRoute type="super_admin" Component={BrandKitPage} />}
        />
        <Route
          path="/outbox"
          element={<ProtectedRoute type="super_admin" Component={OutboxRecipientPage} />}
        />

        <Route path="/discover/:program_slug/:template_slug" element={<DiscoverTemplatePage />} />

        <Route path="*" element={<NotFound />} />
      </Route>

      <Route element={<ProtectedRoute Component={ApplicationLayout} layout="sequence" />}>
        <Route path="/create-content/edit" element={<CreateContentEditPage />} />
        <Route path="/content-library/:id" element={<AssetPage />} />
        <Route path="/discover/sequence/:sequence_slug" element={<DiscoverSequencePage />} />
        <Route
          path="/discover/sequence/:sequence_slug/:template_slug"
          element={<DiscoverSequencePage />}
        />
      </Route>

      <Route element={<ProtectedRoute Component={Layout} themeOverride="dark" />}>
        <Route path="/discover" element={<DiscoverPage />} />
        <Route path="/discover/search" element={<DiscoverSearchPage />} />
        <Route path="/discover/:program_slug" element={<DiscoverProgramPage />} />
      </Route>

      <Route element={<ProtectedRoute type="ce_admin" Component={CeAdminLayout} />}>
        <Route path="/ce_admin" element={<CeAdminPage />} />
        <Route path="/ce_admin/create_account" element={<CreateAccount />} />
        <Route path="/ce_admin/manage_discover" element={<ManageDiscoverPage />} />
        <Route
          path="/ce_admin/design_huddle_custom_brands"
          element={<CeAdminDesignHuddleBrandPage />}
        />

        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="/logout" element={<LogoutPage />} />
    </Route>
    <Route path="/pipe" element={<PipePage />} />
    <Route path="/moment-card-copy" element={<MomentCardCopyPage />} />
  </>
);
