import { RawDraftContentState } from "draft-js";
import { ValidationError } from "yup";
import { config } from "../../../config";
import { imageFetcher } from "../../../utils/server-fetcher";
import { toMarkdown } from "../../chat-moment/components/chat-editor/chat-styling/utils";
import { Tags } from "../../chat-moment/components/chat-editor/token-picker";
import { ChatMoment } from "../../chat-moment/types";
import { imageSizeLimit } from "../../lexical-chat-moment/image-size-limit";
import { validMergeTags } from "../../merge-tag/util";
import { SerializedButtonListNode } from "../../message/editor/nodes/button-list-node";
import { SerializedImageNode } from "../../message/editor/nodes/image-node";
import { getCurlyBraceBalance } from "../../message/editor/plugins/typeahead/merge-tag-utils";
import { SerialisedEditorDesign } from "../../message/editor/types/serialised-editor-design";
import { editorIsEmpty } from "../../message/editor/utils/editor-is-empty";
import { ToTarget } from "../../to/use-to-target";
import { SerializedDesignHuddleNode } from "../../message/editor/nodes/design-huddle-node";

type Node = {
  type: string;
  children?: Node[];
  text?: string;
  key?: string;
};

const TOP_LEVEL_NODES = [
  "paragraph",
  "list",
  "image",
  "divider",
  "buttonlist",
  "design-huddle",
  "heading",
];

const ALLOWED: { [key: string]: string[] } = {
  root: TOP_LEVEL_NODES,
  paragraph: ["text", "link", "autolink", "merge-tag", "emoji", "linebreak", "tab", "merge-tag-v2"],
  list: ["listitem"],
  listitem: [
    "text",
    "link",
    "autolink",
    "merge-tag",
    "emoji",
    "linebreak",
    "tab",
    "list",
    "merge-tag-v2",
  ],
  link: ["text", "merge-tag", "emoji", "linebreak", "tab", "merge-tag-v2"],
  autolink: ["text", "merge-tag", "emoji", "linebreak", "tab"],
  heading: ["text", "link", "autolink", "merge-tag", "emoji", "linebreak", "tab", "merge-tag-v2"],
};

const TOP_LEVEL_NODES_EMAIL = [...TOP_LEVEL_NODES, "styleablebutton", "table", "paper-color"];

const ALLOWED_EMAIL: { [key: string]: string[] } = {
  ...ALLOWED,
  root: TOP_LEVEL_NODES_EMAIL,
  table: ["tablerow"],
  tablerow: ["tablecell"],
  tablecell: TOP_LEVEL_NODES_EMAIL.filter((x) => x !== "table"),
};
const INVALID_REASONS = [
  {
    parent: "tablecell",
    child: "table",
    message: "Message not supported, Columns cannot be nested within other Columns",
  },
];

export const isValidNest = (
  { type, children }: Node,
  channel: string | undefined,
): { valid: true } | { valid: false; invalidReason: string; key?: string } => {
  const a = channel === "work_email" || channel === "personal_email" ? ALLOWED_EMAIL : ALLOWED;

  for (const child of children ?? []) {
    if (!(type in a && a[type].includes(child.type))) {
      const invalidReason =
        INVALID_REASONS.find((x) => x.parent === type && x.child === child.type)?.message ??
        "Message not supported, please simplify the layout";
      return { valid: false, invalidReason, key: child.key };
    }

    const validNest = isValidNest(child, channel);
    if (!validNest.valid) {
      return validNest;
    }
  }

  return { valid: true };
};

const checkMergeTagBalance = ({ type, children, text }: Node): boolean => {
  if (type === "text") return getCurlyBraceBalance(text as string).balanced;

  return children?.every((child) => checkMergeTagBalance(child)) ?? true;
};

const checkButtonListContainButtons = (node: Node): boolean => {
  if (node.type === "buttonlist") {
    const buttonNode = node as SerializedButtonListNode;
    return buttonNode.buttons.length !== 0;
  }

  return node.children?.every((child) => checkButtonListContainButtons(child)) ?? true;
};

const checkGifFileSize = async (node: Node, channel: string | undefined): Promise<boolean> => {
  if (!channel || !["slack", "teams"].includes(channel)) return true;
  const allGifImages =
    (node.children?.filter(
      (child) => child.type === "image" && (child as SerializedImageNode).url.endsWith(".gif"),
    ) as SerializedImageNode[]) ?? [];

  const responses = await Promise.all(
    allGifImages.map((gif) =>
      (gif.url.includes("changeengine.com") ? imageFetcher : fetch)(gif.url, { method: "HEAD" }),
    ),
  );

  return responses.every((response) => {
    const size = response.headers.get("content-length") as unknown as number;
    console.log("Sending .gif of size: ", size);

    if (!size || !response.ok) return false;

    const sizeInMb = size / 1024 / 1024;
    return sizeInMb < imageSizeLimit(channel as "slack" | "teams");
  });
};

export const validateMomentDesign = (
  design: unknown,
  channel: string | undefined,
  createError: (message: string) => ValidationError,
): boolean | ValidationError | Promise<boolean | ValidationError> => {
  const serializedState = design as SerialisedEditorDesign;
  if (editorIsEmpty(serializedState)) {
    return createError("Enter a message");
  }

  const validNest = isValidNest(serializedState.root, channel);
  if (!validNest.valid) {
    console.error("Unsupported message", serializedState.root);
    return createError(validNest.invalidReason);
  }
  if (!checkMergeTagBalance(serializedState.root)) {
    return createError("Unbalanced merge tags found, ensure all merge tags are closed properly");
  }
  if (!checkButtonListContainButtons(serializedState.root)) {
    return createError(
      "ButtonLists found without Buttons, please ensure all ButtonLists contain at least one Button",
    );
  }

  return checkGifFileSize(serializedState.root, channel).then((validSize) => {
    if (validSize) return true;

    return createError(
      `GIF in message content is too large, ensure all images are less than ${imageSizeLimit(channel as "teams" | "slack")}MB`,
    );
  });
};

export const validateChatMoment = (
  { design }: Pick<ChatMoment, "design">,
  channel: "slack" | "teams",
  createError: (message: string) => ValidationError,
): boolean | ValidationError => {
  if (!design) {
    return createError("Enter a message");
  }

  const body = toMarkdown(design as RawDraftContentState, channel);
  if (body.length === 0) {
    return createError("Enter a message");
  }
  if (body.length > config.slack.maxBlockLength) {
    return createError("Message is too long");
  }

  return true;
};

export const validateLexicalMergeTags = (
  design: unknown,
  mergeTags: Tags,
  toTarget: ToTarget,
  createError: (message: string) => ValidationError,
): boolean | ValidationError => {
  const serializedState = design as SerialisedEditorDesign;

  const tags = validMergeTags(mergeTags, toTarget).map((t) => t.id);
  const invalidTags: string[] = [];

  const isValidMergeTags = ({ type, children, text }: Node): void => {
    if (type === "merge-tag-v2" && text && !tags.includes(text)) {
      invalidTags.push(text);
    }

    if (children) {
      for (const child of children) {
        isValidMergeTags(child);
      }
    }
  };

  isValidMergeTags(serializedState.root);

  if (invalidTags.length > 0) {
    return createError(
      `Invalid merge tags: ${invalidTags
        .map((t) => `{{${t}}}`)
        .join(", ")
        .replace(/,(?!.*,)/gim, " and")}`,
    );
  }

  return true;
};

export const validateMergeTags = (
  body: string,
  merge_tags: Tags,
  toTarget: ToTarget,
  createError: (message: string) => ValidationError,
): boolean | ValidationError => {
  const tags = validMergeTags(merge_tags, toTarget).map((t) => t.id);

  const invalid = getInvalidTags(body, tags);

  if (invalid.length > 0) {
    return createError(
      `Invalid merge tags: ${invalid
        .map((t) => `{{${t}}}`)
        .join(", ")
        .replace(/,(?!.*,)/gim, " and")}`,
    );
  }

  return true;
};

const getInvalidTags = (value: string, mergeTags: string[]): string[] => {
  let match: RegExpExecArray | null;
  const invalidTags: string[] = [];
  const mergeTagRegex = RegExp(/\{{(.*?)\}}/g);
  while ((match = mergeTagRegex.exec(value ?? "")) !== null) {
    if (!mergeTags.find((x) => match && x === match[1]) && !match[1].startsWith("hyperlink:")) {
      invalidTags.push(match[1]);
    }
  }
  return invalidTags;
};

export const validateDesignHuddleNodeImages = (
  design: unknown,
  createError: (message: string) => ValidationError,
): boolean | ValidationError => {
  const serializedState = design as SerialisedEditorDesign;

  const result = serializedState.root.children.some((node: Node) => {
    if (node.type === "design-huddle") {
      const dhNode = node as SerializedDesignHuddleNode;
      if (dhNode.version === 2 && dhNode.error) {
        return true;
      }
    }
  });

  if (result) return createError("Invalid images found");

  return true;
};
