import { FC, useCallback } from "react";
import { useToken } from "../../server/auth/use-token";
import { ImagesService } from "../../server/cloudflare/images-service";
import { UUID } from "../../utils/uuid";
import { AssetResult, useUpdateAssetCallback } from "./hooks/use-upsert-asset";
import { Asset } from "./server/digital-asset-service";
import { UploadFileModal } from "./upload-file-modal";

export const ReplaceFileModal: FC<{
  open: boolean;
  assetToReplace: Asset;
  onOpen: () => void;
  onClose: () => void;
  onCancel: () => void;
  onChange: () => void;
  onViewFileClick: (asset: Asset) => void;
}> = ({ assetToReplace, open, ...props }) => {
  const updateAsset = useUpdateAssetCallback();
  const getToken = useToken();

  function getUuid(url: string): string {
    const withoutQuery = url.split("?")[0];
    const withoutTrailingSlash = withoutQuery.endsWith("/")
      ? withoutQuery.slice(0, -1)
      : withoutQuery;

    const parts = withoutTrailingSlash.split("/");
    return parts[parts.length - 1];
  }

  const handleSave = useCallback(
    async (asset: {
      title: string;
      thumbnailUrl?: string | null;
      contentType: string;
      projectId?: string | null;
      pageId?: string | null;
      mimeType?: string | null;
      sourceDigitalAssetId?: UUID | null;
      publicUrl?: string | null;
    }): Promise<AssetResult> => {
      if (!asset.publicUrl || !assetToReplace.public_url)
        throw new Error("Asset must have a public url");

      const id = getUuid(assetToReplace.public_url);
      const file = new File([await (await fetch(asset.publicUrl)).blob()], id);
      const { success, url: imageServiceUrl } = await new ImagesService().upload(
        file,
        id,
        await getToken(),
        file.type,
        true,
      );
      if (!success) throw new Error("Failed to upload image");

      const url = `${imageServiceUrl}?v=${Date.now()}`;
      return await updateAsset({
        asset: assetToReplace,
        title: asset.title,
        ...(asset.mimeType?.startsWith("image/") ? { thumbnailUrl: url } : {}),
        publicUrl: url,
      });
    },
    [assetToReplace, getToken, updateAsset],
  );

  return (
    open && (
      <UploadFileModal
        open
        heading={`Replace ${assetToReplace.title}`}
        allowedFileTypes={assetToReplace.mime_type?.startsWith("image/") ? ["image"] : ["pdf"]}
        onSave={handleSave}
        {...props}
      />
    )
  );
};
