import { useAuth0 } from "@auth0/auth0-react";
import { Alert, AlertTitle, Box, Button, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Navigate, useSearchParams } from "react-router";
import { ThemeProvider } from "../../../styles/theme/theme-provider";

const FullScreenLogo: FC<{ children?: ReactNode }> = ({ children }) => (
  <ThemeProvider mode="dark">
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "neutral.900",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        left: 0,
        p: 3,
        position: "fixed",
        top: 0,
        width: "100vw",
        zIndex: 2000,
      }}
    >
      <img src="/logo.svg" alt="logo" width={300} height={300} />
      <>{children}</>
    </Box>
  </ThemeProvider>
);

export const SplashScreen: FC = () => {
  const [searchParams] = useSearchParams();

  const hasCode = searchParams.has("code");
  if (hasCode) return <></>;

  return <Navigate to="/moments/dashboard" replace />;
};

export const ErrorSplashScreen: FC = () => {
  const { t } = useTranslation();
  const { logout, error } = useAuth0();

  let errorAlert;
  switch (error?.message) {
    case "No permissions are set for user":
      errorAlert = <PermissionsErrorAlert />;
      break;
    default:
      errorAlert = <DefaultErrorAlert />;
  }

  return (
    <FullScreenLogo>
      <Alert variant="outlined" severity="info" sx={{ width: "33%" }}>
        <AlertTitle>
          <Trans>
            <Typography>
              <strong>Oh no, something went wrong!</strong> To login to ChangeEngine you must be an
              authorized admin user for your organization. Please check that you are using the
              correct company email address.
            </Typography>
          </Trans>
        </AlertTitle>
        {errorAlert && (
          <Trans>
            <Typography>{errorAlert}</Typography>
          </Trans>
        )}
      </Alert>
      <Button
        data-analytics-id="error-splash-screen-login"
        variant="outlined"
        sx={{ marginTop: "20px" }}
        onClick={() => {
          void logout().catch(console.error);
        }}
      >
        {t("Login")}
      </Button>
    </FullScreenLogo>
  );
};

const DefaultErrorAlert: FC = () => {
  return (
    <>
      <strong>Are you authorized? First time logging in?</strong> To activate your account, you must
      use the invitation link in the email you should have received from support@changeengine.com.
      After using that link for the first time, then subsequent logins can be made directly at this
      web address.
    </>
  );
};

const PermissionsErrorAlert: FC = () => {
  return (
    <>
      <strong>Permissions are not configured for your user.</strong> Please contact your
      ChangeEngine administrator to have your user permissions set.
    </>
  );
};
